@import "../../../../../../global-ui-scss/variables.scss";
@import "../../../../../../global-ui-scss/mixins.scss";

.avatar_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0;

  .title_name {
    color: $primary-neutrals-dark60;
    font-weight: 500;
    font-size: 14px;
    line-height: 136%;
    white-space: nowrap;
  }

  svg {
    width: 0;
    transition: width 0.3s, margin-left 0.3s;
    cursor: pointer;
  }

  &:hover svg {
    width: 16px;
  }

  .block_name_person {
    display: flex;
    grid-gap: 8px;
    align-items: center;

    .icon_name {
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      color: $white;
      background: $color-primary-blue-deep;
      border-radius: 50%;
      min-width: 24px;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: capitalize;
    }
  }

  .block_name_groups {
    .icon_name {
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      padding: 4px 12px;
      background: $primary-dark;
      border-radius: 100px;
      color: #ffffff;
      display: flex;
      align-items: center;
    }

    &:hover svg {
      margin-left: 6px;
    }
  }
}

.tooltipOverlay {
  &:global(.ant-tooltip) {
    padding-top: 4px;
  }

  :global(.ant-tooltip-inner) {
    background: #003e47;
    border-radius: 8px;
    padding: 12px 14px;
  }
}

.tooltipContent {
  display: flex;
  flex-direction: column;

  .title {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 10px;
  }

  .user {
    font-weight: 500;
    font-size: 14px;
    line-height: 136%;
    color: #ffffff;
  }

  .user + .user {
    margin-top: 8px;
  }
}
