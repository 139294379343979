@import "../../global-ui-scss/mixins";
@import "../../global-ui-scss/variables";

.root {
  :global(.ant-dropdown-menu) {
    max-height: 300px;

    :global(.ant-dropdown-menu-item) {
      padding: 0.8125rem 1rem;

      :global(.ant-dropdown-menu-title-content) {
        font-weight: 700;
        font-size: 0.75rem;
        letter-spacing: 0.04em;
        color: $primary-dark;
      }
    }
  }

  .statusDot {
    &:global(.ant-dropdown-menu-item-icon) {
      width: 10px;
      height: 10px;
      min-width: 10px;
      border-radius: 50%;
      margin-inline-end: 10px;
    }

    &.default {
      background-color: $color-neutrals-solid-7;
    }
    &.blue {
      background-color: $color-primary-blue-main;
    }
    &.yellow {
      background-color: $color-yellow-dark;
    }
    &.red {
      background-color: $color-red-700;
    }
    &.green {
      background-color: $color-green-700;
    }
    &.darkBlue {
      background-color: $color-blue-dark;
    }
    &.orange {
      background-color: $secondary-orange;
    }
    &.purple {
      background-color: $color-purple;
    }
  }
}

.wrapper {
  min-width: 130px;

  &:global(.ant-dropdown-open) {
    .indicatorIcon {
      transform: rotate(180deg);
    }

    .indicator {
      color: #fff;

      &.default {
        background-color: RgbaColor($primary-dark, 0.1);
      }
      &.blue {
        background-color: RgbaColor($color-primary-blue-main, 0.5);
      }
      &.yellow {
        background-color: RgbaColor($color-yellow-main, 0.5);
      }
      &.red {
        background-color: RgbaColor($color-red-700, 0.5);
      }
      &.green {
        background-color: RgbaColor($color-green-700, 0.5);
      }
      &.darkBlue {
        background-color: RgbaColor($color-blue-dark, 0.5);
      }
      &.orange {
        background-color: RgbaColor($secondary-orange, 0.5);
      }
      &.purple {
        background-color: RgbaColor($color-purple, 0.5);
      }
    }
  }

  .button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    font-size: 0.75rem;
    height: auto;
    grid-gap: 0.625rem;
    border-radius: 4px;
    padding: 0 0 0 0.75rem;
    min-height: 0;
    box-shadow: none;
    font-weight: 700;
    background-color: transparent;

    &:global(.ant-dropdown-open) {
      svg {
        transform: rotate(180deg);
      }
    }

    svg {
      transition: transform 0.2s;

      path {
        stroke: currentColor;
        stroke-opacity: 1;
      }
    }

    &.default,
    &.default:hover {
      color: RgbaColor($primary-dark, 0.8);
      background-color: $color-neutrals-solid-4;
    }
    &.blue,
    &.blue:hover {
      color: $color-primary-blue-deep;
      background-color: RgbaColor($color-primary-blue-main, 0.2);
    }
    &.yellow,
    &.yellow:hover {
      color: $color-yellow-dark;
      background-color: RgbaColor($color-yellow-main, 0.2);
    }
    &.red,
    &.red:hover {
      color: $color-red-700;
      background-color: RgbaColor($color-red-700, 0.1);
    }
    &.green,
    &.green:hover {
      color: $color-green-700;
      background-color: RgbaColor($color-green-700, 0.2);
    }
    &.darkBlue,
    &.darkBlue:hover {
      color: $color-blue-dark;
      background-color: RgbaColor($color-blue-dark, 0.2);
    }
    &.orange,
    &.orange:hover {
      color: $secondary-orange;
      background-color: RgbaColor($secondary-orange, 0.2);
    }
    &.purple,
    &.purple:hover {
      color: $color-purple;
      background-color: RgbaColor($color-purple, 0.2);
    }
  }

  .indicator {
    display: flex;
    padding: 0.25rem;
    border-radius: 0 4px 4px 0;
    border-left: 1px solid $color-neutrals-solid-1;
    background-color: RgbaColor($primary-dark, 0.02);
  }

  .indicatorIcon {
    transition: transform 200ms;
  }
}
