.root {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  padding: 16px 24px !important;

  button {
    min-width: 128px;
    width: max-content;
  }
}
