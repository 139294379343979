.wrapper {
  position: relative;
  min-height: 100%;
  background: #003e470d;

  :global(.audits_logo) {
    position: sticky;
  }

  :global(.ant-tabs-top > .ant-tabs-nav) {
    margin-bottom: 0;
  }

  :global(.ant-tabs-tab-btn) {
    font-size: 16px;
    font-weight: 700;
    color: #003e47;
  }

  .content {
    display: grid;
    align-items: flex-start;
    margin: 0 auto;
    padding: 2rem;
    max-width: 1092px;
  }

  .fluid {
    max-width: none;
  }
}
