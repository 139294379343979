@import "../../../../../../global-ui-scss/mixins";
@import "../../../../../../global-ui-scss/variables";

.statusGroupWrapper {
  display: grid;
  align-items: center;
  grid-gap: 0.5rem;
  grid-template-columns: auto 1fr auto;
}

.unsynced {
  .dragHandle,
  :global(.ant-dropdown-trigger) {
    pointer-events: none;
    opacity: 0.5;
  }
}

.menu {
  min-width: 10rem !important;
}

.statusGroup {
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
  min-width: 0;
  max-width: 352px;
  padding: 1.125rem 1rem;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
  min-height: 70px;

  &:not(.active):hover {
    background: RgbaColor($primary-dark, 0.07);
  }

  :global(.ant-progress-circle > *:nth-child(1)) {
    stroke: #003e4712 !important;
  }

  :global(.ant-progress-circle > *:nth-child(2)) {
    stroke: var(--primary-blue, #49baca) !important;
  }

  :global(.ant-progress-circle > *:nth-child(3)) {
    stroke: var(--secondary-green-700, #6db333) !important;
  }

  &.active .divider {
    height: 17px;
  }

  .botRow {
    display: flex;
    height: 5px;
    opacity: 0;
    gap: 4px;
    transition: 0.3s;

    .stepIndicator {
      width: 100%;
      height: 100%;
      border-radius: 3px;
      background: RgbaColor($primary-dark, 0.1);
      transition: 0.3s;

      &.in_progress {
        background: $color-primary-blue-main;
      }

      &.blocked {
        background: $secondary-orange;
      }

      &.pending {
        background: $color-yellow-dark;
      }

      &.done {
        background: $color-green-700;
      }

      &.not_required {
        background: $color-purple;
      }
    }
  }

  &.active {
    background: #fff;

    .outOf,
    .botRow {
      opacity: 1 !important;
    }
  }
}

.icon {
  display: flex;
}

.info {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  min-width: 0;

  .topRow {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .name {
      font-size: 1rem;
      font-weight: 500;
      color: $primary-dark;
      min-width: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      .active & {
        font-weight: 700;
      }
    }

    .outOf {
      margin-left: auto;
      margin-bottom: auto;
      color: RgbaColor($primary-dark, 0.4);
      font-size: 12px;
      font-weight: 700;
      opacity: 0;
    }
  }
}

.divider {
  position: absolute;
  bottom: -17px;
  left: 31px;
  height: 26px;
  width: 2px;
  background-color: RgbaColor($primary-dark, 0.07);
  z-index: -1;
}

.actionButtons {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 1.5rem;
}

.renameForm {
  position: relative;
  height: 34px;
  z-index: 10000;

  .input {
    position: absolute;
    top: 50%;
    left: 0;
    border: 0;
    font-size: 1rem;
    padding: 0.875rem 1rem;
    box-shadow: 0 6px 17px 0 #0000001a !important;
    background-color: white !important;
    transform: translate(-16px, -50%);
  }

  .actionButtons {
    transform: translate(-16px, -8px);
  }
}
