@import "../../../../global-ui-scss/mixins";
@import "../../../../global-ui-scss/variables";

.wrapper {
  position: relative;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s background-color;
  border-radius: 4px;
  outline: none;
  border: none;
  width: 32px;
  height: 32px;
  color: RgbaColor($primary-dark, 0.8);
  background-color: transparent;

  &:hover,
  &.active,
  &:active {
    background: RgbaColor($primary-dark, 0.1);
    transition-delay: 0s;
  }

  &.disabled {
    color: RgbaColor($primary-dark, 0.4);
    background: transparent;
  }
}

.tooltip {
  &:global(.ant-tooltip) {
    :global(.ant-tooltip-inner) {
      font-weight: 500;
      line-height: 1.35;
      padding: 0.25rem 0.5rem;
      font-size: 0.75rem;
      min-height: auto;
    }
  }
}

.divider {
  width: 1px;
  height: 24px;
  margin: auto 2px;
  background-color: RgbaColor($primary-dark, 0.2);
}
