.root {
  font-size: 12px;
  font-weight: 700;
  line-height: 14.09px;
  text-align: center;
  padding: 10px 16px;
  border-radius: 4px;
  background: #003e4712;
  color: #003e4799;
  white-space: nowrap;

  &.white {
    border: 1px solid #003e4712;
    background: #fff;
  }
}
