.dropdown-container {
  position: relative;
  width: 200px;
}

.dropdown-header {
  display: flex;
  align-items: center;
  padding: 0.8125rem 1rem;
  color: var(--primary-dark, #003e47);
  font-size: 12px;
  font-weight: 700;
  transition: 0.3s;
  border-radius: 4px;
  border: 1px solid var(--neutrals-dark-10, rgba(0, 62, 71, 0.1));
}

.dropdown-container.disabled .dropdown-header {
  opacity: 0.5;
  pointer-events: none;
}

.dropdown-container.open .dropdown-arrow {
  transform: translateY(-50%) rotate(180deg);
}

.dropdown-container.grey .dropdown-header {
  border-radius: 4px;
  background: var(--neutrals-dark-20, rgba(0, 62, 71, 0.2));
  border: 1px solid var(--neutrals-dark-10, rgba(0, 62, 71, 0.02));
}

.dropdown-container.big .dropdown-list,
.dropdown-container.big {
  width: 100%;
}

.dropdown-container.big .dropdown-header {
  padding: 13px 15px;
  line-height: 24px;
}

.dropdown-container.error .dropdown-header {
  outline: 1px solid #ff5454;
  border: none !important;
  background-color: rgba(255, 84, 84, 0.1);
}

.dropdown-list {
  position: absolute;
  max-height: 165px;
  width: 100%;
  overflow-y: auto;
  list-style-type: none;
  margin: 4px 0 0 0;
  padding: 4px;
  z-index: 10;
  border-radius: 8px;
  background: var(--primary-white, #fff);
  box-shadow: 0 6px 17px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.dropdown-arrow {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.3s transform;
}

.ant-dropdown .ant-dropdown-menu {
  max-height: 240px;
  overflow: auto;
}

.dropdown-list-item .ant-dropdown-menu-title-content {
  color: var(--primary-dark, #003e47);
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.48px;
}

.dropdown-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 0.5rem;
  min-height: 44px;
  padding: 15px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s background-color;
  color: var(--primary-dark, #003e47);
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.48px;
}

.ant-dropdown-menu .dropdown-list-item .ant-dropdown-menu-title-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.dropdown-list-item.dropdown-active-item {
  background: var(--neutrals-solid-dark-5, #f2f5f6);
}
