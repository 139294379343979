@import "../../../../global-ui-scss/mixins.scss";
@import "../../../../global-ui-scss/variables.scss";

.container {
  width: 100%;
  @include DisplayFlex(column, center, flex-start);
  gap: 8px;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.attachment_wrapper.dragActive,
:global(.parent-upload-drag-active) .attachment_wrapper {
  border: 1px solid #49baca;
  background: rgba(0, 62, 71, 0.02);
  box-shadow: 0px 0px 4px 0px #54b4b4;

  button {
    opacity: 0;
  }
}

.attachment_wrapper {
  width: 100%;
  @include DisplayFlex(column, start, start);
  gap: 24px;

  padding: 14px 24px 14px 14px;
  border: 1px dashed rgba(0, 62, 71, 0.2);
  border-radius: 4px;
  transition: 0.3s;

  .attachment_dropzone {
    width: 100%;
    @include DisplayFlex(row, space-between, center);
  }

  .attachment_search {
    position: relative;
    width: 100%;
  }

  .text_img_wrapper {
    @include DisplayFlex(row, center, center);

    gap: 10px;

    .img_wrapper {
      padding: 28px;
      background: #f2f5f6;
      border-radius: 4px;
    }

    .text {
      font-family: "Raleway";
      font-style: normal;
      font-weight: 500;
      color: #003e47cc;
      margin: 0;
    }
  }

  button {
    white-space: nowrap;
    width: fit-content;
    transition: 0.3s !important;
  }
}

.attachment_wrapper.boxioConnected {
  background-color: $secondary-green-100;
  border: none;

  .img_wrapper {
    background-color: $secondary-green20;
  }

  :global(svg path) {
    fill: $color-green-700;
    fill-opacity: 1;
  }
}

.attachmentList {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 12px;
}

.attachmentList > * + * {
  margin-top: 8px;
}
