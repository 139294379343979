@import "../../../global-ui-scss/mixins";
@import "../../../global-ui-scss/variables";

@mixin CreateSecondaryButton($borderColor, $hoverBackground) {
  border: 1px solid $borderColor;
  color: $borderColor;
  background-color: transparent;
  &:hover {
    background: $hoverBackground;
  }
}

@mixin CreatePrimaryButton($bgColor, $bgHoverColor) {
  background-color: $bgColor;
  &:hover {
    background-color: $bgHoverColor;
  }
}

@mixin CreateTertiaryButton($color, $hoverColor, $bgHoverColor: transparent) {
  color: $color;
  &:hover {
    color: $hoverColor;
    background-color: $bgHoverColor;
  }
}

@mixin CreateButton($color, $bgColor, $bgHoverColor) {
  color: $color;
  background-color: $bgColor;
  &:hover {
    background-color: $bgHoverColor;
  }
}

.button_root {
  @include DisplayFlex(row, center, center);
  grid-gap: 0.5rem;
  padding: 9px 16px;
  border: none;
  cursor: pointer;
  width: 100%;
  font-weight: 700;
  border-radius: 4px;
  font-size: 12px;
  transition: background-color 0.4s;

  &:focus {
    outline: none;
  }

  &.medium {
    padding: 0.875rem 1rem;
  }

  &.large {
    border-radius: 8px;
    font-size: 14px;
    line-height: 16px;
    padding: 20px 24px;
  }

  &.small {
    padding: 4px 12px;
  }

  &.fullWidth {
    width: 100%;
  }

  &.primary {
    color: $white;

    &.gray {
      color: RgbaColor($primary-dark, 0.8);
      @include CreatePrimaryButton(RgbaColor($primary-dark, 0.15), RgbaColor($primary-dark, 0.2));
    }

    &.blue {
      @include CreatePrimaryButton($color-primary-blue-main, $color-primary-blue-deep);
    }

    &.lightBlue {
      color: $color-primary-blue-deep;
      @include CreatePrimaryButton(
        RgbaColor($color-primary-blue-main, 0.2),
        RgbaColor($color-primary-blue-main, 0.4)
      );
    }

    &.green {
      @include CreatePrimaryButton($color-green-700, $primary-green-dark);
    }

    &.lightGreen {
      color: $color-green-700;
      @include CreatePrimaryButton($secondary-green-100, $secondary-green20);
    }

    &.red {
      @include CreatePrimaryButton($color-red-700, $color-red-800);
    }

    &.lightRed {
      color: $color-red-700;
      @include CreatePrimaryButton(RgbaColor($color-red-700, 0.1), RgbaColor($color-red-700, 0.2));
    }

    &.dark {
      @include CreatePrimaryButton($primary-dark, $primary-dark);
    }

    &:disabled:hover,
    &:disabled {
      color: white;
      background: $primary-disable;
    }
  }

  &.secondary {
    transition: border-color 0.3s, background-color 0.3s;

    &.gray {
      @include CreateButton(
        RgbaColor($primary-dark, 0.8),
        RgbaColor($primary-dark, 0.1),
        $primary-neutrals-dark15
      );
    }

    &.blue {
      @include CreateSecondaryButton($color-primary-blue-main, $secondary-blue);

      &:hover {
        border-color: transparent;
        //border: 1px solid transparent;
      }
    }

    &.red {
      @include CreateSecondaryButton($color-red-700, $secondary-red-dark);

      &:hover {
        border: 1px solid transparent;
      }
    }

    &.green {
      @include CreateSecondaryButton($color-green-700, $secondary-green);

      &[disabled]:hover,
      &[disabled] {
        border: 1px solid var(--neutrals-dark-20, rgba(0, 62, 71, 0.2));
      }
    }

    &.large {
      border-width: 2px;
    }

    &[disabled]:hover,
    &[disabled] {
      color: $primary-disable;
      border-color: $primary-disable;
      background: $white;
      cursor: not-allowed;
    }
  }

  &.tertiary {
    background-color: transparent;
    transition: color 0.3s, background-color 0.3s;

    &.gray {
      @include CreateTertiaryButton($tertiary-neutrals-dark20, #0f8594);

      &.icon {
        @include CreateTertiaryButton($tertiary-neutrals-dark20, #0f8594, #003e4726);
      }
    }

    &.bright {
      @include CreateTertiaryButton($tertiary-neutrals-dark20, #0f8594);

      &.icon {
        @include CreateTertiaryButton($tertiary-neutrals-dark20, #0f8594, #ffffff1a);
      }
    }

    &.blue {
      @include CreateTertiaryButton($color-primary-blue-main, $color-primary-blue-deep);
    }

    &.red {
      @include CreateTertiaryButton(
        $color-red-700,
        $color-red-700,
        var(--secondary-red-200, rgba(255, 84, 84, 0.2))
      );
    }

    &:disabled:hover,
    &:disabled {
      color: $tertiary-neutrals-dark20;
    }
  }

  &.icon {
    padding: 4px;
    width: max-content;
    height: max-content;

    &.lightBlue {
      @include CreateButton(
        $color-primary-blue-deep,
        RgbaColor($color-primary-blue-main, 0.2),
        RgbaColor($color-primary-blue-main, 0.3)
      );
    }

    &.gray {
      @include CreateButton(
        RgbaColor($primary-dark, 0.8),
        RgbaColor($primary-dark, 0.07),
        RgbaColor($primary-dark, 0.15)
      );
    }
  }

  &.modal {
    font-weight: 700;
    font-size: 12px;
    border-radius: 4px;

    &.red {
      color: $color-red-700;
      background: $white;

      &:hover {
        background: #ff54541a;
      }
    }

    &.gray {
      color: $primary-dark;
      background: $white;

      &:hover {
        background: #f2f5f6;
      }
    }
  }

  &.light {
    color: $color-primary-blue-main;

    &:hover {
      color: $color-primary-blue-deep;
    }

    &:disabled:hover,
    &:disabled {
      color: $primary-disable;
    }
  }
}
