@import "../../../global-ui-scss/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100% - $header-height);
  background: linear-gradient(0deg, rgba(0, 62, 71, 0.05), rgba(0, 62, 71, 0.05)), #ffffff;

  .addCompanyButton {
    grid-gap: 0.625rem;
    letter-spacing: 0.04em;
    padding: 3px 10px 3px 5px;
  }
}

.inner {
  max-width: 700px;
  flex-grow: 1;
  padding: 32px 42px;
}

.title {
  text-align: center;
  font-size: 1.5rem;
  color: $primary-dark;
  font-weight: 800;
}
