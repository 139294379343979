.checkData {
  display: flex;
  justify-content: flex-end;
  grid-gap: 14px;
}

.btn_discard {
  cursor: pointer;
  width: 130px;
  height: 34px;
  border: 1px solid #ff5454;
  color: #ff5454;
  font-size: 12px;
  font-weight: 700;
  line-height: 14%;
  background-color: transparent;
  border-radius: 4px;
  letter-spacing: 4%;
  transition: 0.6s;
}
.btn_discard:hover {
  transition: 0.7s;
  color: #fff;
  background-color: #ff5454;
}
.btn_check {
  width: 130px;
  height: 34px;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  line-height: 14%;
  border: none;
  background-color: #003e4733;
  border-radius: 4px;
  letter-spacing: 4%;
  transition: 0.6s;
}
.btn_check-active {
  background-color: #6db333;
  cursor: pointer;
}

.btn_check-active:hover {
  transition: 0.9s;
  background-color: #569720;
}
