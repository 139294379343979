@import "../../../global-ui-scss/mixins";
@import "../../../global-ui-scss/variables";

.root {
  position: relative;
}

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1010;
  width: $dashboard-aside-width;
  flex-shrink: 0;

  @media (min-width: 1440px) {
    position: sticky;
    width: auto;
  }
}

.aside {
  position: relative;
  display: grid;
  grid-template-rows: repeat(2, max-content) 1fr;
  width: $dashboard-aside-width;
  height: 100vh;
  padding: 1.5rem 0;
  color: #ffffff;
  background-color: $primary-dark;
  transition: width 250ms;

  .iconButton {
    &:global(.ant-btn) {
      color: #ffffff;
      background-color: transparent;
      border-color: transparent;
      transition: transform 350ms;

      &:not(.ant-button-disabled) {
        &:hover {
          color: #ffffff;
          background-color: rgba(255, 255, 255, 0.05);
          border-color: transparent;
        }
      }
    }

    &.rotated {
      transform: rotate(180deg);
    }
  }

  @media (min-width: 1440px) {
    //width: $dashboard-aside-width-open;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.25rem 1rem;
}

.logo {
  display: inline-flex;
  max-width: 0;
  opacity: 0;
  overflow: hidden;
  transition: width 250ms, opacity 350ms;
}

.auditSubmenu {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.25rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.auditSubmenuHeader {
  display: flex;
  flex-direction: column;
  max-width: 0;
  overflow: hidden;
}

.auditSubmenuTitle {
  font-weight: 700;
  margin-bottom: 2px;
}

.auditSubmenuDescription {
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.8);
}

.asideNavigation {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  margin-top: 1rem;
  padding: 0 0.75rem;
}

.asideLink {
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 0.75rem;
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  border-radius: 4px;

  &:last-child {
    margin-top: auto;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.05);
  }
}

.asideLinkActive {
  font-weight: 700;
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.1);
}

.asideLinkIcon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.asideLinkLabel {
  max-width: 0;
  overflow: hidden;
  transition: max-width 250ms, padding 250ms;
}

.asideOpen {
  width: $dashboard-aside-width-open;

  .logo,
  .asideLinkLabel,
  .auditSubmenuHeader {
    max-width: $dashboard-aside-width-open;
    opacity: 1;
  }

  .asideLinkLabel {
    padding: 0 1rem;
  }
}

.submenu {
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 100%;
  background-color: #ffffff;
  transform: translateX(-$dashboard-aside-width-open * 2);
  transition: transform 250ms;
}

.overlay {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 100;
}

.submenuOpen {
  transform: translateX(0);
}

.submenuInner {
  position: relative;
  padding-top: 2.5rem;
  width: 100vw;
  height: 100vh;
  max-width: 400px;
  overflow: auto;
}

.submenuTitle {
  color: $primary-dark;
  font-size: 1.25rem;
  font-weight: 800;
  padding-left: 1.5rem;
}

.searchWrapper {
  padding: 1.5rem;
}

.submenuListWrapper {
  margin-top: 3.125rem;
}

.submenuListTitle {
  font-size: 1.125rem;
  font-weight: 700;
  margin: 0 24px;
  padding-bottom: 1rem;
  color: $primary-dark;
  border-bottom: 1px solid RgbaColor($primary-dark, 0.07);
}

.submenuList {
  display: grid;
  grid-gap: 0.5rem;
  margin-top: 1rem;
}

.submenuListItem {
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  grid-gap: 1.25rem;
  align-items: center;
  border-right: 3px solid #0000;
  padding: 14px 24px;
  cursor: pointer;

  &:not(:last-child) {
    .navigationPoint {
      &::before {
        position: absolute;
        content: "";
        display: block;
        width: 1px;
        z-index: 0;
        right: 4.5px;
        top: 10px;
        border-left: 1px dashed #dae3e5;
        height: 44px;
      }
    }
  }
}

.submenuListItemActive {
  border-color: $primary-dark;
  box-shadow: inset -180px 0 130px -60px #00000008;
  font-weight: 700;

  .navigationPoint {
    background-color: $color-primary-blue-main;
  }
}

.navigationPoint {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  background-color: #dae3e5;
  border-radius: 50%;
  position: relative;
}

.submenuListItemTitle {
  color: $primary-dark;

  &.titleActive {
    font-weight: 700;
  }
}
