.actions {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  padding: 12px;
  margin: 20px 0;
  border-radius: 16px;
  background-color: #ffffff;
}

.actionInner {
  overflow: auto;
}
