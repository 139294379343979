.root {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.text {
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
}

.default {
}

.absoluteCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
