@import "../../../global-ui-scss/variables.scss";

.inner {
  min-height: 100vh;
  padding: 3.625rem 1.5rem 1.5rem;
  background: linear-gradient(0deg, rgba(0, 62, 71, 0.05), rgba(0, 62, 71, 0.05)), #ffffff;
}

.title {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 800;
  color: $primary-dark;
}
