@import "../../../global-ui-scss/mixins";
@import "../../../global-ui-scss/variables";

.root {
  position: relative;
  width: 100%;
  max-width: 300px;
}

.input {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;

  &::placeholder {
    fill: RgbaColor($primary-dark, 0.2);
  }
}

.icon {
  fill: RgbaColor($primary-dark, 0.4);
}
