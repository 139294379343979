@import "../../global-ui-scss/variables";

.container {
  display: flex;
  flex-grow: 1;
}

.leftBlock,
.rightBlock {
  overflow: hidden;
}

.leftBlock,
.rightBlock {
  background-color: #ffffff;
}

.resizer {
  position: relative;
  cursor: col-resize;
  width: 1px;
  background-color: $color-neutrals-solid-5;
  z-index: 1;
}
