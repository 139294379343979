@import "../../../../../global-ui-scss/mixins.scss";
@import "../../../../../global-ui-scss/variables.scss";

.wrapper {
  width: 100%;
  .headerSection {
    padding: 24px;
    gap: 24px;
    width: 100%;
    @include DisplayFlex(column, flex-start, center);
    border-bottom: 1px solid $primary-neutrals-dark10;
    .headingWrapper {
      width: 100%;
      @include DisplayFlex(row, space-between, center);

      .title {
        font-weight: 800;
        font-size: 20px;
        color: $primary-dark;
      }

      .buttons_wrapper {
        @include DisplayFlex(row, center, center);
        gap: 12px;
        button {
          width: 113px;
        }
      }
    }
    .inputWrapper {
      width: 100%;
    }
  }
  .bodySection {
    padding: 24px;
    gap: 40px;
    width: 100%;
    @include DisplayFlex(column, center, flex-start);

    .dateFileWrapper {
      gap: 24px;

      .inputDate {
        border: 1px solid #003e471a;
        border-radius: 4px;
        background-color: transparent;
        font-feature-settings: "pnum" on, "lnum" on;
        padding: 4px 8px 4px 12px;
        font-size: 14px;
        color: #003e47cc;
        font-weight: 500;
        &::placeholder {
          font-size: 14px;
          padding-top: 2px;
        }
      }
    }

    :global(.rdw-editor-wrapper) {
      border-color: #ebf0f0 !important;
    }
  }
}
.row {
  @include DisplayFlex(row, flex-start, center);
}
.column {
  width: 100%;
  @include DisplayFlex(column, center, flex-start);
  gap: 8px;
}
.columnTitle {
  margin: 0.5rem 0;
  color: #003e4766;
  font-size: 12px;
  font-weight: 700;
}

.toggleWrapper {
  gap: 12px;
}
