.wrapper_login_page {
  height: 100vh;
  background: linear-gradient(0deg, rgba(0, 62, 71, 0.05), rgba(0, 62, 71, 0.05)), #ffffff;
  display: grid;
  justify-content: center;
  align-items: center;
}

.wrapper_login_content {
  display: grid;
  justify-items: center;
  grid-template-rows: max-content 1fr;
  grid-gap: 50px;
}

.wrapper_login_content > a {
  margin: -24px 0;
  padding: 24px;
}

.wrapper_login_content .login_form {
  background: #ffffff;
  border-radius: 12px;
  display: grid;
  padding: 40px;
  max-width: 420px;
}

.wrapper_login_content .login_form.code_form {
  padding-bottom: 16px;
}

.wrapper_login_content .login_form > svg {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 2rem;
}
.wrapper_login_content .login_form a,
.wrapper_login_content .login-disclaimer {
  text-align: center;
  font-size: 14px;
  line-height: 136%;
  color: rgba(0, 62, 71, 0.4);
}

.wrapper_login_content .login_form a {
  color: #49baca;
}

.wrapper_login_content .login_form a:hover {
  color: #0f8594;
}

.wrapper_login_content .login-disclaimer {
  max-width: 420px;
  margin-top: -18px;
}

.wrapper_login_content .login_form a,
.wrapper_login_content .login-disclaimer a {
  font-weight: bold;
  text-decoration: none;
}

.wrapper_login_content .login-disclaimer a {
  color: #49baca;
  border-bottom: 1px solid rgba(0, 62, 71, 0.1);
}

.wrapper_login_content .login-disclaimer a:hover {
  color: #0f8594;
}

.wrapper_inputActive_error {
  color: red;
  text-align: center;
  font-weight: 700;
  letter-spacing: 0.5px;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.wrapper_inputAddCompany_errors {
  color: red;
  text-align: center;
  font-weight: 700;
  letter-spacing: 0.5px;
  display: grid;
  justify-items: end;
  margin-right: 30px;
  margin-top: 15px;
}

.wrapper_login_content .login_form .btn_bluelight {
  width: 100%;
  padding: 20px 0;
}

.wrapper_login_content .login_form .input_container {
  min-width: 340px;
}

.wrapper_login_content .login_form__title {
  font-weight: 800;
  font-size: 24px;
  line-height: 110%;
  text-align: center;
  color: #003e47;
  margin: 0;
}

.wrapper_login_content .login_form__inputs {
  display: grid;
  margin-top: 32px;
  grid-gap: 24px;
}

.wrapper_login_content .login_form__inputs .PhoneInputInput {
  margin-top: 8px;
}

.wrapper_login_content .login_form__inputs .PhoneInputCountry {
  margin-top: -6px;
}

.wrapper_login_content .login_form__inputs > .input_container + .input_container {
  margin-top: -12px;
}

.initialLogo {
  width: 127px;
  height: 45px;
}

.login_buttonEnter {
  margin-top: -35px;
}
