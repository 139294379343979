@import "../../../../../../global-ui-scss/mixins";
@import "../../../../../../global-ui-scss/variables";

.button {
  grid-gap: 0.5rem;
  padding-inline: 0.5rem;
}

.footer {
  padding: 1rem 1.5rem;
  border-top: 1px solid #ebf0f0;
}

.inner {
  padding: 0 1.5rem;
}

.accessSection {
  padding: 1.25rem 1.5rem;
  border-top: 1px solid #ebf0f0;
  border-bottom: 1px solid #ebf0f0;
  background-color: RgbaColor($primary-dark, 0.02);
}

.accessTitle {
  font-weight: 700;
  font-size: 0.75rem;
  color: RgbaColor($primary-dark, 0.4);
  margin-bottom: 0.625rem;
}

.accessIcon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 2.25rem;
  height: 2.25rem;
  color: RgbaColor($primary-dark, 0.8);
  background-color: RgbaColor($primary-dark, 0.07);
  border-radius: 4px;
}

.accessButton {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 0;
  border: 0;
  padding: 0;
  font-weight: 700;
  color: RgbaColor($primary-dark, 0.8);
  box-shadow: none;
  background-color: transparent;

  .accessButtonValue {
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    text-transform: capitalize;
  }

  .accessButtonArrow {
    transition: transform 200ms;
  }

  &:global(.ant-dropdown-open) {
    .accessButtonArrow {
      transform: rotate(180deg);
    }
  }

  &:not(:disabled):not(:global(.ant-btn-disabled)) {
    &:hover {
      box-shadow: none;
      background-color: transparent;
    }
  }

  span {
    font-size: 0.75rem;
    font-weight: 500;
    color: RgbaColor($primary-dark, 0.6);
  }
}

.invitedUserWrap {
  width: 100%;
  padding: 1.25rem 1.5rem;
  border-top: 1px solid #ebf0f0;
}

.menuItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inviteUserName {
  font-size: 0.875rem;
  font-weight: 600;
  color: RgbaColor($primary-dark, 0.8);
  margin-right: 4px;
}

.inviteUserCompany {
  padding: 2px 6px;
  font-size: 0.75rem;
  font-weight: 700;
  color: RgbaColor($primary-dark, 0.4);
  background-color: RgbaColor($primary-dark, 0.07);
  border-radius: 4px;
}
