.animatedDiv {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 2;

  .close {
    position: absolute;
    top: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.125rem;
    right: 0.5rem;
    z-index: 12;
  }
}
