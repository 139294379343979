.root {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  font-size: 95%;
  line-height: 1;
  font-weight: 700;
  flex-shrink: 0;
  background: #0f8594;
  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
}

.inactive {
  border: 1px dashed #003e4733;
  background: white;
  color: #003e4799;
}
