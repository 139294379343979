.settingsAudit {
  overflow: hidden;
  display: flex;
  margin-bottom: 24px;
}

.delete-audit-popup {
  .ant-modal-footer {
    padding: 0;
  }
}

.setting_menu {
  position: fixed;
  width: 340px;
  list-style-type: none;
}

.settings_audit .settings_control .settings_audit-team-name .settings_audit-team-name_subtitle {
  font-size: 14px;
  color: #003e4766;
}

.settings_audit-team_edit .settings_audit-team_edit_images {
  width: 34px;
  height: 34px;
  border: 1px solid #ccd8da;
}

.setting_menu_team {
  margin-top: 75px;
}

.settings_right-audit {
  background-color: #fff;
  width: 100%;
  border-radius: 12px;
}

.permission-title {
  color: var(--neutrals-dark-80, rgba(0, 62, 71, 0.8));
  font-size: 14px;
  font-weight: 600;
  line-height: 136%;
}

.permission-description {
  color: var(--neutrals-dark-40, rgba(0, 62, 71, 0.4));
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  margin-top: 4px;
  display: block;
}

.audits_download_img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 1px solid #003e471a;
  border-radius: 4px;
  margin-left: 4px;
  cursor: pointer;
}

.settings_audit-team-icon {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: 20px;
  align-items: center;
}

.settings_audit .settings_audit-team {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 20px;
  padding-left: 25px;
  height: 79px;
  margin-left: -24px;
  padding-right: 20px;
  border-bottom: 1px solid #003e4712;
}

.settings_right-info_inner-line {
  height: 1px;
  border: none;
  background: #003e4712;
}

.settings_control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 20px;
  padding-left: 25px;
  height: 79px;
  margin-left: -24px;
  padding-right: 20px;
  border-bottom: 1px solid #003e4712;
}

.settings_audit-team-name {
  display: grid;
  grid-template-columns: 1fr;
  font-size: 16px;
  font-weight: 500;
}

.settings_audit-team-name span {
  margin-top: 4px;
}

.settings_right-info-title {
  margin-bottom: 20px;
}

.settings_right-info {
  height: auto;
  width: 100%;
}

.settings_audit-team_edit {
  display: flex;
  grid-gap: 20px;
  cursor: pointer;
}

.settings_audit-team_edit img {
  border-radius: 4px;
}

.setting_btn {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.setting_btn-add {
  width: 330px;
  height: 40px;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #49baca;
  color: #49baca;
  font-size: 12px;
  line-height: 15px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  transition: 0.6s;
}

.setting_btn-add:hover {
  background-color: #49baca;
  color: #fff;
  transition: 0.6s;
}

.pesrmission_info {
  display: flex;
  align-items: center;
  grid-gap: 24px;
  margin-top: 20px;
  padding-bottom: -20px;
  padding-top: 22px;
  border-top: 1px solid #003e4712;
  margin-left: -25px;
}

.pesrmission_info-left {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin-left: 0;
  margin-bottom: 10px;
}

.pesrmission_info .pesrmission_info-right {
  color: #003e47;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  padding-bottom: 10px;
  margin-top: 4px;
}

.permission_row {
  display: flex;
  align-items: center;
  grid-gap: 16px;
  padding: 16px 24px !important;
  cursor: pointer;
}

.permission_row > div {
  display: flex;
  flex-direction: column;
}

.permission_row .permission_title {
  color: var(--neutrals-dark-80, rgba(0, 62, 71, 0.8));
  font-size: 14px;
  font-weight: 600;
  line-height: 136%;
}

.permission_row .permission_description {
  color: var(--neutrals-dark-40, rgba(0, 62, 71, 0.4));
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  margin-top: 2px;
}

.danger_zone_warning {
  color: var(--neutrals-solid-dark-40, #99b2b5);
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  margin-top: 12px;
  display: block;
}

.settings_right-info_last-inner {
  width: 420px;
  height: 100px;
  resize: none;
  border: 1px solid #003e471a;
  border-radius: 4px;
  background-color: transparent !important;
  margin-left: 3px;
  color: #003e47;
  font-size: 16px;
  padding-left: 12px;
}

.settings_audit-team-icon_last {
  width: 34px;
  height: 34px;
}

.settings_audit-team-icon_first {
  width: 34px;
  height: 34px;
  border: 1px solid #ccd8da;
}

.settings_right-info_last-one_first {
  margin-top: -35px;
}

.settings_right-info_last-one_last {
  margin-top: -100px;
}

.pesrmission_info_check {
  border: 1px solid #003e4712;
  width: 24px;
  height: 24px;
  border-radius: 4px;
}

.icon-name-person {
  width: 34px;
  height: 34px;
  color: #fff;
  background-color: #49baca;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  text-transform: uppercase;
}

.settings_right-info_last-next-titleOne,
.settings_right-info_last-next-title {
  display: grid;
  grid-template-columns: 180px 1fr;
  align-items: center;
  grid-gap: 24px;
  width: 100%;
}

.settings_right-info_last-next-titleOne {
  align-items: flex-start;
}

.auditor-company {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 30px 38px 30px 24px;
  border-radius: 4px;
  border: 1px solid rgba(0, 62, 71, 0.07);
  background: #f2f5f6;
  width: 100%;

  @media (max-width: 860px) {
    flex-direction: column;
  }
}

.auditor-company img {
  width: 76px;
  object-fit: cover;
}

.auditor-company .auditor-info {
  display: flex;
  flex-direction: column;
}

.auditor-company .auditor-info .info-subtitle {
  color: var(--neutrals-dark-40, rgba(0, 62, 71, 0.4));
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 11px */
  letter-spacing: 1.25px;
  text-transform: uppercase;
  margin-bottom: 9px;
}

.auditor-company .auditor-info .info-company {
  color: var(--neutrals-dark-80, rgba(0, 62, 71, 0.8));
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 14px;
  font-weight: 600;
  line-height: 136%; /* 19.04px */
}

.auditor-list-heading {
  color: var(--neutrals-dark-40, rgba(0, 62, 71, 0.4));
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  display: flex;
  margin-top: 32px;
}
