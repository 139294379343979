.main-block {
  display: flex;
  flex-direction: column;
  font-family: "Raleway";
  font-style: normal;
  max-height: 350px;
  overflow-y: auto;
}

.main-block .assign-scroll-wrapper {
  padding: 0 22px 16px;
  overflow: auto;
  max-height: 300px;
}

.main-block .search-block {
  display: flex;
  position: relative;
  border-bottom: 1px solid #ebf0f0;
}

.main-block .search-block img {
  left: 12px;
  top: 10px;
  position: absolute;
  border: none !important;
}

.main-block .search-block input::placeholder {
  font-weight: 500;
  font-size: 14px;
  line-height: 136%;
  color: rgba(0, 62, 71, 0.2);
}

.main-block .search-block input {
  border: none;
  height: 44px;
  padding-inline: 52px 16px;
  width: 100%;
}

/*.main-block .table-search .dropdown-list {*/
/*  position: fixed;*/
/*}*/
.main-block .table-search {
  display: flex;
  flex-direction: column;
}

.main-block .table-search .title {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 62, 71, 0.4);
  padding-block: 16px 8px;
}

.main-block .table-search .row-search {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0px;
  cursor: pointer;
  transition: 0.3s opacity;
}

.main-block .table-search .row-search.row-disabled {
  pointer-events: none;
  opacity: 0.3;
}

.main-block .table-search .row-search .title-name {
  font-weight: 600;
  font-size: 14px;
  line-height: 136%;
  color: rgba(0, 62, 71, 0.8);
}

.main-block .table-search .row-search .block-name {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 12px;
  align-items: center;
}

.main-block .table-search .row-search .block-name .icon-name-persons {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  background: #e9d94a;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-block .table-search .row-search .block-name .icon-name-groups {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  padding: 9px 12px;
  background: #003e47;
  border-radius: 100px;
  color: #ffffff;
}

.main-block .table-search .row-search .checkbox {
  border: 2px solid rgba(0, 62, 71, 0.1);
  box-sizing: border-box;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: relative;
}

.main-block .table-search .row-search .checkbox img {
  border: none !important;
}

.main-block .table-search .row-search .checkbox-active {
  background: #49baca;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
