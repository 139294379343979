@import "../../../../global-ui-scss/variables.scss";
@import "../../../../global-ui-scss/mixins.scss";
.root {
  width: 352px;
  @include DisplayFlex(column, flex-start, flex-start);
  row-gap: 28px;
  margin-bottom: 32px;
  .contentWrapper {
    width: 100%;
    @include DisplayFlex(row, space-between, center);
    .title {
      font-weight: 800;
      font-size: 24px;
      color: $primary-dark;
    }
    .buttonWrapper {
      width: 34px;
      height: 34px;
      button {
        padding: 0px;
        height: 100%;
        .plusIng {
          width: 24px;
        }
      }
    }
  }
}
