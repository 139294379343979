.timeRangePicker {
  position: relative;
  display: inline-block;
}

.pickerOverlay {
  padding: 9px 10px;
  display: flex;
  min-width: 135px;
  justify-content: space-between;
  height: 34px;
  align-items: center;
  border-radius: 4px;
  background: transparent;
  cursor: pointer;
  border: 1px solid var(--neutrals-dark-10, rgba(0, 62, 71, 0.1));
  transition: 0.3s;
  user-select: none;
}

.timeRangePicker.disabled .pickerOverlay {
  pointer-events: none;
  opacity: 0.5;
}

.pickerOverlay:hover {
  border: 1px solid var(--neutrals-dark-20, rgba(0, 62, 71, 0.2));
  background: var(--neutrals-dark-2, rgba(0, 62, 71, 0.02));
}

.timeRangePicker.active .pickerOverlay {
  background-color: var(--neutrals-dark-7, rgba(0, 62, 71, 0.07));
  border-color: transparent;
}

.pickerOverlay span {
  color: var(--primary-dark, #003e47);
  font-family: Raleway;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 6px;
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  display: inline-block;
  background-color: gray;
  border-radius: 50%;
}

.dropdown {
  display: none;
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: var(--primary-white, #fff);
  box-shadow: 0px 6px 17px 0px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  width: 260px;
  height: 340px;
  z-index: 100;
}

.timeRangePicker.single .dropdown {
  width: 129px;
}

.timeRangePicker.single .column {
  width: 50%;
}

.chevron {
  transition: 0.3s ease-in-out;
}
.timeRangePicker.active .chevron {
  transform: rotate(180deg);
}

.timeRangePicker.active .dropdown {
  display: flex;
}

.column {
  overflow: auto;
  float: left;
  padding: 10px;
  width: 25%;
  height: 100%;
}

.column div {
  display: flex;
  height: 32px;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Raleway;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.column div:hover {
  background: var(--neutrals-solid-dark-8, #ebf0f0);
}

.column > div + div {
  margin-top: 12px;
}

.columns {
  position: relative;
  overflow: hidden;
}

.divider {
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  width: 2px;
  background: var(--neutrals-solid-dark-8, #ebf0f0);
  transform: translateX(-50%);
}

.secondaryDivider {
  position: absolute;
  top: 10px;
  bottom: 10px;
  width: 1px;
  background: var(--neutrals-solid-dark-5, #f2f5f6);
  transform: translateX(-50%);
}

.selected {
  background: var(--primary-dark, #003e47);
  color: var(--primary-white, #fff);
}

.header {
  display: flex;
  min-height: 50px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--neutrals-solid-dark-8, #ebf0f0);
  color: var(--primary-dark, #003e47);
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 136%;
}
