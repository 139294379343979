@import "mixins";

// COLORS
$color-primary-blue-main: #49baca;
$color-primary-blue-deep: #0f8594;
$color-blue-dark: #1864ab;
$secondary-blue: #49baca33;

$color-green-700: #6db333;
$color-green-200: RgbaColor($color-green-700, 0.2);

$primary-green-dark: #569720;
$secondary-green: #6db3331a;
$secondary-green20: #6db33333;
$secondary-green-800: #569720;
$secondary-green-100: rgba(109, 179, 51, 0.1);
$white: #ffff;

$color-red-700: #ff5454;
$color-red-800: #d94747;

$secondary-gray: #003e4766;
$secondary-gray-dark: #003e4712;
$secondary-red-10: rgba(255, 84, 84, 0.1);
$secondary-red-dark: #ff545433;

$primary-dark: #003e47;
$primary-neutrals-dark5: rgba(0, 62, 71, 0.05);
$primary-neutrals-dark10: #003e471a;
$primary-neutrals-dark15: #003e4726;
$primary-neutrals-dark20: rgba(0, 62, 71, 0.2);
$primary-neutrals-dark40: rgba(0, 62, 71, 0.4);
$primary-neutrals-dark60: #003e4799;
$primary-neutrals-dark80: rgba(0, 62, 71, 0.8);
$tertiary-neutrals-dark20: rgba(0, 62, 71, 0.2);

$color-neutrals-solid-1: #fafbfb;
$color-neutrals-solid-3: #f2f5f6;
$color-neutrals-solid-4: #ebf0f0;
$color-neutrals-solid-5: #dae3e5;
$color-neutrals-solid-6: #b2c5c8;
$color-neutrals-solid-7: #99b2b5;

$color-yellow-main: #fcc419;
$color-yellow-light: #fde18c;
$color-yellow-dark: #dfa800;

$secondary-orange: #f17f16;
$secondary-orange-dark10: #f17f161a;

$primary-disable: $primary-neutrals-dark40;

$color-purple: #6167f5;

// OTHER
$header-height: 70px;
$dashboard-aside-width: 74px;
$dashboard-aside-width-open: 296px;
