@import "../../../global-ui-scss/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  grid-gap: 2rem;
  padding: 1.5rem;
  background: linear-gradient(0deg, rgba(0, 62, 71, 0.05), rgba(0, 62, 71, 0.05)), #ffffff;
}

.headerPanelInner {
  display: grid;
  justify-content: space-between;
  grid-template-columns: auto 300px;
}

.headerPanel {
  border: 0;

  :global(.ant-card-body) {
    padding: 0.75rem;
  }
}

.documentList {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
}

.documentColumns {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.title {
  font-size: 24px;
  color: $primary-dark;
  font-weight: 800;
}
