.root {
  position: relative;
}

.overlay {
  padding: 9px 10px;
  display: flex;
  min-width: 135px;
  height: 42px;
  align-items: center;
  border-radius: 4px;
  background: transparent;
  cursor: pointer;
  border: 1px solid var(--neutrals-dark-10, rgba(0, 62, 71, 0.1));
  transition: 0.3s;
  user-select: none;
}

.root.disabled .overlay {
  pointer-events: none;
  opacity: 0.5;
}

.overlay:hover {
  border: 1px solid var(--neutrals-dark-20, rgba(0, 62, 71, 0.2));
  background: var(--neutrals-dark-2, rgba(0, 62, 71, 0.02));
}

.root.active .overlay {
  background-color: var(--neutrals-dark-7, rgba(0, 62, 71, 0.07));
  border-color: transparent;
}

.overlay span {
  color: var(--primary-dark, #003e47);
  font-family: Raleway, sans-serif;
  font-size: 12px;
  font-weight: 700;
  margin-left: 6px;
}

.icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  border-radius: 50%;
}

.chevron {
  margin-left: auto;
  transition: 0.3s ease-in-out;
}

.root.active .chevron {
  transform: rotate(180deg);
}

.root > :global(.ant-picker) {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  top: 0;
}

.root.error .overlay {
  border: 1px solid var(--secondary-red-700, #ff5454);
  background: var(--secondary-red-100, rgba(255, 84, 84, 0.1));
}
