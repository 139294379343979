@import "../../../global-ui-scss/mixins";
@import "../../../global-ui-scss/variables";

.label {
  @include DisplayFlex(row, start, center);
  font-weight: 700;
  font-size: 12px;
  color: RgbaColor($primary-dark, 0.6);
}

.nowrap {
  white-space: nowrap;
}

.gutterBottom {
  margin-bottom: 0.5rem;
}
