.root {
  background-color: transparent !important;
  //width: 50vw !important;
  //height: max-content !important;

  :global(#pdf-page-info),
  :global(#pdf-controls) {
    display: none;
  }

  :global(#pdf-page-wrapper + #pdf-page-wrapper) {
    margin-top: 16px;
  }

  :global(#txt-renderer) {
    background-color: white;
  }

  table {
    background-color: white;
    border-collapse: collapse;

    th,
    td {
      border: 1px solid black;

      &:empty {
        display: table-cell;
      }
    }
  }

  iframe {
    height: 80vh;
  }

  .loader {
    margin-top: 2.5rem;
  }
}
