@import "../../../global-ui-scss/mixins";
@import "../../../global-ui-scss/variables";

.content {
  display: grid;
  grid-gap: 1.25rem;

  .uploadForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;

    button {
      width: 100%;
    }
  }
}

.description {
  text-align: center;
  color: RgbaColor($primary-dark, 0.6);
  margin: 0;
}
