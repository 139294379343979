@import "../../../../../global-ui-scss/mixins";
@import "../../../../../global-ui-scss/variables";

.root {
  display: inline-flex;
  align-items: center;
  grid-gap: 4px;
  font-size: 0.75rem;
  font-weight: 500;
  color: $color-neutrals-solid-7;
  text-transform: lowercase;

  &:hover {
    text-decoration: underline;
    color: $color-primary-blue-main;
  }
}

.tooltip {
  background-color: #f3f3f3;
}

.overlay {
  &:global(.ant-dropdown) {
    :global(.ant-dropdown-menu) {
      padding: 0;
      max-height: inherit;

      :global(.ant-dropdown-menu-item) {
        padding: 0.5rem;

        &:hover {
          background-color: RgbaColor($color-neutrals-solid-7, 0.15);
        }
      }

      :global(.ant-dropdown-menu-item-group-title) {
        font-weight: 700;
        font-size: 0.75rem;
        padding: 0.75rem 0.875rem 0.5rem;
        color: RgbaColor($primary-dark, 0.4);
      }

      :global(.ant-dropdown-menu-item-group) {
        padding-bottom: 4px;
      }

      :global(.ant-dropdown-menu-item-group-list) {
        margin: 0 4px;
      }
    }
  }
}
