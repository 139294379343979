.btn_blue-resend {
  margin-top: 10px;
  width: 341px;
  height: 56px;
  background-color: transparent;
  border: 2px solid #49baca;
  color: #49baca;
  font-size: 14px;
  border-radius: 8px;
  font-weight: 700;
  text-transform: none;
  line-height: 16px;
  transition: 0.5s;
}

.btn_blue-resend:hover {
  background-color: #49baca;
  border: 2px solid #fff;
  color: #fff;
}

.login_form-link {
  display: block;
  text-align: center;
  color: #49baca;
  font-size: 0.875rem;
  padding: 0.25rem;
  margin: 1rem auto 0;
}

.resend_span {
  color: #49baca;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.resend_block {
  text-align: center;
  padding: 12px 16px;
  background: rgba(73, 186, 202, 0.1);
  border-radius: 4px;
  border: 1px solid #49baca;
}
