@import "../../../../global-ui-scss/mixins.scss";
@import "../../../../global-ui-scss/variables.scss";

.row {
  @include DisplayFlex(row, flex-start, center);
  width: 100%;
  gap: 16px;
}

.column {
  width: 100%;
  @include DisplayFlex(column, center, flex-start);
  gap: 8px;
}

.disabled > .column,
.disabled > .row {
  pointer-events: none;
  opacity: 0.5;
}

.label {
  color: var(--neutrals-dark-40, rgba(0, 62, 71, 0.4));
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.description {
  color: var(--neutrals-solid-dark-40, #99b2b5);
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  margin-top: 4px;
  line-height: normal;
}

.option {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  border-radius: 4px;
  outline: 1px solid var(--neutrals-dark-20, rgba(0, 62, 71, 0.2));
  color: var(--neutrals-dark-80, rgba(0, 62, 71, 0.8));
  text-align: center;
  font-family: Raleway;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.24px;
  background: transparent;
  transition: 0.2s;
  width: 100%;
  cursor: pointer;

  &:not(.selected):hover {
    background: rgba(0, 62, 71, 0.02);
    outline: 1px solid rgba(0, 62, 71, 0.2);
    color: #003e47;
  }

  &.selected {
    border-radius: 4px;
    color: var(--Primary-Dark, #003e47);

    &.otherOption {
      outline: 2px solid var(--Primary-Blue, #49baca);
    }

    &:not(.otherOption) {
      background: linear-gradient(0deg, rgba(73, 186, 202, 0.1), rgba(73, 186, 202, 0.1));
      outline: 1px solid #49baca;
    }
  }

  &.disabled {
    pointer-events: none;
    outline: 1px solid var(--neutrals-dark-20, rgba(0, 62, 71, 0.1));
    color: var(--neutrals-dark-40, rgba(0, 62, 71, 0.4));
  }

  &.big {
    height: 68px;
  }

  &.error {
    outline: 1px solid #ff5454;
    background-color: rgba(255, 84, 84, 0.1);
  }
}

.other {
  > div {
    margin-top: 8px;
  }

  > input {
    margin-top: 8px;
  }
}
