@import "../../../global-ui-scss/variables.scss";

.container {
  position: relative;
  width: 100%;
  --PhoneInputCountryFlag-height: 0.75em;
}

.withLabel {
  :global(.PhoneInputInput) {
    padding-top: 1.625rem;
  }
}

.countrySelect {
  background-color: #f5f8fa;
  border: none;
}

.highlighted {
  input {
    background-color: #fffce4;
  }
}

.input {
  padding: 0;
  font-size: 16px;
  margin: 0;
  border: 1px solid #e2e8f0;
  border-radius: 5px;
  overflow: hidden;
  gap: 0;

  input {
    padding: 10px 12px;
  }

  :global(.PhoneInputCountry) {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 0.25rem;
    padding-left: 0.3125rem;
    margin: 0;
    width: 60px;
    background-color: $primary-neutrals-dark10;
  }

  &:global(.PhoneInput--focus) {
    border-color: $color-primary-blue-main;
  }
}

.input:focus {
  outline: none;
}

.withError {
  .input {
    border-color: $color-red-700;
    background-color: $secondary-red-10;

    input {
      background-color: transparent;
    }
  }
}

.errorText {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  color: $color-red-700;
}

.label {
  position: absolute;
  top: 0.375rem;
  left: 4.75rem;
  font-size: 0.75rem;
  font-weight: 700;
  color: #003e47cc;
  z-index: 5;
}
