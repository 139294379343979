@import "../../../../../../../global-ui-scss/mixins";
@import "../../../../../../../global-ui-scss/variables";

.collapse {
  background-color: transparent;

  &:global(.ant-collapse) {
    :global(.ant-collapse-item) {
      margin-top: 0.25rem;
      border-radius: 4px;
      color: $primary-dark;
      background-color: #ffffff;
      border: 1px solid $color-neutrals-solid-4;
      transition: border-color 200ms;

      &:global(.ant-collapse-item-active) {
        border-bottom-width: 1px;
        border-color: $color-primary-blue-main;

        :global(.ant-collapse-header) {
          border-bottom-color: inherit;
        }

        :global(.ant-collapse-expand-icon) {
          padding: 0;
          transform: rotate(180deg);
        }
      }

      :global(.ant-collapse-header) {
        padding: 0 1rem 0 0;
        align-items: center;
        border-bottom: 1px solid transparent;
      }

      :global(.ant-collapse-expand-icon) {
        padding: 0 !important;
        transition: transform 250ms;
      }

      :global(.ant-collapse-content) {
        :global(.ant-collapse-content-box) {
          padding: 0;
        }
      }
    }
  }
}
