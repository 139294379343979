@import "../../../../../global-ui-scss/mixins";
@import "../../../../../global-ui-scss/variables";

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  grid-gap: 2rem;
}
