@import "../../../../../../global-ui-scss/mixins";
@import "../../../../../../global-ui-scss/variables";

.wrapper {
  height: 100%;
  background-color: $color-neutrals-solid-1;
}

.title {
  font-size: 1rem;
  font-weight: 700;
  color: $primary-dark;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5rem;
}

.tabs {
  &:global(.ant-tabs) {
    :global(.ant-tabs-nav) {
      padding-inline: 1.5rem;
      margin: 0;
    }

    :global(.ant-tabs-tab) {
      padding: 0.25rem 0.625rem 0.625rem;
      min-width: 92px;
      justify-content: center;

      + :global(.ant-tabs-tab) {
        margin-left: 0;
      }

      :global(.ant-tabs-tab-btn) {
        font-size: 0.75rem;
        font-weight: 600;
      }
    }

    :global(.ant-tabs-tabpane) {
      padding: 1.5rem;
    }
  }

  .tabsMore {
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
  }
}
