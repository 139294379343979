.root {
  display: grid;
  cursor: pointer;
  border-radius: 8px;
  padding: 12px 16px 14px;
  height: max-content;
  width: 100%;
  transition: 0.4s;

  &.bordered {
    border-radius: 8px;
    border: 1px solid var(--neutrals-dark-10, rgba(0, 62, 71, 0.1));
  }
  &:hover {
    background: rgba(0, 62, 71, 0.07);
    border-radius: 8px;
    transition: 0.4s;
  }
  &.active {
    background: #ffffff;
    border-radius: 10px;
    transition: 0.4s;
  }
}
