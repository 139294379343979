@import "../../../../global-ui-scss/mixins.scss";
@import "../../../../global-ui-scss/variables.scss";

.container {
  width: 100%;
  border: 1px solid #ebf0f0;
  border-radius: 4px;

  .commentsList {
    width: 100%;
    max-height: 500px;
    overflow: auto;
    @include DisplayFlex(column, center, flex-start);
  }

  .commentInputWrapper {
    width: 100%;

    input {
      border-radius: 0;
      border-left: none;
      border-bottom: none;
      border-right: none;
      outline: none;
      border-top: 1px solid rgba(0, 62, 71, 0.1019607843) !important;
    }
  }
}
