@import "../global-ui-scss/variables.scss";

.custom_text_area textarea {
  font-size: 14px;
  line-height: 22px;
  outline: 1px solid $primary-neutrals-dark10;
  border: none !important;
  padding: 14px 15px;
  transition: 0.3s, 0s height;
  border-radius: 4px;
}

.custom_text_area label {
  margin-bottom: 5px;
  display: block;
}

.custom_text_area textarea:hover {
  background: rgba(0, 62, 71, 0.02);
  outline: 1px solid rgba(0, 62, 71, 0.2);
  border: none !important;
  color: #003e47;
}

.custom_text_area textarea::placeholder {
  color: var(--neutrals-dark-40, rgba(0, 62, 71, 0.4));
  font-size: 14px;
  font-weight: 500;
  line-height: 136%; /* 19.04px */
}

.custom_text_area textarea:focus {
  outline: 2px solid $color-primary-blue-main;
  border: none !important;
}

.custom_text_area_error textarea:focus,
.custom_text_area_error textarea:hover,
.custom_text_area_error textarea {
  outline: 1px solid #ff5454;
  border: none !important;
  background-color: rgba(255, 84, 84, 0.1);
}

.custom_text_area_error input:focus,
.custom_text_area_error input {
  border: 1px solid var(--error);
}

.custom_text_area_error .ant-input:focus,
.custom_text_area_error .ant-input-focused {
  box-shadow: unset;
}

.custom_text_area_error .ant-input,
.custom_text_area_error .ant-input:hover {
  border: 1px solid var(--error);
}

.custom_text_area_error .error {
  font-size: 12px;
  line-height: 14px;
  color: var(--error);
}

.custom_text_area textarea:disabled:hover,
.custom_text_area textarea:disabled {
  color: #003e4799;
  background: #003e4712;
  outline: none !important;
}
