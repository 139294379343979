@import "../../../../global-ui-scss/mixins.scss";
@import "../../../../global-ui-scss/variables.scss";

.wrapper {
  @include DisplayFlex(row, center, center);
  gap: 8px;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 6px 17px rgba(0, 0, 0, 0.1);
  background: #ffffff;

  .button {
    padding: 5px;
    border-radius: 4px;
  }
}
