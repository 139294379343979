.landing {
  background-color: var(--bg-main-green);
}

.landing__wrapper {
  color: #ffffff;
}

.all-page_white {
  background-color: var(--bg-main-gray);
}
