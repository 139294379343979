@import "../../../global-ui-scss/variables.scss";

.container {
  .root {
    width: 100%;

    .input {
      padding-left: 0.625rem;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
    }

    input {
      &::placeholder {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: $primary-disable;
      }
    }

    input.inputFocused {
      ~ fieldset {
        border-color: $primary-neutrals-dark10;
        border-width: 1px;
      }
    }
  }

  fieldset {
    border-color: $primary-neutrals-dark10;
  }

  .listbox {
    max-height: 13rem;
    padding: 0.25rem;
    font-size: 0.875rem;

    :global(.MuiAutocomplete-option) {
      padding: 0.75rem 1rem;
    }
  }
}

.paper.autocompletePaper {
  border-radius: 4px;
  box-shadow: 0 7px 25px 0 rgba(0, 0, 0, 0.25);
}
