/* .revision_wrapper__view_without_documents {
    margin-top: 120px;
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-gap: 0 80px;
    align-items: center;
    justify-items: center;
} */
.revision_wrapper__view_without_documents {
  margin-top: 110px;
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-gap: 0 130px;
  align-items: center;
  justify-items: center;
}
.revision_wrapper__view_without_documents .info {
  display: grid;
  grid-gap: 26px 0;
  height: max-content;
  max-width: 400px;
  color: #003e47;
}
.revision_wrapper__view_without_documents a {
  text-decoration: unset;
}

.revision_wrapper__view_without_documents .info .description {
  color: rgba(0, 62, 71, 0.8);
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 148%;
}

.revision_wrapper__waste_time {
  margin: 180px 0;
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
}

.revision_wrapper__waste_time .info {
  margin-left: -200px;
  z-index: 1;
  position: relative;
}

.revision_wrapper__waste_time .info .title {
  font-weight: 800;
  font-size: 32px;
  line-height: 110%;
  color: #003e47;
}

.revision_wrapper__waste_time .info .description {
  margin-top: 32px;
}

.revision_wrapper__waste_time .info .description p {
  font-weight: 500;
  font-size: 16px;
  line-height: 148%;
  color: rgba(0, 62, 71, 0.8);
}

.revision_wrapper__waste_time .image_wrapper {
  background: #ffffff;
  border-radius: 16px;
  position: relative;
  width: 550px;
  height: 465px;
}

.revision_wrapper__waste_time .image_wrapper img {
  width: 400px;
  max-height: 450px;
  position: absolute;
  bottom: -32px;
  left: -56px;
}

.aivo_unique {
  margin: 180px 0;
}

.aivo_unique .aivo_unique__wrapper {
  display: grid;
  margin-top: 80px;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 60px;
}

.aivo_unique .aivo_unique__wrapper .every {
  display: grid;
  height: max-content;
  justify-items: center;
  grid-template-rows: 200px 1fr max-content;
}

.revision_wrapper .slick-slider {
  cursor: grab !important;
}

.aivo_unique .aivo_unique__wrapper .every .title {
  font-weight: 800;
  font-size: 20px;
  line-height: 110%;
  text-align: center;
  color: #003e47;
}

.supported_accounting .description {
  font-weight: 500;
  font-size: 16px;
  line-height: 148%;
  color: rgba(0, 62, 71, 0.8);
}

.supported_accounting {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 140px;
  margin: -20px 0 140px 0;
}

.supported_accounting .preview {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.supported_accounting .preview .every {
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.04em;
  padding: 46px 0;
  width: 100%;
  border: 1px solid rgba(0, 62, 71, 0.2);
  text-transform: uppercase;
  color: #003e47;
  border-radius: 8px;
  display: grid;
  align-items: center;
  justify-content: center;
}

.supported_accounting .preview .btn_gray {
  background: rgba(0, 62, 71, 0.1);
  border-radius: 8px;
  width: 100%;
  padding: 33px 0;
  grid-column: 1/-1;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #003e47;
}

.supported_accounting .preview .btn_gray:hover {
  border: 1px solid var(--bg-main-gray);
}

.aivo_unique .aivo_unique__wrapper .every .description {
  font-weight: 500;
  font-size: 14px;
  line-height: 136%;
  text-align: center;
  color: rgba(0, 62, 71, 0.8);
  margin-top: 0;
  max-width: 279px;
}

.revision_wrapper .growing_profile_wrapper {
  background: #fff;
  padding: 60px 0;
  margin: 180px 0 180px calc((-100vw - -1128px) / 2);
  width: 100vw;
}

.revision_wrapper .growing_profile_wrapper .wrapper_title {
  text-align: center;
  margin-bottom: 40px;
}

.revision_wrapper .growing_profile_wrapper .partners-logo img {
  border: transparent 2px solid;
  transition: 400ms;
  width: auto;
  height: 100px;
}

.revision_wrapper .growing_profile_wrapper .partners-logo img:hover {
  border: rgba(0, 37, 42, 0.5) 2px solid;
  transition: 400ms;
}

.revision_wrapper .editorial_wrapper__every {
  padding: 8px 60px;
  position: relative;
}

.revision_wrapper .editorial_wrapper__every .title {
  margin: 24px 0;
}

.revision_wrapper .editorial_wrapper_title {
  text-align: center;
  margin: 100px 0 60px;
}

.revision_wrapper .editorial_wrapper_title span {
  font-weight: 800;
  font-size: 40px;
  line-height: 110%;
  color: #003e47;
}

.revision_wrapper .editorial_wrapper__every .btn_orange {
  text-decoration: unset;
}

/* .revision_wrapper .editorial_wrapper {
  position: relative;
} */

.revision_wrapper .editorial_wrapper .img_wrapper {
  background-color: #003840;
  width: 91px;
  height: 91px;
  display: grid;
  align-items: center;
  position: relative;
  justify-content: center;
  z-index: 10;
  border-radius: 50%;
  margin: auto;
}

.editorial_wrapper__every .line {
  position: absolute;
  top: 30px;
  z-index: -1;
  margin-left: 12px;
  left: 0;
  right: 0;
  width: calc(100% + 0.5px);
}

.revision_wrapper .editorial_wrapper .title {
  font-weight: 800;
  font-size: 20px;
  line-height: 110%;
  color: #003e47;
  text-align: center;
  margin-top: 40px;
}
.revison_button {
  display: block !important;
  margin: 0 auto !important;
}

.editorial_wrapper .slick-next,
.editorial_wrapper .slick-prev {
  top: -60px !important;
  background: #fff;
  border: 5px solid rgba(0, 62, 71, 0.07);
  border-radius: 6px;
  width: 48px;
  height: 48px;
}

.editorial_wrapper {
  position: relative;
  margin-bottom: -50px !important ;
}

.editorial_wrapper .art_price_body_slider_arrow {
  width: 48px;
  height: 48px;
  display: grid !important;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 5px solid rgba(0, 62, 71, 0.07);
  border-radius: 6px;
  position: absolute;
  top: 44px !important;
  z-index: 10;
  cursor: pointer !important;
}

.editorial_wrapper .art_price_body_slider_arrow:before {
  content: unset;
}

.editorial_wrapper .art_price_body_slider_arrow.slick-disabled {
  opacity: 0.4;
  cursor: default !important;
  display: none !important;
}

.editorial_wrapper .art_price_body_slider_arrow_left {
  left: 0;
  /* left: -55px !important; */
}

.editorial_wrapper .art_price_body_slider_arrow_left svg {
  transform: rotate(180deg);
}

.revision_wrapper .editorial_wrapper .description {
  font-weight: 500;
  font-size: 16px;
  line-height: 148%;
  color: rgba(0, 62, 71, 0.8);
  margin-bottom: 28px;
  text-align: center;
}

.revision_wrapper .growing_profile_wrapper .wrapper_title span {
  font-weight: bold;
  font-size: 16px;
  line-height: 110%;
  letter-spacing: 0.125em;
  text-transform: uppercase;
  color: #003e47;
}

.aivo_unique__title {
  text-align: center;
}

.aivo_unique__title .title {
  font-weight: 800;
  font-size: 40px;
  line-height: 110%;
  color: #003e47;
}

.revision_wrapper .growing_profile_wrapper .slick-slide {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.revision_wrapper .growing_profile_wrapper .slick-slide .slick-active {
  display: flex;
  justify-content: center;
}

.revision_wrapper .growing_profile_wrapper .slick-track {
  outline: none !important;
}

.revision_wrapper .info .title {
  font-weight: 800;
  font-size: 40px;
  line-height: 110%;
  color: #003e47;
}

.editorial_wrapper .slick-track .slick-slide:last-child .line {
  transform: rotateY(180deg);
}

@media screen and (max-width: 1240px) {
  .revision_wrapper .growing_profile_wrapper {
    background: #fff;
    padding: 60px 0;
    margin: 180px 0 180px calc((-100vw - -100vw - 100px) / 2);
    width: 100vw;
  }
}

@media screen and (max-width: 890px) {
  .supported_accounting .preview {
    grid-template-columns: none;
  }
}

@media screen and (max-width: 855px) {
  .aivo_unique .aivo_unique__wrapper {
    grid-template-columns: none;
  }

  .revision_wrapper .info .title {
    font-size: 32px;
  }
}

@media screen and (max-width: 800px) {
  .revision_wrapper .info .title {
    font-size: 24px;
  }
}

@media screen and (max-width: 768px) {
  .revision-img {
    width: 400px;
    height: 400px;
  }

  .supported_accounting {
    grid-template-columns: none;
  }

  .revision_wrapper__view_without_documents {
    grid-template-columns: none;
  }

  .revision_wrapper__waste_time .image_wrapper {
    grid-row-start: 2;
    width: 400px;
    height: 360px;
  }

  .revision_wrapper__waste_time .info {
    margin-left: 0;
  }

  .revision_wrapper__waste_time {
    grid-template-columns: none;
    margin-top: 120px;
  }

  .revision_wrapper__waste_time .info .description {
    margin-top: 24px;
  }

  .revision_wrapper__waste_time .image_wrapper img {
    width: 270px;
    bottom: -20px;
    left: 11px;
  }

  /* casing some issues with margin */

  /* .revision_wrapper .editorial_wrapper__every {
    padding: 0;
    height: 360px;
  } */

  .editorial_wrapper .art_price_body_slider_arrow_left {
    left: -35px;
  }

  .editorial_wrapper .art_price_body_slider_arrow_right {
    right: -35px;
  }
}

@media screen and (max-width: 550px) {
  .revision_wrapper .growing_profile_wrapper {
    margin: 180px 0 180px calc((-100vw - -100vw - 30px) / 2);
  }

  .revision_wrapper .growing_profile_wrapper .slick-slide img {
    outline: none;
  }

  .editorial_wrapper .art_price_body_slider_arrow {
    top: 50px !important;
  }

  .editorial_wrapper .art_price_body_slider_arrow.slick-disabled {
    opacity: 0;
  }

  .editorial_wrapper .slick-track .slick-slide .img_wrapper {
    margin: 0 auto;
  }

  .editorial_wrapper .art_price_body_slider_arrow_right {
    z-index: 10;
    right: 12px;
  }

  .editorial_wrapper .art_price_body_slider_arrow_left {
    left: 12px;
    z-index: 10;
  }
}

@media screen and (max-width: 430px) {
  .revision_wrapper__view_without_documents {
    /* grid-template-columns: 300px; */
    margin-top: 70px;
  }

  .revision_wrapper .info .title {
    font-size: 32px;
  }

  .revision-img {
    width: 300px;
    height: 266px;
  }

  .revision_wrapper__waste_time .image_wrapper {
    grid-row-start: 2;
    width: 100%;
    height: 290px;
  }

  .revision_wrapper__waste_time .info {
    max-width: 290px;
    margin-left: 0;
  }

  .revision_wrapper__waste_time .info .title span {
    font-size: 24px;
  }

  .revision_wrapper__waste_time {
    grid-template-columns: none;
    margin-top: 120px;
  }

  .revision_wrapper__waste_time .info .description {
    margin-top: 24px;
  }

  .revision_wrapper__waste_time .image_wrapper img {
    width: 270px;
    height: 253px;
    bottom: -20px;
    left: 11px;
  }

  .aivo_unique__title .title {
    font-size: 32px;
  }

  .aivo_unique .aivo_unique__wrapper {
    grid-template-columns: none;
  }

  .revision_wrapper .growing_profile_wrapper .wrapper_title {
    padding: 20px;
  }

  .supported_accounting {
    grid-template-columns: none;
    grid-gap: 3px;
  }

  .supported_accounting .preview {
    grid-template-columns: none;
  }
}
