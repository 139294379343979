html {
  scroll-behavior: smooth;
}

.be-our-partner {
  margin-top: 105px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  align-items: center;
  justify-items: center;
}

.partners-info {
  display: grid;
  grid-gap: 26px 0;
  height: max-content;
  max-width: 400px;
  color: #003e47;
}

.partners-info span {
  font-weight: 800;
  font-size: 40px;
  line-height: 110%;
  color: #003e47;
}

.partners-info p {
  line-height: 148%;
  color: rgba(0, 62, 71, 0.8);
  margin: 0;
  font-weight: 500;
  font-size: 16px;
}

.our-opportunities {
  display: grid;
  margin-top: 255px;
}

#section_partners {
  margin-top: 200px;
}
.our-opportunities .headline {
  font-weight: 800;
  font-size: 40px;
  line-height: 110%;
  color: #003e47;
  text-align: center;
}

.opportunities-blocks {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 60px;
  margin-top: 80px;
  margin-bottom: 100px;
}

.aivo-software .title,
.aivo-trustees .title {
  font-weight: bold;
  font-size: 16px;
  line-height: 110%;
  text-align: center;
  letter-spacing: 0.125em;
  text-transform: uppercase;
  color: #003e47;
}

.aivo-software,
.aivo-trustees {
  background: #ffffff;
  border-radius: 16px;
  padding: 36px 48px;
  display: grid;
}

.interview,
.progressive-app {
  padding: 50px;
}

.interview {
  margin-bottom: 20px;
}

.subparagraph {
  font-size: 20px;
  line-height: 130%;
  font-weight: bold;
  color: rgba(0, 62, 71, 0.8);
}

.aivo-software .description,
.aivo-trustees .description {
  font-size: 16px;
  line-height: 130%;
  color: rgba(0, 62, 71, 0.8);
}

.opportunities-blocks Button:hover {
  color: #f17f16;
  border: 1px solid #f17f16;
}
*section2 {
  margin-top: 200px;
}

@media screen and (max-width: 1188px) {
  .opportunities-blocks {
    grid-template-columns: 500px 500px;
  }
}

@media screen and (max-width: 1130px) {
  .opportunities-blocks {
    grid-template-columns: 466px 466px;
    grid-gap: 30px;
  }
  .interview,
  .progressive-app {
    padding: 30px;
  }
  .aivo-software,
  .aivo-trustees {
    padding: 27px 34px;
  }
}

@media screen and (max-width: 1030px) {
  .opportunities-blocks {
    grid-template-columns: 420px 420px;
  }
  .aivo-software,
  .aivo-trustees {
    display: inline;
  }
}

@media screen and (max-width: 932px) {
  .opportunities-blocks {
    grid-template-columns: 380px 380px;
  }
  .interview,
  .progressive-app {
    width: 300px;
    margin: auto;
  }
}

@media screen and (max-width: 864px) {
  .be-our-partner {
    grid-template-columns: none;
    justify-items: start;
  }
  .opportunities-blocks {
    grid-template-columns: none;
  }
  .aivo-software,
  .aivo-trustees {
    display: grid;
  }
  .business-deal {
    width: 350px;
  }
  .our-opportunities {
    margin-top: 100px;
  }
}

@media screen and (max-width: 615px) {
  .our-opportunities .headline {
    font-size: 35px;
  }
}

@media screen and (max-width: 506px) {
  .our-opportunities .headline {
    font-size: 30px;
  }
}

@media screen and (max-width: 438px) {
  .our-opportunities .headline {
    font-size: 23px;
  }
  .partners-info span {
    font-size: 32px;
  }
}
@media screen and (max-width: 425px) {
  .be-our-partner {
    margin-top: 70px;
  }
}
@media screen and (max-width: 375px) {
  .aivo-software,
  .aivo-trustees {
    max-width: 341px;
  }
  .opportunities-blocks {
    margin-bottom: 0;
  }
}
