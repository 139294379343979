.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 24px 48px;
  gap: 12px;
  min-height: 327px;

  span {
    max-width: 400px;

    &.title {
      color: #003e47;
      font-size: 18px;
      font-weight: 700;
      line-height: 21.6px;
      text-align: center;
    }

    &.description {
      font-size: 14px;
      font-weight: 500;
      line-height: 19.04px;
      text-align: center;
      color: #003e47cc;
    }
  }

  .code {
    padding: 16px;
    background: #003e4705;
  }

  button {
    width: 154px;
  }
}

.gutterTop {
  margin-top: 12px;
}
