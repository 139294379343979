@import "../../global-ui-scss/mixins.scss";
@import "../../global-ui-scss/variables.scss";

.ant-tag {
  font-weight: 700;
  color: RgbaColor($primary-dark, 0.8);
  background: RgbaColor($primary-dark, 0.1);
  border: 0;
  line-height: 24px;

  &.ant-tag-orange {
    color: $secondary-orange;
    background-color: RgbaColor($secondary-orange, 0.2);
  }
}
