@import "../../global-ui-scss/mixins";
@import "../../global-ui-scss/variables";

.ant-radio-wrapper {
  .ant-radio-inner {
    width: 24px;
    height: 24px;
    border: 2px solid RgbaColor($primary-dark, 0.4);
  }

  span.ant-radio + * {
    font-weight: 700;
    font-size: 0.75rem;
    color: RgbaColor($primary-dark, 0.8);
    letter-spacing: 0.02em;
    padding-inline: 0.75rem;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: $color-primary-blue-main;
    background-color: $color-primary-blue-main;

    &::after {
      transform: scale(0.5);
    }
  }
}

.ant-radio-button-wrapper {
  height: 38px;
  line-height: 36px;
  border-color: RgbaColor($primary-dark, 0.1);
}

.ant-radio-button-wrapper-checked {
  &:not(.ant-radio-button-wrapper-disabled) {
    &:first-child {
    }
  }
}

.ant-radio-group {
  width: 100%;

  &.ant-radio-group-outline {
    display: flex;
    text-align: center;
    grid-gap: 1rem;

    .ant-radio-button-wrapper {
      flex-grow: 1;
      border-radius: 4px;
      font-size: 0.75rem;
      font-weight: 700;
      color: $primary-dark;
      border: 1px solid RgbaColor($primary-dark, 0.1);

      &.ant-radio-button-wrapper-disabled {
        border-color: transparent;
        background-color: RgbaColor($primary-dark, 0.02);
      }

      &:not(:first-child) {
        &::before {
          display: none;
        }
      }

      &.ant-radio-button-wrapper-checked {
        background-color: RgbaColor($color-primary-blue-main, 0.1);
        border-color: $color-primary-blue-main;
      }
    }
  }
}
