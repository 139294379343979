:global(.parent-upload-drag-active) {
  .attachmentDropzone {
    opacity: 1;
  }
}

.attachmentDropzone {
  position: absolute;
  inset: 0;
  box-shadow: 0px 0px 4px 0px #54b4b4;
  border: 1px solid #49baca;
  background: #003e4712;
  z-index: 29;
  pointer-events: none;
  transition: opacity 0.3s;
  border-radius: 8px;
  opacity: 0;

  .visualAssist {
    position: absolute;
    left: 50%;
    bottom: 17%;
    transform: translateX(-50%);
    display: flex;
    gap: 16px;
    align-items: center;
    width: 321px;
    padding: 14px 24px 14px 14px;
    border: 1px solid #49baca;
    box-shadow: 0px 0px 4px 0px #54b4b4;
    background: white;
    border-radius: 8px;

    svg {
      min-width: 72px;
      min-height: 72px;
      padding: 20px;
      background: #f2f5f6;
      border-radius: 4px;

      path {
        fill: #49baca;
        fill-opacity: 1;
      }
    }

    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 19.04px;
      color: #003e47cc;
    }
  }
}
