.content {
  display: flex;
  flex-direction: column;
  grid-gap: 1.5rem;
  text-align: center;

  .title {
    font-weight: 800;
  }

  .info {
    margin-bottom: 0.5rem;
  }

  .inputWrapper {
    display: flex;
    flex-direction: column;
    grid-gap: 0.25rem;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
  }
}
