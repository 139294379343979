@import "../../../../../global-ui-scss/variables.scss";

.role {
  padding: 10px 16px;
  border-radius: 4px;
  background: var(--neutrals-dark-7, rgba(0, 62, 71, 0.07));
  color: var(--neutrals-dark-60, rgba(0, 62, 71, 0.6));
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.name {
  color: $primary-dark;
  font-size: 14px;
  font-weight: 500;
  font-variant-numeric: lining-nums proportional-nums;
}

.tooltip {
  display: flex;
  align-items: center;
  opacity: 0.6;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }
}

.avatar {
  height: 40px;
  width: 40px;

  svg,
  img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
  }
}
