.blue-window {
  background: #49baca;
  border-radius: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 58px;
  margin: 60px 0;
}

.left-side-blue {
  display: grid;
  grid-template-rows: 1fr 80px 30px;
  margin-top: -18px;
}

.left-side-blue {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.left-side-blue h2 {
  font-weight: 800;
  font-size: 32px;
  line-height: 110%;
  color: #fff;
}

.undrawSegment {
  margin-left: 120px;
}

.appointment {
  font-weight: 500;
  font-size: 16px;
  line-height: 148%;
  color: rgba(255, 255, 255, 0.8);
  padding-bottom: 32px;
}

.blue-window a {
  text-decoration: unset;
}

@media screen and (max-width: 988px) {
  .left-side-blue {
    grid-template-rows: none;
  }
}

@media screen and (max-width: 800px) {
  .right-side-blue img {
    width: 482px;
  }
}

@media screen and (max-width: 783px) {
  .blue-window {
    grid-template-columns: none;
    padding: 70px;
    justify-items: center;
  }

  .right-side-blue {
    grid-row-start: 1;
  }
}

@media screen and (max-width: 640px) {
  .left-side-blue {
    max-width: 498px;
  }
}

@media screen and (max-width: 590px) {
  .left-side-blue {
    max-width: 450px;
  }

  .right-side-blue img {
    width: 400px;
    height: 200px;
  }
}

@media screen and (max-width: 526px) {
  .left-side-blue {
    max-width: 337px;
  }
}

@media screen and (max-width: 500px) {
  .left-side-blue {
    max-width: 346px;
  }

  .right-side-blue img {
    width: 355px;
    height: 211px;
  }
}

@media screen and (max-width: 430px) {
  .blue-window {
    grid-template-columns: none;
    /* padding: 20px; */
    justify-content: center;
    padding: 10px 0 50px 0;
  }

  .right-side-blue {
    grid-row-start: 1;
  }

  .right-side-blue img {
    width: 270px;
    height: 167px;
  }

  .left-side-blue h2 {
    font-size: 24px;
    padding: 3px;
  }

  .left-side-blue {
    grid-template-rows: none;
    max-width: 265px;
  }
}

@media screen and (max-width: 310px) {
  .left-side-blue {
    max-width: 215px;
  }
}
