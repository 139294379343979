@import "../../../../../global-ui-scss/mixins";
@import "../../../../../global-ui-scss/variables";

.body {
  padding: 28px 24px;
}

.bodyInner {
  display: flex;
  justify-content: space-between;
  margin-bottom: 55px;
}

.title {
  font-weight: bold;
  font-size: 1rem;
  color: $primary-dark;
}

.descriptionWrapper {
  margin-top: 1.25rem;
}

.alignRight {
  text-align: right;
}

.description {
  font-weight: 600;
  font-size: 14px;
  margin: 0.5rem 0 0;
  color: RgbaColor($primary-dark, 0.6);
}

.progress {
  width: 142px;
}

.statusLabelWrapper {
  padding-right: 0.5rem;
}

.statusLabel {
  display: block;
  cursor: default;
  max-width: max-content;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0.5rem;
}
