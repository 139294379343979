.footer-aivo {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 15px;
  font-size: 14px;
  margin-bottom: 90px;
}

.footer-aivo .logo {
  grid-column: 1/-1;
  margin-bottom: 65px;
  height: 25px;
}

.footer-blocks {
  display: grid;
  grid-template-rows: 50px 1fr 1fr 1fr;
  grid-gap: 10px;
}

.footer-word {
  color: rgba(255, 255, 255, 0.4);
  font-weight: 500;
}

.footer-blocks .footer-address {
  word-break: break-word;
}

.footer-aivo-gray .footer-word {
  color: rgba(0, 62, 71, 0.4);
}

.footer-aivo * {
  font-weight: 500;
  font-size: 14px;
  height: max-content;
  line-height: 148%;
}

.footer-text .info {
  background-color: rgba(255, 255, 255, 0.8);
}

.wrapper_app_gray .footer-blocks a:hover {
color: var(--bg-main-orange);
}

.wrapper_app_green .footer-blocks a:hover {
  color: #ffffff;
}

.footer-blocks .mail-info:hover {
  background-color: #ffffff;
}

.footer-aivo-gray .footer-text {
  color: rgba(0, 62, 71, 0.8);
}
.footer-text {
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  position: relative;
}

.footer-text:hover .mail-info {
  width: 18px;
  height: 3px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 6px;
  position: absolute;
  bottom: 9px;
  left: -25px;
}

.wrapper_app_gray .footer-text:hover .mail-info {
  background-color: var(--bg-main-orange);
}

.copyright {
  font-weight: 500;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.4);
}

@media screen and (max-width: 765px) {
  .footer-aivo {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 76px;
  }

  .footer-aivo .logo {
    margin: unset;
  }

  .footer-blocks {
    grid-template-rows: 33px 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 505px) {
  .footer-aivo {
    grid-template-columns: none;
    grid-gap: 60px;
  }

  .footer-blocks {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, 1fr);
  }

  .footer-blocks .footer-address {
    grid-column-start: 2;
  }

  .footer-position {
    grid-column-start: 2;
    grid-row-start: 2;
  }

  .footer-position-number {
    grid-column-start: 2;
    grid-row-start: 3;
  }

  .footer-aivo .logo {
    margin-bottom: 0px;
  }
}
