@import "../../../../../../global-ui-scss/variables.scss";
@import "../../../../../../global-ui-scss/mixins.scss";

.root {
  padding: 1.5rem;
  font-size: 0.75rem;
  font-weight: 500;
  color: $primary-neutrals-dark60;
  background-color: RgbaColor($primary-dark, 0.05);
  border-radius: 4px;

  ul {
    list-style: none;
    margin: 0.5rem;

    li {
      padding: 0.25rem 0;
      color: $primary-neutrals-dark80;

      + li {
        border-top: 1px solid $primary-neutrals-dark5;
      }
    }
  }
}
