@import "../../../../../global-ui-scss/mixins";
@import "../../../../../global-ui-scss/variables";

.popover {
  max-width: 340px;

  :global(.ant-popover-inner) {
    padding: 0;
  }

  .title {
    margin-bottom: 0.5rem;
  }

  .menu {
    padding: 0.5rem;
  }

  .menuItem {
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    cursor: pointer;
    font-weight: 600;
    color: RgbaColor($primary-dark, 0.8);
    font-size: 0.875rem;
    padding: 0.25rem 0;
    border-bottom: 1px solid $color-neutrals-solid-5;

    &:hover {
      color: RgbaColor($primary-dark, 1);
    }
  }

  .menuItemIcon {
    display: flex;
    color: $color-primary-blue-deep;
  }
}
