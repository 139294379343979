@import "../../global-ui-scss/mixins";
@import "../../global-ui-scss/variables";

.wrapper {
  .slider {
    &:global(.ant-slider-horizontal) {
      padding: 0;

      :global(.ant-slider-rail),
      :global(.ant-slider-step),
      :global(.ant-slider-track) {
        height: 10px;
        border-radius: 16px;
      }

      :global(.ant-slider-rail) {
        background: linear-gradient(
          90deg,
          #6db333 0%,
          #f1c216 32.5%,
          #f17f16 64.36%,
          #ff5454 93.86%
        );
      }

      :global(.ant-slider-track) {
        background-color: transparent;
      }

      :global(.ant-slider-handle) {
        z-index: 1;

        &::before {
          width: 24px;
          height: 24px;
          inset-inline-start: -8px;
          inset-block-start: -8px;
        }

        &::after {
          width: 24px;
          height: 24px;
          inset-inline-start: -8px;
          inset-block-start: -8px;
          border: 4px solid $color-primary-blue-main;
          box-shadow: -6px 3px 17.6px 0px rgba(0, 62, 71, 0.15);
        }
      }
    }
  }

  .topLegend {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .topLegendItem {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 0.5rem;
    font-size: 0.75rem;
    font-weight: 700;
    color: $secondary-orange;

    &:first-child {
      color: $color-green-700;
    }

    &:last-child {
      color: $color-red-700;
    }
  }

  .bottomLegend {
    display: grid;
    grid-template-columns: repeat(10, 1fr) 2rem;
  }

  .bottomLegendItem {
    position: relative;
    font-size: 0.75rem;
    font-weight: 500;
    color: RgbaColor($primary-dark, 0.8);

    &:nth-child(odd):not(:last-child),
    &:nth-child(10) {
      color: RgbaColor($primary-dark, 0.3);
    }

    &:nth-child(8),
    &:nth-child(4) {
      &::before {
        content: "";
        position: absolute;
        left: 6px;
        bottom: calc(100% + 7px);
        width: 6px;
        height: 16px;
        background-color: #fff;
      }

      &::after {
        content: "";
        position: absolute;
        left: 8px;
        bottom: calc(100% + 7px);
        width: 2px;
        height: 16px;
        background-color: $color-neutrals-solid-4;
      }
    }
  }

  .viewValue {
    display: inline-flex;
    align-items: center;
    grid-gap: 0.25rem;
    font-size: 0.75rem;
    font-weight: 700;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
  }

  .viewValueGreen {
    color: $color-green-700;
    background-color: RgbaColor($color-green-700, 0.1);
  }

  .viewValueOrange {
    color: $secondary-orange;
    background-color: RgbaColor($secondary-orange, 0.1);
  }

  .viewValueRed {
    color: $color-red-700;
    background-color: RgbaColor($color-red-700, 0.1);
  }
}
