@import "../../../../../global-ui-scss/mixins";
@import "../../../../../global-ui-scss/variables";

.popover {
  :global(.ant-popover-inner) {
    display: flex;
    padding: 0.25rem;
  }

  .list {
    display: flex;
    grid-gap: 0.25rem;
  }

  .colorPickerItem {
    position: relative;
    width: 14px;
    height: 14px;
    border-radius: 2px;
    cursor: pointer;
    overflow: hidden;
  }
}
