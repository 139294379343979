@import "../../../../global-ui-scss/mixins.scss";
@import "../../../../global-ui-scss/variables.scss";

.fieldWrapper {
  width: 100%;
  @include DisplayFlex(column, center, flex-start);
  gap: 8px;
}

.root {
  width: 100%;
  @include DisplayFlex(column, center, flex-start);
  gap: 8px;
  position: relative;
  .label {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: $secondary-gray;
  }
  .textEditorWrapper {
    width: 100%;
    position: relative;
    z-index: 1;
    transition: 0.3s filter;
  }
  .editButtonsWrapper {
    position: absolute;
    right: 0;
    top: calc(100% + 8px);
    @include EditButtonsAppear(calc(100% + 8px));

    + .textEditorWrapper {
      filter: drop-shadow(0px 6px 17px rgba(0, 0, 0, 0.1));
    }
  }
}

.attachedEntities {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

:global(
    :is(.editor-conclusion, .editor-documentation, .editor-findingDescription) .rdw-editor-main
  ) {
  min-height: 88px;
}
