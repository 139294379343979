@import "../../../../../../../../global-ui-scss/mixins";
@import "../../../../../../../../global-ui-scss/variables";

.wrapper {
  display: grid;
  padding: 1rem 0 1rem 1rem;
  grid-template-columns: 1fr 140px 100px;
}

.item {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  color: RgbaColor($primary-dark, 0.8);

  &:first-of-type {
    font-weight: 700;
    color: $primary-dark;
  }

  svg {
    margin-top: 4px;
    fill: $secondary-orange;
  }
}
