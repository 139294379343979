@import "../../../../../../global-ui-scss/mixins.scss";
@import "../../../../../../global-ui-scss/variables.scss";

.commentWrapper {
  @include DisplayFlex(row, flex-start, center);
  padding: 24px;
  gap: 24px;
  width: 100%;

  p {
    margin: 0;
  }

  .dateTitle {
    font-size: 12px;
    font-weight: 700;
    color: $secondary-gray;
    white-space: nowrap;
  }

  .title {
    font-size: 14px;
    font-weight: 600;
    color: $primary-dark;
  }

  .dot {
    @include DisplayFlex(row, center, center);
    width: 32px;
    height: 32px;

    &::after {
      display: inline-block;
      content: "";
      background: $primary-dark;
      border-radius: 50%;
      width: 12px;
      height: 12px;
    }
  }

  .iconNamePerson {
    @include DisplayFlex(row, center, center);
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: $white;
    background: $color-primary-blue-deep;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    //TODO: recheck this with david

    &::before {
      content: "";
      position: relative;
      display: block;
      bottom: calc(100% + 12px);
      border-left: 1px dashed rgba(0, 62, 71, 0.2);
      height: 48px;
      z-index: 0;
    }
  }

  .textWrapper {
    @include DisplayFlex(column, center, flex-start);

    .titlesWrapper {
      gap: 16px;
      @include DisplayFlex(row, center, center);
    }

    .comment {
      font-weight: 500;
      font-size: 14px;
      color: $primary-neutrals-dark80;
    }
  }
}
