@import "../../../../../global-ui-scss/mixins";
@import "../../../../../global-ui-scss/variables";

.signerInfo {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  cursor: pointer;
  user-select: none;
  min-height: 6rem;
  min-width: 220px;
  padding: 0.5rem 1rem 0.5rem 1.5rem;
  border-radius: 2px;
  border: 1px solid RgbaColor($primary-dark, 0.2);
  background-color: #ffffff;
  z-index: 10;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    bottom: -1px;
    width: 10px;
    background-color: RgbaColor($color-primary-blue-main, 0.4);
    border-radius: 4px 0 0 4px;
    z-index: 1;
  }
}

.signerInfoActive {
  box-shadow: -1px 1px 4px RgbaColor($primary-dark, 0.07);

  &::before {
    background-color: $color-primary-blue-main;
  }
}

.close {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
}

.companyName {
  font-weight: 700;
  font-size: 0.75rem;
  color: RgbaColor($primary-dark, 0.8);
  margin-bottom: 1rem;
}

.companySignatory {
  text-transform: capitalize;
}

.secondaryText {
  font-size: 0.75rem;
  color: RgbaColor($primary-dark, 0.6);
}

.small {
  padding: 0.375rem 0.375rem 0.375rem 1rem;
  width: 100%;
  min-width: 0;
  min-height: 0;
  aspect-ratio: 205/82;

  > div {
    pointer-events: none;
  }

  .close {
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.125rem;
    right: 0.5rem;
  }

  .companyName {
    margin-bottom: 0.25rem;
  }
}

.userName {
  margin-bottom: auto;
  padding-right: 2rem;
}
