@import "../../../../../global-ui-scss/mixins";
@import "../../../../../global-ui-scss/variables";

.noAnimation :global(.ant-drawer-content-wrapper) {
  transition: 0s all !important;
}

:global(.edit-step-drawer .ant-drawer-content-wrapper) {
  width: calc(100% - 400px - 235px) !important;

  :global(.ant-drawer-body) {
    padding: 0;
  }

  :global(.ant-drawer-header) {
    position: absolute;
    right: 0;
    padding: 1.125rem;
    border: 0;
    z-index: 1000;

    :global(.ant-drawer-close) {
      font-size: 0.75rem;
      margin-right: 0;
      color: $primary-neutrals-dark60;
    }
  }

  .drawerContent {
    display: flex;
    flex-direction: column;

    .type {
      font-size: 12px;
      font-weight: 700;
      color: $primary-neutrals-dark40;
      text-transform: capitalize;
    }

    .deleteWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px;
      margin-top: 16px;

      > button {
        width: 200px;
      }
    }

    &.spacing {
      gap: 24px;
    }

    .drawerHeader {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;
    }

    .drawerBody {
      padding: 2rem 1.5rem;
    }

    .drawerBodyGrid {
      display: grid;
      grid-gap: 1.5rem;
      padding: 2rem 1.5rem;
    }

    .subtaskHeader {
      padding: 0.75rem 1.5rem;
      display: flex;
      align-items: center;
      gap: 16px;
      border-bottom: 2px solid #ebf0f0;

      svg {
        cursor: pointer;
      }

      span {
        font-size: 12px;
        font-weight: 700;
        line-height: 14.09px;
        color: #003e4799;
      }
    }

    .assertionRow {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
      margin-top: 8px;

      :global(.main-block) {
        position: absolute;
        background: white;
        box-shadow: 0 6px 17px 0 #0000001a;
        border-radius: 8px;
        top: calc(100% + 4px);
        left: 0;
        width: 350px;
        z-index: 100;

        :global(.assign-scroll-wrapper) {
          padding: 0 8px 8px;
        }

        :global(.table-search .title) {
          margin-left: 4px;
        }

        :global(.row-search) {
          padding: 4px;
          margin-block: 4px;
          transition: 0.3s background-color;
          border-radius: 4px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &:hover {
            background: #f2f5f6;
          }

          > span {
            color: #003e4766;
            font-size: 12px;
            font-weight: 700;
            line-height: 14.09px;
          }
        }
      }
    }

    .drawerHead {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 3.75rem;
      padding: 1rem 1.5rem;
      border-bottom: 2px solid rgba(235, 240, 240, 1);

      .actions {
        display: flex;
        gap: 12px;
        height: max-content;

        > * {
          width: 133px;
        }
      }
    }

    .mainInfo {
      display: flex;
      flex-direction: column;
      padding: 0.5rem 1.5rem;
    }

    .drawerTitle {
      color: var(--Primary-Dark, #003e47);
      font-size: 20px;
      font-weight: 700;
      line-height: 23px;
      letter-spacing: 0;
      text-align: left;
    }

    .field {
      display: flex;
      flex-direction: column;
      gap: 6px;

      > label {
        color: var(--neutrals-dark-40, rgba(0, 62, 71, 0.4));
        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
        letter-spacing: 0;
        text-align: left;
      }

      > span > *,
      > span {
        color: var(--neutrals-dark-80, rgba(0, 62, 71, 0.8));
        font-family: Raleway, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 131.4%; /* 18.396px */
        margin: 0;
      }

      > button {
        width: 140px;
      }
    }

    &:not(.spacing) .field + .field {
      margin-top: 24px;
    }
  }
}

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;

  .head {
    position: sticky;
    top: 0;
    padding: 24px;
    color: $primary-dark;
    font-size: 20px;
    font-weight: 700;
    width: 100%;
    z-index: 10;
    background-color: white;
    border-bottom: 1px solid var(--neutrals-solid-dark-8, #ebf0f0);

    .numeration {
      margin-right: 16px;
    }
  }

  &.editMode {
    .steps .addNew {
      opacity: 1;
      height: 16px;
      margin-top: 0;
      pointer-events: all;
      cursor: pointer;
    }
  }

  .stepColumn {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &.dragActive {
    .steps .addNew {
      opacity: 0;
    }
  }

  .steps {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;

    .addNew {
      margin-inline: auto;
      opacity: 0;
      height: 0;
      margin-top: -16px;
      pointer-events: none;
      transition: 0.3s;
    }

    .step {
      width: 100%;
      display: flex;
      gap: 20px;
      position: relative;

      .divider {
        width: 0;
        border-right: 1px dashed rgba(0, 62, 71, 0.2);
        position: absolute;
        top: 41px;
        bottom: -64px;
        height: 100%;
        left: 5.3px;
        background-color: #ffffff;
        z-index: 0;
        transition: 0.3s border;
      }

      .leftPane > svg {
        position: relative;
        top: 24px;
        background-color: #ffffff;
      }

      &.in_progress .stepCard {
        outline: 1px solid $color-primary-blue-main;
        background: var(--primary-white, #fff);
        box-shadow: 0 0 0 4px rgba(73, 186, 202, 0.3);
      }

      &.pending .stepCard {
        outline: 1px solid $color-yellow-dark;
        background: var(--primary-white, #fff);
      }

      &.done .stepCard {
        outline: 1px solid var(--secondary-green-700, #6db333);
        background: var(--primary-white, #fff);
      }

      &.blocked .stepCard {
        outline: 1px solid $secondary-orange;
        background: var(--primary-white, #fff);
      }

      &.not_required .stepCard {
        outline: 1px solid $color-purple;
        background: var(--primary-white, #fff);
      }

      .stepHead {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }

      .stepFooter {
        display: flex;
        flex-direction: column;

        a {
          -webkit-user-drag: none;
        }

        > * {
          margin-top: 24px;
          text-decoration: none;
        }

        > * + * {
          margin-top: 8px;
        }
      }

      .stepCard {
        padding: 20px 24px;
        border-radius: 4px;
        background: var(--neutrals-solid-dark-5, #f2f5f6);
        transition: 0.3s;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        width: 100%;

        .actions {
          display: flex;
          align-items: center;
          gap: 16px;
        }

        .info {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .actions {
            display: flex;
            align-items: start;
            flex-direction: column;
            grid-gap: 12px;
            margin-top: -8px;

            :first-child {
              margin-top: 24px;
            }

            button {
              width: 200px;
            }

            .helpText {
              color: var(--neutrals-solid-dark-40, #99b2b5);
              font-size: 12px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }
          }

          .ownership {
            color: var(--neutrals-dark-60, rgba(0, 62, 71, 0.6));
            font-size: 10px;
            font-weight: 700;
            line-height: 110%; /* 11px */
            letter-spacing: 1.25px;
            text-transform: uppercase;
            margin-bottom: -8px;
          }

          .title {
            color: var(--primary-dark, #003e47);
            font-size: 16px;
            font-weight: 500;
            line-height: 135%; /* 21.6px */
          }

          .description {
            color: var(--neutrals-dark-60, rgba(0, 62, 71, 0.6));
            font-size: 14px;
            font-weight: 500;
            margin: 0 !important;
            background-color: transparent !important;

            * {
              color: inherit !important;
              font-size: inherit;
              font-weight: inherit;
              margin: inherit !important;
              background-color: inherit !important;
            }
          }

          .description {
            strong,
            b,
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
              font-weight: 700;

              * {
                font-weight: inherit;
              }
            }
          }
        }
      }

      .dragHandle {
        margin-left: -20px;
        width: 0;
        opacity: 0;
        margin-block: auto;
        transition: 0.3s width, 0.3s margin-left, 0.3s opacity;
        pointer-events: none;

        &.active {
          margin-left: 0;
          opacity: 1;
          width: 24px;
          pointer-events: all;
        }
      }
    }
  }
}

.subtasks {
  display: flex;
  flex-direction: column;
  gap: 8px;

  & > div {
    padding: 12px 12px 12px 16px;
    gap: 32px;
    border-radius: 4px;
    border: 1px solid #ebf0f0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .status {
      border: 4px solid #dae3e5;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      transition: 0.3s border, 0.3s outline;
    }

    &.done {
      outline: 1px solid var(--secondary-green-700, #6db333);

      .status {
        border: 4px solid #6db333;
      }

      .subtaskInfo .type {
        color: #6db333;
      }
    }

    &.in_progress {
      box-shadow: 0 0 0 4px #49baca4d;
      outline: 1px solid #49baca;

      .status {
        border: 4px solid #49baca;
      }

      .subtaskInfo .type {
        color: #49baca;
      }
    }

    &.pending {
      outline: 1px solid $color-yellow-dark;

      .status {
        border: 4px solid $color-yellow-dark;
      }

      .subtaskInfo .type {
        color: $color-yellow-dark;
      }
    }

    &.blocked {
      outline: 1px solid $secondary-orange;

      .status {
        border: 4px solid $secondary-orange;
      }

      .subtaskInfo .type {
        color: $secondary-orange;
      }
    }

    .subtaskInfo {
      display: grid;
      align-items: center;
      grid-template-columns: 16px 100px 1fr;
      grid-gap: 8px;
      width: 60%;

      .type {
        margin-bottom: 0 !important;
        transition: 0.3s color;
      }

      .name {
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        color: #003e47;
      }
    }

    .subtaskActions {
      width: 40%;
      display: flex;
      gap: 16px;
      align-items: center;
      justify-content: flex-end;

      .assertions {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        gap: 4px;
      }
    }
  }

  .createNew {
    margin-top: 4px;
    justify-content: center;
  }
}
