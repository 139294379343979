.timePickerPopup {
  width: 274px;

  &.singleTimePicker {
    width: max-content;
  }

  .ant-btn {
    background: #003e47;
    border-color: #003e47;
    border-radius: 4px;
  }

  .ant-btn > span {
    font-size: 12px;
  }

  .ant-btn-primary:hover {
    background: #003e47;
    border-color: #003e47;
    border-radius: 4px;
  }

  .ant-picker-focused {
    border-color: none !important;
    border-right-width: 0px;
    box-shadow: none;
    outline: 0;
  }

  .ant-picker-time-panel-column:after {
    display: none;
  }

  .ant-picker-time-panel-column > li {
    display: flex;
    color: white;
    border-radius: 4px !important;
    align-items: center;
    margin: 12px 8px;
    justify-content: center;
    padding: 0;
    width: auto;
    font-size: 12px;
  }

  .ant-picker-time-panel-column
    > li.ant-picker-time-panel-cell-selected
    .ant-picker-time-panel-cell-inner {
    background: #003e47;
    color: white;
    border-radius: 4px;
  }
}
