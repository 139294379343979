.chat {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  position: fixed;
  width: 360px;
  height: 100%;
  top: 0;
  padding-top: 30px;
  z-index: 100;
  right: 0;
  border-left: 1px solid #003e4712;
  padding-bottom: 24px;
}

.chat .chat_header {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  color: #003e47;
  font-weight: 700;
  font-size: 16px;
  grid-gap: 120px;
  align-items: center;
}

.chat .chat_header .closeIcon {
  width: 12px;
  height: 12px;
  cursor: pointer;
}

.chat_btn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chat .chat_btn_inner {
  width: 308px;
  height: 44px;
  background-color: #49baca;
  color: #fff;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 700;
  border: none;
  cursor: pointer;
}

.chat .chat_btn_inner:hover {
  background-color: #0f8594;
  transition: 0.5s;
}

.chat .chat-search {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 17px;
  align-items: center;
  padding: 8px;
  background: rgba(0, 62, 71, 0.07);
  border-radius: 8px;
  margin: 24px 24px 0;
}

.chat .chat-search .chat-search_input {
  border: none;
  padding: 4px;
  background: rgba(0, 62, 71, 0);
}

.chat .chat_all {
  padding-left: 24px;
  padding-right: -4px;
  margin-top: 36px;
}

.chat .chat_all .chat_all-inner {
  display: flex;
  grid-gap: 12px;
  border-bottom: 1px solid #f2f5f6;
  margin-top: 10px;
  margin-left: -23px;
  padding-left: 23px;
  cursor: pointer;
}

.chat_all-inner_data_info {
  display: flex;
  grid-gap: 82px;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  color: #003e47;
}

.chat_all-inner_data_info .chat_all-inner_data_info_time {
  color: #003e4799;
  font-size: 12px;
  font-weight: 600;
}

.chat_all-inner_data .chat_all-inner_data_info_subtitle {
  font-size: 10px;
  color: #003e4766;
  font-weight: 600;
  margin-top: -5px;
}

.chat_all .chat_all-inner .chat_all-inner_data .chat_all-inner_data_info-message {
  display: grid;
  grid-template-columns: 4fr 1fr;
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: 500;
  color: #003e47cc;
}

.chat_all
  .chat_all-inner
  .chat_all-inner_data
  .chat_all-inner_data_info-message
  .chat_all-inner_data_info_message-all {
  color: #fff;
  width: 22px;
  height: 16px;
  font-size: 11px;
  font-weight: 900;
  background-color: #f17f16;
  border-radius: 51px;
}

.chat_all
  .chat_all-inner
  .chat_all-inner_data
  .chat_all-inner_data_info-message
  .chat_all-inner_data_info_message-all
  .chat_all-inner_data_info-message_last {
  padding-left: 8px;
  padding-top: 10px;
  width: 13px;
  height: 12px;
}

.chatSvg {
  position: fixed;
  bottom: 30px;
  right: 20px;
  z-index: 101;
  padding: 14px 11px 16px 20px;
  background: #ffffff;
  box-shadow: 0 6px 17px -7px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  cursor: pointer;
}
