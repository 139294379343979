.root {
  .body:global(.ant-modal-body) {
    padding: 0;
  }

  .tabs {
    padding-top: 1rem;

    :global(.ant-tabs-nav) {
      margin: 0;
    }

    :global(.ant-tabs-nav-list) {
      margin-inline: 1.5rem;
    }

    :global(.ant-tabs-tab) {
      padding-top: 0;
      padding-inline: 2rem;

      + :global(.ant-tabs-tab) {
        margin-left: 0;
      }

      :global(.ant-tabs-tab-btn) {
        font-size: 0.875rem;
        font-weight: 500;
      }
    }
  }
}
