@import "../../../../../../global-ui-scss/variables";

.root {
  position: relative;
  display: grid;
  align-items: center;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) 200px 80px 32px;
  grid-gap: 2rem;
  padding: 1rem;
  border-radius: 8px;
  text-decoration: none;
  transition: background-color 300ms;

  &:hover {
    background-color: white;
  }
}

.email,
.date {
  font-size: 12px;
  font-weight: 500;
  line-height: 16.32px;
  color: #003e47;
}

.divider {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border: none;
  background-color: #dae3e5;
  height: 1px;
  z-index: 0;
  margin: 0;
}

.row {
  display: flex;
  gap: 12px;
  align-items: center;
}

.name {
  font-size: 12px;
  font-weight: 700;
  line-height: 13.2px;
  color: $primary-dark;
}

.code {
  font-weight: 600;
  font-size: 1.125rem;
  color: $primary-dark;
}
