@import "../../../../../../global-ui-scss/mixins.scss";

.root {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.row {
  @include DisplayFlex(row, flex-start, center);
  width: 100%;
  gap: 12px;
}

.column {
  width: 100%;
  @include DisplayFlex(column, flex-start, flex-start);
  gap: 16px;
}

.group {
  width: 100%;
  @include DisplayFlex(column, flex-start, flex-start);
  gap: 8px;
}
