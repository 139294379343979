@import "../../global-ui-scss/variables.scss";

.ant-checkbox-wrapper {
  align-items: center;
  font-size: 0.75rem;
  font-weight: 700;
  color: $primary-neutrals-dark80;

  &:not(.ant-checkbox-wrapper-disabled) {
    &:hover {
      .ant-checkbox {
        &.ant-checkbox-checked {
          .ant-checkbox-inner {
            background-color: $color-primary-blue-deep;
          }
        }

        .ant-checkbox-inner {
          border-color: transparent;
          background-color: rgba(0, 62, 71, 0.07);
        }
      }
    }
  }

  .ant-checkbox.ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
    border-color: var(--primary-blue);
    background-color: var(--primary-blue);
  }

  .ant-checkbox-disabled {
    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: $primary-neutrals-dark20;
        border-color: transparent;

        &::after {
          border-color: #ffffff;
        }
      }
    }
  }
}

.ant-checkbox {
  .ant-checkbox-inner {
    &::after {
      width: 6px;
      height: 11px;
      inset-inline-start: 30%;
    }
  }
}

.ant-checkbox-disabled {
  .ant-checkbox-inner {
    background-color: transparent;
    border-color: rgba(0, 62, 71, 0.2);
  }
}
