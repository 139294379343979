@import "../../global-ui-scss/variables.scss";

.root {
  min-height: 100vh;
  background: linear-gradient(0deg, rgba(0, 62, 71, 0.05), rgba(0, 62, 71, 0.05)), #ffffff;

  .loadingContainer {
    display: flex;
    justify-content: center;
    margin-top: 100px;
  }

  .tabContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2.625rem;

    > h1 {
      font-weight: 800;
      font-size: 24px;
      line-height: 110%;
      color: #003e47;
      justify-content: center;
      width: 720px;
      margin-bottom: 24px;
      align-self: center;
    }

    .tabBody {
      display: flex;
      border: 1px solid rgba(0, 62, 71, 0.07);
      border-radius: 8px;
      width: 720px;
      height: 40px;
      align-items: center;
      justify-content: space-between;
      padding-inline: 4px;

      .tabButton {
        width: 234px;
        height: 32px;
        background: transparent;
        border: none;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: #003e47;

        &:hover {
          cursor: pointer;
        }
      }

      .tabButtonActive {
        border: none;
        background: #003e47;
        border-radius: 6px;
        color: $white;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }

  .list {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-inline: 24px;

    > a + a {
      margin-top: 8px;
    }

    .item {
      max-width: 720px;
      width: 100%;
      padding: 24px 44px 20px 24px;
      display: flex;
      grid-template-columns: 34px 1fr;
      grid-gap: 24px;
      background-color: white;
      position: relative;
      border-radius: 12px;
      text-decoration: none;

      .new {
        position: absolute;
        top: 10px;
        left: 10px;
        width: 7px;
        height: 7px;
        background: #f17f16;
        border: 1px solid #ffffff;
        border-radius: 50%;
      }

      .container {
        display: flex;
        width: 100%;

        .logos {
          position: relative;
          height: max-content;

          .creator {
            background-color: #0f8594;
            width: 34px;
            height: 34px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            color: #ffffff;
          }

          .company {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            border: 1px solid #ffffff;
            object-fit: cover;
            position: absolute;
            right: 0;
            bottom: 0;
            transform: translate(40%, 40%);
          }
        }
      }

      .info {
        display: flex;
        flex-direction: column;
        margin-left: 24px;
        width: 100%;

        .textArea {
          margin-top: 15px;
          border: 1px solid rgba(0, 62, 71, 0.1);
          border-radius: 8px;
          padding: 12px;
        }

        .text {
          font-weight: 500;
          font-size: 14px;
          line-height: 136%;
          color: #003e47;
        }

        .name {
          font-weight: 700;
          font-size: 18px;
          line-height: 110%;
          color: #003e47;
        }

        .message {
          width: 100%;
          margin-top: 4px;
          font-weight: 500;
          font-size: 16px;
          line-height: 135%;
          color: #003e47;

          a {
            color: #003e47;
            font-weight: 700;
            text-transform: capitalize;
          }

          .requestBtn {
            margin-top: 15px;
          }

          .uploadContainer {
            display: inline-flex;
            width: 100%;
            margin-top: 15px;
          }
        }

        .date {
          font-weight: 500;
          font-size: 14px;
          line-height: 136%;
          color: rgba(0, 62, 71, 0.4);
          margin-top: 8px;
        }
      }
    }
  }
}
