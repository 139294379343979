@import "../../../../../global-ui-scss/variables.scss";

.conditionItem {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
}

.conditionError {
  color: $color-red-800;
}

.conditionSuccess {
  color: $secondary-green-800;
}

.rightError {
  display: flex;
  justify-content: flex-end;
  color: $color-red-800;
}
