@import "../../../../../../../../global-ui-scss/mixins";
@import "../../../../../../../../global-ui-scss/variables";

.table {
  :global(.ant-table-thead) {
    > tr {
      > th {
        color: $primary-dark;
        padding: 0.5rem 1rem;
        font-size: 0.75rem;
        font-weight: 700;
        background-color: RgbaColor($primary-dark, 0.07);
      }
    }
  }

  :global(.ant-table-tbody) {
    > tr {
      > td {
        font-weight: 500;
        color: RgbaColor($primary-dark, 0.6);
        padding: 0.5rem 1rem;
        font-size: 0.875rem;
      }
    }
  }
}

.date {
  font-size: 0.75rem;
  font-weight: 600;
  color: RgbaColor($primary-dark, 0.6);
}

.ref {
  font-weight: 500;
  color: $color-primary-blue-main;
}

.description {
  font-weight: 600;
  color: $primary-dark;
}

.amountPositive,
.amountNegative {
  display: inline-flex;
  align-items: center;
  grid-gap: 0.25rem;
  font-weight: 600;
}

.amountPositive {
  color: $color-green-700;
}

.amountNegative {
  color: $color-red-700;
}
