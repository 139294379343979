@import "../../../global-ui-scss/mixins";
@import "../../../global-ui-scss/variables";

.wrapper {
  z-index: 101;
}

.title {
  color: $primary-dark;
  font-weight: 800;
}

.header {
  position: sticky;
  z-index: 10;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  background: #ffffff;
  border-bottom: 1px solid #ebf0f0;
}

.fileNameWrapper {
  display: flex;
  align-items: center;
  grid-gap: 12px;
  margin-bottom: 1rem;
}

.fileName {
  font-size: 1rem;
}

.buttonBack {
  color: $primary-dark;
}
