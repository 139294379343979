.thank-you-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100vh;
}

.thank-you-wrapper .title {
  font-weight: 800;
  font-size: 32px;
  line-height: 110%;
  color: #003e47;
  margin: 32px 0 18px;
}

.thank-you-wrapper .description {
  font-weight: 500;
  font-size: 16px;
  line-height: 148%;
  color: rgba(0, 62, 71, 0.8);
  max-width: 363px;
}
