.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;
  min-height: 34px;
  order: -1;

  .button {
    padding: 0;
    width: 34px;
    height: 34px;
  }

  .title {
    font-size: 24px;
    font-weight: 800;
    margin: 0;
  }
}
