.settings_team_name {
  margin-top: 35px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 55px;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
}
.settings_audit-team_information .error-input {
  border: 1px solid #f30000;
}
.settings_audit-team_information .error-dropdown {
  border: 1px solid #f30000;
  width: max-content;
  border-radius: 4px;
}
.settings_team {
  display: flex;
  grid-gap: 140px;
}
.setting_right_info_danger {
  margin-top: 30px;
}
.settings_audit-team_information {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 20px;
  grid-gap: 30px;
}
.setting_detail_informations {
  height: 456px;
  margin-top: 28px;
}
.settingsAudit_role {
  overflow: hidden;
  display: flex;
  grid-gap: 20px;
  margin-top: -27px;
  padding-top: 25px;
  margin-left: 0px;
}

.settingsAudit_role_information {
  display: grid;
  margin-top: 50px;
  margin-left: 26%;
  margin-bottom: 15%;
}
.settingsAudit_role_informationTeam {
  display: grid;
  margin-top: 0px;
  margin-left: 28%;
  margin-bottom: 30%;
}

.settings_audit-team_information input {
  width: 420px;
  height: 46px;
  border-radius: 4px;
}
.settings_audit-team_information-danger {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.settings_danger-btn {
  border: 1px solid #ff5454;
  color: #ff5454;
  font-size: 12px;
  font-weight: 700;
  border-radius: 4px;
  font-family: Raleway;
  line-height: 17px;
  background-color: transparent;
  width: 420px;
  height: 46px;
  transition: 0.6s
}
.settings_danger-btn:hover {
  background-color: #ff5454;
  color: #fff;
  transition: 0.6s;
  cursor: pointer;
}
.settings_audit-team_information input {
  color: #003e47cc;
  font-weight: 500;
  font-size: 16px;
  padding-left: 12px;
}
.teamMember_PhoneInput {
  border: 1px solid #003e471a;
  border-radius: 4px;
  width: 420px;
  margin-left: 1px;
  display: grid;
  grid-template-columns: minmax(40px, 0fr) minmax(351px, 0fr);
  background-color: #003e4712;
  padding-left: 12px;
  margin-top: 0px;
}
.settings_audit-team_inner {
  border: 1px solid #003e471a;
}
.teamMember_PhoneInput input {
  width: auto;
  border-radius: 0px 4px 4px 0px;
  margin-left: -10px;
}
