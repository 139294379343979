@import "../../../../../../global-ui-scss/mixins.scss";
@import "../../../../../../global-ui-scss/variables.scss";

.root {
  display: grid;
  align-items: center;
  grid-template-columns: 250px minmax(200px, 1fr) minmax(200px, 1fr);
  padding: 16px;
  grid-gap: 2rem;
  border-radius: 8px;
  flex-shrink: 0;
  transition: 0.3s;
  text-decoration: none;
  position: relative;
}

.root > hr {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border: none;
  background-color: #dae3e5;
  height: 1px;
  z-index: 0;
  margin: 0;
}

.root > * {
  z-index: 1;
}

.root::after {
  content: "" "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: calc(100% + 1px);
  width: 100%;
  border: none;
  background: transparent;
  border-radius: 8px;
  transition: 0.3s;
}

.key {
  font-size: 0.75rem;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.root:hover::after {
  background: white;
}

.highlighted {
  border-color: RgbaColor($color-yellow-dark, 0.7);
  background-color: RgbaColor($color-yellow-dark, 0.15);
}

.edited {
  border-color: $secondary-green-800;
  background-color: RgbaColor($secondary-green-800, 0.4);
}
