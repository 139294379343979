@import "../../global-ui-scss/mixins.scss";
@import "../../global-ui-scss/variables.scss";

.ant-input-affix-wrapper {
  .ant-input-prefix {
    margin-right: 0.5rem;
  }

  > input.ant-input {
    font-size: 0.875rem;

    &::placeholder {
      font-size: 0.875rem;
      fill: RgbaColor($primary-dark, 0.2);
    }
  }
}

.ant-input-affix-wrapper-sm {
  padding: 6px 10px;
}

.ant-input-outlined {
  color: $primary-dark;
  border-color: RgbaColor($primary-dark, 0.1);
  outline: none;
  box-shadow: none;
  padding: 0.5625rem 1rem;

  &.ant-input-affix-wrapper-sm {
    padding: 0.3125rem 0.75rem;
  }

  input {
    &::placeholder {
      font-size: 0.875rem;
      fill: RgbaColor($primary-dark, 0.2);
    }
  }

  &:hover {
    border-color: RgbaColor($primary-dark, 0.2);
    background-color: RgbaColor($primary-dark, 0.02);
    outline: none;
    box-shadow: none;

    input {
      &::placeholder {
        fill: RgbaColor($primary-dark, 0.4);
      }
    }
  }

  &:focus,
  &:focus-within {
    border-color: $color-primary-blue-main;
    background-color: transparent;
    outline: none;
    box-shadow: none;

    input {
      &::placeholder {
        fill: RgbaColor($primary-dark, 0.4);
      }
    }
  }

  &:read-only {
    border-color: RgbaColor($primary-dark, 0.02);
    background-color: RgbaColor($primary-dark, 0.02);
  }
}
