@import "../../../../../global-ui-scss/mixins";
@import "../../../../../global-ui-scss/variables";

.root {
  display: flex;
  gap: 4px;
  border-radius: 4px;
  width: max-content;
  height: max-content;
  color: RgbaColor($primary-dark, 0.8);
  border: 0;
  background: RgbaColor($primary-dark, 0.1) !important;

  &:global(.ant-btn) {
    padding: 2px 6px;
  }
}

.root {
  font-size: 12px;
  font-weight: 700;
}

.tooltipTitle {
  font-weight: 700;
  color: #b2c5c8;
  font-size: 0.75rem;
  margin-bottom: 0.625rem;
}
