@import "../../../../../global-ui-scss/mixins";
@import "../../../../../global-ui-scss/variables";

.wrapper {
  padding: 1.5rem 1rem;
  border-top: 1px solid $color-neutrals-solid-4;
  border-bottom: 1px solid $color-neutrals-solid-4;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 0;
}

.emptyText {
  font-weight: 500;
  color: RgbaColor($primary-dark, 0.4);
}

.list {
  margin-top: 1rem;
}

.signer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  padding: 0.75rem 0;

  + .signer {
    border-top: 1px solid $color-neutrals-solid-4;
  }
}

.userName {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 0;
}

.userInfo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 600;
  font-size: 0.875rem;
  color: RgbaColor($primary-dark, 0.8);
  min-width: 0;
}

.status {
  padding: 6px 10px;
  border-radius: 25px;
  font-size: 12px;
  font-weight: 700;
  margin-top: 0;
  letter-spacing: 0.02em;
  text-transform: capitalize;
}

.unsigned {
  color: #f17f16;
  background: rgba(241, 127, 22, 0.1);
  margin-left: 10px;
}

.failed {
  color: #ff5454;
  background: rgba(255, 84, 84, 0.1);
  margin-left: 10px;
}

.in_progress {
  color: #49baca;
  background: rgba(73, 186, 202, 0.2);
  margin-left: 10px;
}

.signed {
  color: #6db333;
  background: rgba(109, 179, 51, 0.2);
  margin-left: 10px;
}

.newSignersWrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 10;
}

.newSignersHero {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-shrink: 1;
  padding: 1.5rem 1rem;
  border-bottom: 1px solid $color-neutrals-solid-4;
}

.description {
  color: $primary-dark;
  font-size: 0.875rem;
}

.newSignersBody {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 1rem;
  padding: 1rem;
  overflow: auto;
}
