@import "../../../../../../global-ui-scss/mixins.scss";

.container {
  @include DisplayFlex(row, center, center);
  gap: 8px;
  .buttonWrapper {
    width: 112px;
    height: 30px;
  }
}
