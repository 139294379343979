@import "../../global-ui-scss/mixins.scss";
@import "../../global-ui-scss/variables.scss";

.ant-btn {
  padding: 0.5rem 1rem;
  height: auto;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1.5;

  &.ant-btn-icon-only {
    width: 34px;
    height: 34px;
    color: RgbaColor($primary-dark, 0.8);

    &.ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
      color: RgbaColor($primary-dark, 0.8);
      background-color: RgbaColor($primary-dark, 0.15);
    }
  }

  &.ant-btn-text {
    &:disabled,
    &.ant-btn-disabled {
      color: RgbaColor($primary-dark, 0.2);
    }

    &.ant-btn-dangerous:not(:disabled):not(.ant-btn-disabled) {
      color: $color-red-700;

      &:hover {
        color: $color-red-700;
        background-color: RgbaColor($color-red-700, 0.2);
      }
    }
  }

  .ant-btn-icon {
    display: inline-flex;
  }
}
