@import "../../../../global-ui-scss/mixins.scss";
@import "../../../../global-ui-scss/variables.scss";

.container {
  @include DisplayFlex(column, center, flex-start);
  gap: 8px;
  width: auto;
  align-items: flex-start;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .columnWrapper {
    @include DisplayFlex(row, flex-start, center);
    gap: 16px;
    flex-wrap: wrap;
  }

  .relativeSearch {
    position: relative;

    .searchWrapper {
      position: absolute;
      z-index: 3;
      background: white;
      width: 450px;
      box-shadow: 0px 6px 17px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
    }
  }
}
