@import "../../../global-ui-scss/variables.scss";

.root {
  opacity: 0.8;
  transition: 0.3s opacity;

  :global(.MuiSwitch-root) {
    transition: 0.3s opacity;
    opacity: 0.6;
  }

  :global(.MuiFormControlLabel-label) {
    font-size: 0.75rem;
    font-weight: 700;
    color: $primary-neutrals-dark80;
  }
}

.checked {
  opacity: 1;

  :global(.MuiSwitch-root) {
    opacity: 1;
  }
}
