@import "../../../../../../../../global-ui-scss/mixins";
@import "../../../../../../../../global-ui-scss/variables";

.wrapper {
}

.inner {
  padding-block: 1rem;
}

.divider {
  margin-inline: -24px;
  width: calc(100% + 48px);
  margin-bottom: 0;
}

.addButton {
  width: auto;
  margin-top: 1rem;
  border-radius: 16px;
}

.title {
  font-weight: 700;
  font-size: 1.25rem;
  color: $primary-dark;
}

.helperText {
  margin-top: 0.75rem;
  font-size: 0.75rem;
  font-weight: 500;
  color: $color-neutrals-solid-7;
}

.description {
  font-size: 0.875rem;
  font-weight: 500;
  color: RgbaColor($primary-dark, 0.8);
}

.affectedAccountsItem {
  display: grid;
  grid-template-columns: 1fr 120px 120px;
  grid-gap: 2rem;
  padding: 1rem 0.75rem;

  > .itemInner {
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    font-weight: 600;
    color: RgbaColor($primary-dark, 0.8);

    &:first-child {
      font-weight: 700;
      color: $primary-dark;
    }

    .flagIcon {
      fill: $secondary-orange;
    }
  }
}
