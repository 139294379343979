.section {
  display: flex;
  flex-direction: column;

  .title {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 62, 71, 0.4);
    margin-block: 16px;
  }

  + .section {
    margin-top: 16px;
  }
}
