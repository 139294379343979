@import "../../../../../../../../global-ui-scss/mixins";
@import "../../../../../../../../global-ui-scss/variables";

.root {
  display: grid;
  grid-template-columns: 1fr 100px;
  grid-gap: 2rem;
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 4px;
  font-size: 0.875rem;
  padding: 1rem;

  + .root {
    border-top: 1px solid $color-neutrals-solid-4;
  }
}

.content {
  display: grid;
  grid-template-columns: 1fr auto;
}

.contentInner {
  display: grid;
  grid-gap: 0.25rem;
}

.type {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: capitalize;

  &.quantitative {
    color: $color-green-700;
  }

  &.improvement {
    color: $color-neutrals-solid-7;
  }

  &.qualitative {
    color: $secondary-orange;
  }
}

.title {
  font-size: 0.875rem;
  color: $primary-dark;
  font-weight: 600;
}
