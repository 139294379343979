.react-tel-input {
  width: 340px;
  height: 65px;
  border: none;
}

.react-tel-input .form-control {
  display: flex;
  position: absolute !important;
  cursor: pointer;
  bottom: 220px;
  align-items: center;
  font-family: Raleway sans-serif;
  font-weight: semi-bold;
  color: #003e47cc;
  border: none !important;
  z-index: 1;
  font-size: 18px !important;
  width: 222px !important;
  padding-left: 12px !important;
  padding-top: 3px;
  margin-top: 26px !important;
}
.login_password-text-block {
  display: flex;
  flex-direction: column;
}
.login_password-text {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #003e4766;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.3px;
  margin-bottom: 8px;
}

.login_password-line {
  display: flex;
  justify-content: space-between;
}

.phone_input::placeholder {
  color: #003e471a;
  font-weight: semi-bold;
  font-size: 18px;
  padding-left: 5px;
  font-family: Raleway sans-serif;
  line-height: 20px;
  display: flex;
  align-items: center;
}

.react-tel-input .flag-dropdown {
  position: absolute !important ;
  top: 0;
  bottom: 0;
  padding: 0;
  border: none !important;
  background-color: transparent !important;
  border-radius: 3px 0 0 3px;
}

.react-tel-input .country-list .country:hover {
  background-color: #f2f5f6 !important;
  width: 306px;
  border-radius: 4px;
}
.react-tel-input .country-list {
  color: #003e47;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  font-family: Raleway;
  margin: -10px 0 10px 1px !important;
  width: 340px !important;
  padding-left: 10px !important;
}
.react-tel-input .flag-dropdown.open .selected-flag {
  background: transparent !important;
}

.react-tel-input .selected-flag {
  outline: none;
  position: relative;
  width: 20px !important;
  padding: 0 !important ;
  border-radius: none !important;
  background: transparent !important;
}
.react-tel-input .selected-flag .flag {
  margin-top: -21px !important;
  margin-left: 21px !important;
}
.login_form__inputs_flag {
  position: relative;
}

.input_text {
  position: absolute;
  z-index: 5;
  top: 10px;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  font-style: normal;
  color: #003e47cc;
  padding-left: 67px;
}

.login_form__inputs_flag-inner {
  border: 2px solid #003e471a;
  display: flex;
  border-radius: 6px;
  background-color: #003e4712;
  height: 100%;
  width: 100%;
}
.login_form__inputs_flag-inner-error {
  border: 2px solid red;
}
.login_form__inputs_flag-left {
  width: 60px;
  height: 60px;
}

.login_form__inputs_flag-right {
  width: 100%;
  background: #fff;
  border-radius: 0 6px 6px 0px;
  padding-top: 3px;
}
.PhoneInput {
  gap: 15px;
  margin-left: -42px;
  margin-top: 15px;
}
.PhoneInputInput {
  border: none;
  color: #003e47;
  font-family: "Raleway";
  font-weight: 500;
}
.PhoneInputCountrySelect {
  color: #003e47;
  font-family: "Raleway";
  font-weight: 500;
  font-size: 14px;
  padding-top: 10px;
}
option {
  width: 50px;
  height: 50px;
}
.PhoneInputCountrySelect option {
  width: 50px !important;
  height: 50px !important;
}
.login_form__subtitle-inner {
  margin-bottom: -10px;
}
.btn_bluelighActive {
  background-color: #49baca;
}
.btn_bluelighActive:hover {
  transition: 0.5s;
  background-color: transparent;
  border: 1px solid #49baca;
  color: #49baca;
}
