.Dropdown-control {
  width: 155px;
  height: 35px;
  background-color: #003e471a !important;
  font-size: 12px;
  color: #003e47 !important;
  font-weight: 700;
  font-family: Raleway;
  line-height: 14px;
  letter-spacing: 4%;
  border: none !important;
  border-radius: 4px !important;
  padding: 10px 0 0 10px !important;
  cursor: pointer !important;
}
.Dropdown-option {
  font-size: 12px;
  font-weight: 700;
  width: 155px;
  font-family: Raleway;
  color: #003e47 !important;
}

.Dropdown-menu {
  width: 155px !important;
  overflow-x: hidden !important;
  max-height: 125px !important;
}
