.root {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border: 1px solid rgba(0, 62, 71, 0.1);
  border-radius: 8px;
  width: 100%;
  cursor: pointer;
}

.filetype {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  min-width: 24px;
  height: 24px;
  background: #003e47;
  border-radius: 2px;
  font-weight: 700;
  font-size: 8px;
  line-height: 9px;
  font-weight: 700;
  font-size: 8px;
  line-height: 9px;
  color: #ffffff;
  text-transform: uppercase;
}

.info {
  margin-inline: 16px 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 350px;
}

.info .name {
  font-weight: 500;
  font-size: 14px;
  line-height: 136%;
  color: rgba(0, 62, 71, 0.8);
}

.info .infoTop,
.info .infoBot {
  display: flex;
}

.info .date {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 62, 71, 0.4);
}

.size {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 62, 71, 0.4);
  margin-left: auto;
  display: block;
}

.actions {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.actions span {
  height: 24px;
}

.actions svg {
  cursor: pointer;
}

.actions > * + * {
  margin-left: 10px;
}

.actions svg path {
  fill: rgba(0, 62, 71, 0.6);
  fill-opacity: 1;
  transition: 0.3s;
}

.actions svg:hover path {
  fill: rgba(0, 62, 71, 1);
}
