@import "../../../../../../../../global-ui-scss/mixins";
@import "../../../../../../../../global-ui-scss/variables";

.list {
  display: grid;
  grid-gap: 0.25rem;
  margin-top: 0.5rem;
}

.listItem {
  display: grid;
  grid-template-columns: 125px 1fr 100px;
  border-radius: 4px;
  grid-gap: 2rem;
  padding: 0.75rem;
  background-color: #fff;

  &.in_progress {
    .status {
      border-color: $color-primary-blue-main;
    }

    .type {
      color: $color-primary-blue-main;
    }
  }
}

.subtaskInfo {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
}

.type {
  font-weight: 700;
  font-size: 0.75rem;
  color: RgbaColor($primary-dark, 0.4);
  text-transform: capitalize;
}

.status {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  border-radius: 50%;
  border: 4px solid #dae3e5;
  transition: 0.3s border, 0.3s outline;
}

.name {
  font-weight: 500;
  color: $primary-dark;
}

.assertions {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 0.25rem;
}
