@import "../../../../../../global-ui-scss/variables";
@import "../../../../../../global-ui-scss/mixins";

.titleBlock {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #003e47;
  max-width: 320px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.titleBlock.readOnly {
  color: rgba(0, 62, 71, 0.6);
}

.meetingInfo {
  display: flex;
  margin-top: 8px;
  gap: 24px;
  align-items: center;

  .infoBlock {
    display: flex;
    align-items: center;

    svg {
      path {
        fill: $primary-neutrals-dark40;
      }
    }

    span {
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      color: $primary-neutrals-dark40;
      margin-left: 8px;
    }
  }
}

.assignedUsers {
  margin-top: 16px;
  display: flex;
  gap: 4px;
  flex-wrap: wrap;

  .userIcon {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: $white;
    background: $color-primary-blue-deep;
    border-radius: 50%;
    min-width: 24px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
  }
}
