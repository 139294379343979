@import "../../../global-ui-scss/variables.scss";
@import "../../../global-ui-scss/mixins.scss";

@mixin toggleButtonCreate($borderColor, $hoverBackground, $activeBackground) {
  border: 1px solid $borderColor;
  color: $borderColor;
  background-color: transparent;
  &:hover {
    background: $hoverBackground;
  }
  &.active {
    color: $white;
    background: $activeBackground;
    border: 1px solid transparent;
  }
}

.toggle_root {
  @include DisplayFlex(row, center, center);
  padding: 8px 15px;
  border: none;
  white-space: nowrap;
  border-radius: 25px;
  cursor: pointer;
  width: 100%;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  transition: background-color 0.4s;

  &.dark {
    @include toggleButtonCreate(
      $primary-neutrals-dark60,
      $primary-neutrals-dark10,
      $primary-neutrals-dark60
    );
  }
  &.green {
    @include toggleButtonCreate($color-green-700, $secondary-green, $color-green-700);
  }
  &.blue {
    @include toggleButtonCreate(
      $color-primary-blue-main,
      $secondary-blue,
      $color-primary-blue-main
    );
  }
}
