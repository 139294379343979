@import "../../../../../../../global-ui-scss/mixins";
@import "../../../../../../../global-ui-scss/variables";

.list {
}

.listItem {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr auto;
  padding: 1rem 0.75rem;
  border-bottom: 1px solid $color-neutrals-solid-4;
}
