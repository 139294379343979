@import "../../global-ui-scss/mixins";
@import "../../global-ui-scss/variables";

.ant-dropdown,
.ant-dropdown-menu-submenu {
  .ant-dropdown-menu {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

    .ant-dropdown-menu-item {
      font-size: 12px;
      font-weight: 700;
      color: $primary-dark;
      padding: 14px 16px;
      border-radius: 4px;

      &:hover {
        background-color: #f2f5f6;
      }

      &.ant-dropdown-menu-item-danger:not(.ant-dropdown-menu-item-disabled) {
        &:hover {
          color: $color-red-700;
          background-color: rgba(0, 0, 0, 0.04);
        }
      }
    }

    .ant-dropdown-menu-item-disabled {
      color: RgbaColor($primary-dark, 0.5);

      &:hover {
        color: RgbaColor($primary-dark, 0.5);
      }
    }
  }
}

//.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item.ant-dropdown-menu-item-danger,
//.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item.ant-dropdown-menu-item-danger {
//  color: $color-red-700;
//}
