@import "../../global-ui-scss/variables.scss";
@import "../../global-ui-scss/mixins.scss";

.ant-switch {
  min-width: 32px;
  height: 14px;
  background: RgbaColor($primary-dark, 0.1);

  &.ant-switch-disabled {
    opacity: 0.6;
    background: RgbaColor($primary-dark, 0.1);
  }

  &.ant-switch-checked {
    background: RgbaColor($color-primary-blue-main, 0.5);

    &:hover {
      &:not(.ant-switch-disabled) {
        background: RgbaColor($color-primary-blue-main, 0.5);
      }
    }

    .ant-switch-handle {
      inset-inline-start: calc(100% - 18px);

      &::before {
        background-color: $color-primary-blue-main;
      }
    }
  }

  &:hover {
    &:not(.ant-switch-disabled) {
      background: RgbaColor($primary-dark, 0.2);
    }
  }

  .ant-switch-handle {
    top: 50%;
    inset-inline-start: 0;
    margin-top: -9px;

    &::before {
      background-color: $color-neutrals-solid-7;
      box-shadow: none;
    }
  }
}
