.placeholder_container,
.video_wrapper video {
  max-width: 1114px;
  margin: 0 auto;
  border-radius: 12px;
  display: block;
  max-height: 504px;
  object-fit: contain;
  border: solid 5px rgba(0, 0, 0, 0.1);
  filter: brightness(0.2);
  transition: 0s;
  transform: scale(1);
}

.placeholder_container {
  aspect-ratio: 2 / 1.15;
}

.video_wrapper {
  position: relative;
  transition: 0.4s;
}

.video_wrapper_active {
  z-index: 100;
  /* position: relative; */
  position: fixed;
  top: 59px;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 37, 42, 0.8);
}

.video_wrapper_active video {
  /*transform: scale(1.2);*/
  filter: brightness(1);
  max-height: 75vh;
  max-width: 90%;
  transition: 0.4s;
}
/*
.video_wrapper .wrapper_controls {
  position: absolute;
  inset: 0 0 0 0;
  width: max-content;
  display: grid;
  align-items: center;
  grid-template-columns: max-content 1fr;
  margin: auto;
  height: max-content;
  cursor: pointer;
  filter: brightness(1.5);
} */

.video_wrapper .wrapper_controls {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  cursor: pointer;
  filter: brightness(1.5);
}

.video_wrapper_play {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 28px 0;
}

.video_wrapper .wrapper_controls span {
  font-weight: 800;
  font-size: 32px;
  line-height: 110%;
  color: #fff;
}

.close_video {
  cursor: pointer;
}

@media screen and (max-width: 820px) {
  .video_wrapper_active video {
    max-width: 95%;
  }
}

@media screen and (max-width: 600px) {
  .video_wrapper_active video {
    max-width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .video_wrapper .wrapper_controls {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    cursor: pointer;
    filter: brightness(1.5);
  }
}

@media screen and (max-width: 380px) {
  .wrapper_controls span {
    font-size: 30px !important;
    white-space: nowrap;
  }
}
