.list {
  display: grid;
  grid-gap: 0.25rem;
  margin-top: 0.5rem;
}

.dragItemWrapper {
  display: grid;
  grid-template-columns: 1.5rem 1fr;
  align-items: center;
  grid-gap: 0.5rem;
}

.dragItemChild {
  padding-left: 0.5rem;
}

.dragHandle {
  cursor: grab;
}
