@import "../../../../../global-ui-scss/mixins";
@import "../../../../../global-ui-scss/variables";

.loadingWrapper {
  display: flex;
  align-items: center;
  height: 100vh;
  align-self: center;
}

.wrapper {
  width: 100%;
  padding-bottom: 4rem;

  .topContainer {
    width: 100%;

    .headerSection {
      @include DisplayFlex(row, space-between, flex-start);
      padding: 24px;
      gap: 24px;
      width: 100%;
      border-bottom: 1px solid $primary-neutrals-dark10;
      position: relative;
    }

    .bodySection {
      padding: 24px;
      gap: 40px;
      width: 100%;
      @include DisplayFlex(column, center, flex-start);

      .containerAssigned {
        @include DisplayFlex(row, flex-start, flex-start);
        width: 100%;
        gap: 48px;
      }

      .columnAssignedWrapper {
        width: auto;
        align-items: flex-start;
      }

      .dateFileWrapper {
        gap: 24px;

        .inputDate {
          border: 1px solid #003e471a;
          border-radius: 4px;
          background-color: transparent;
          font-feature-settings: "pnum" on, "lnum" on;
          padding: 4px 8px 4px 12px;
          font-size: 14px;
          color: #003e47cc;
          font-weight: 500;

          &::placeholder {
            font-size: 14px;
            padding-top: 2px;
          }
        }
      }
    }
  }
}

.inner {
  display: grid;
  grid-gap: 0.5rem;
  padding-inline: 1.5rem;
}

.row {
  @include DisplayFlex(row, flex-start, center);
}

.column {
  width: 100%;
  @include DisplayFlex(column, center, flex-start);
  gap: 8px;
}
