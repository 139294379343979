@import "../../../global-ui-scss/mixins";
@import "../../../global-ui-scss/variables";

.root {
  display: flex;
  padding: 4px;
  margin-right: 20px;
  border: 1px solid rgba(0, 62, 71, 0.07);
  border-radius: 8px;
  overflow: auto;

  .tab {
    padding: 0.375rem 0.75rem;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1.5;
    color: $primary-dark;
    border-radius: 6px;
    transition: 0.3s;
    cursor: pointer;
    white-space: nowrap;
    margin-bottom: 0;
    text-transform: capitalize;

    &.active {
      background: #003e47;
      color: white;
    }
  }

  > * + * {
    margin-left: 4px;
  }
}
