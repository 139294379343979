@import "../../../../../../../global-ui-scss/mixins";
@import "../../../../../../../global-ui-scss/variables";

.headerActions {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
}

.header,
.filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 1.5rem;
}

.title {
  font-size: 1rem;
  color: $primary-dark;
}

.filters {
  margin: 1.5rem 0 1rem;
}
