@import "../../../../../global-ui-scss/mixins";
@import "../../../../../global-ui-scss/variables";

.popover {
  width: 280px;

  :global(.ant-popover-inner) {
    padding: 0;
  }
}

.documentWrapper {
  cursor: pointer;
}

.header {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.5rem 1rem;
  color: RgbaColor($primary-dark, 0.4);
  cursor: pointer;
}

.inputWrapper {
  position: relative;

  .searchIcon {
    position: absolute;
    top: 50%;
    left: 1rem;
    z-index: 2;
    transform: translateY(-50%);
  }

  .input {
    border-radius: 0;
    border-left: 0;
    border-right: 0;
    padding-left: 3.125rem;

    &::placeholder {
      font-size: 0.875rem;
      color: RgbaColor($primary-dark, 0.4);
    }
  }
}

.list {
  overflow: auto;
  height: 8.375rem;
}

.footer {
  position: relative;
  padding: 0.5rem;
  border-top: 1px solid RgbaColor($primary-dark, 0.1);
}

.uploadInput {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: -1;
}

.uploadButton {
  padding-block: 0.3125rem;
}

.options {
  padding: 0.5rem;
}

.footnote {
  color: RgbaColor($primary-dark, 0.4);
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.25rem;
  margin-bottom: 0.25rem;
}
