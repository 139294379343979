@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200&display=swap");

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Raleway", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --primary-white: #ffffff;
  --neutrals-dark-60: rgba(0, 62, 71, 0.6);
  --neutrals-dark-80: rgba(0, 62, 71, 0.8);
  --primary-blue: #49baca;
  --bg-main-gray: #f2f5f6;
  --bg-main-green: #003e47;
  --primary-dark: #003e47;
  --neutrals-dark-40: rgba(0, 62, 71, 0.4);
  --neutrals-solid-dark-40: #99b2b5;
  --bg-main-orange: #f17f16;
  --bg-main-white: #ffffff;
  --error: #ff0010;

  --color-red-100: rgba(255, 84, 84, 0.1);
  --color-red-200: rgba(255, 84, 84, 0.2);
  --color-red-700: rgba(255, 84, 84, 1);
}

h1,
h2,
h3,
h4,
h5,
h6,
figcaption {
  margin: 0;
}

ul,
ol {
  padding-left: 1rem;
}

input:focus,
textarea:focus,
select:focus,
div:focus {
  outline: none;
}

.hiddenCanvasElement {
  display: none;
}

.wrapper_app {
  padding: 30px 50px;
  overflow-x: unset;
}

.wrapper_main {
  max-width: 1128px;
  margin: 0 auto;
}

.wrapper_app_gray {
  background-color: #f2f5f6;
}

.wrapper_app_gray .copyright {
  color: rgba(0, 62, 71, 0.4);
}

.wrapper_app_green {
  background-color: #003e47;
}

.wrapper_main_gray * {
  color: rgba(0, 62, 71, 0.8);
}

.wrapper_main_gray .footer div .footer-text {
  color: rgba(0, 62, 71, 0.8);
}

.wrapper_main_green {
  background-color: var(--bg-main-green);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.loader {
  display: inline-block;
  width: 40px;
  height: 40px;
}

.loader:after {
  content: " ";
  box-sizing: content-box;
  display: block;
  width: 40px;
  height: 40px;
  margin: -6px;
  border-radius: 50%;
  border: 6px solid;
  border-color: #6db333 transparent #6db333 transparent;
  animation: rotate 1.2s linear infinite;
}

.loader.small,
.loader.small:after {
  height: 16px;
  width: 16px;
}

.loader.small:after {
  border-color: white transparent white transparent;
}

.timePickerPopup
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner:hover {
  background: #003e47;
}

body .ant-tabs-tab {
  padding: 0 0 16px;
}

body .ant-tabs-top > .ant-tabs-nav {
  margin-bottom: 40px;
}

body .ant-anchor-wrapper:not(.ant-anchor-wrapper-horizontal) .ant-anchor::before,
body .ant-anchor-wrapper:not(.ant-anchor-wrapper-horizontal) .ant-anchor .ant-anchor-ink {
  display: none !important;
}

body
  .ant-anchor-wrapper:not(.ant-anchor-wrapper-horizontal)
  .ant-anchor-link
  .ant-anchor-link-title {
  transition: background-color 0.3s;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--primary-dark, #003e47);
  padding: 14px 24px;
}

body
  .ant-anchor-wrapper.sectioned:not(.ant-anchor-wrapper-horizontal)
  .ant-anchor
  > .ant-anchor-link
  > .ant-anchor-link-title {
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  margin-block-end: 16px;
  color: #003e4766;
}

/*.account-settings-wrapper*/
/*  .ant-anchor-wrapper:not(.ant-anchor-wrapper-horizontal)*/
/*  .ant-anchor-link*/
/*  > .ant-anchor-link-title {*/
/*  pointer-events: none;*/
/*}*/

body
  .ant-anchor-wrapper:not(.ant-anchor-wrapper-horizontal)
  .ant-anchor-link
  .ant-anchor-link
  > .ant-anchor-link-title {
  pointer-events: all;
}

/*body .ant-anchor-wrapper:not(.ant-anchor-wrapper-horizontal) .ant-anchor-link .ant-anchor-link {*/
/*  padding-block: 14px;*/
/*}*/

body .ant-anchor-wrapper .ant-anchor .ant-anchor-link {
  padding: 0;
}
body
  .ant-anchor-wrapper:not(.ant-anchor-wrapper-horizontal)
  .ant-anchor-link.ant-anchor-link-active
  .ant-anchor-link-title {
  border-radius: 8px;
  background: var(--primary-white, #fff);
  font-weight: 700;
}

body .ant-card .ant-card-head-title {
  color: var(--primary-dark, #003e47);
  font-variant-numeric: lining-nums proportional-nums;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
}

body .ant-card:not(.ant-card-bordered) {
  box-shadow: none;
}

.ant-card .ant-card-grid,
.ant-card .ant-card-body {
  padding: 32px 24px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1200px) {
  .wrapper_app {
    overflow-x: scroll;
  }
}

@media screen and (max-width: 550px) {
  .wrapper_app {
    padding: 30px 15px;
    overflow: hidden;
  }
}
