.actions {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  margin: 20px 0;
  background: #ffffff;
  border-radius: 16px;
}

.loader {
  margin: 3rem;
}
