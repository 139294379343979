@mixin DisplayFlex($flexDirection, $justifyContent, $alignItems) {
  display: flex;
  flex-direction: $flexDirection;
  justify-content: $justifyContent;
  align-items: $alignItems;
}

@mixin EditButtonsAppear($top) {
  @keyframes editButtonsAppear {
    0% {
      top: 0;
      z-index: 0;
    }
    99% {
      top: $top;
      z-index: 0;
    }
    100% {
      z-index: 3;
    }
  }
  & {
    animation: editButtonsAppear 0.4s forwards;
    z-index: 0;
  }
}

@function RgbaColor($color, $opacity) {
  @return rgba($color, $opacity);
}
