.sub_menu_document {
  position: fixed;
  z-index: 100001;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: 0s;
  background: rgba(0, 62, 71, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.sub_menu_document .settings_right-audit .addAuditBtn {
  padding-right: 24px;
}

.sub_menu_document .settings_right-audit {
  position: relative;
  background-color: #fff;
  border-radius: 12px;
  padding: 22px 0;
  max-width: 720px;
}

.sub_menu_document .settings_right-audit .addAuditBtn {
  margin-bottom: 0;
}

.sub_menu_document .settings_right-audit .popup-title {
  margin: 22px 0 0 24px;
}

.sub_menu_document .settings_right-audit .popup-title,
.sub_menu_document .settings_right-audit .title {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #003e47;
  /*margin: 22px 0px 0px 24px;*/
  padding-bottom: 10px;
}

.documents_line-create {
  height: 1px;
  background-color: #003e4712;
  border: none;
  margin-top: 20px;
}

.block-input {
  padding: 35px 24px 15px 30px;
  display: flex;
  flex-direction: column;
  grid-gap: 22px;
}

.block-input_edit {
  padding: 22px 25px 15px 30px;
  display: flex;
  flex-direction: column;
  grid-gap: 22px;
}

.block-row_edit {
  display: grid;
  grid-template-columns: 92px 468px;
  flex-direction: row;
  justify-content: flex-start;
  grid-gap: 105px;
  align-items: center;
}

.block-input_edit .block-row_edit .title-input {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #003e47;
  width: 92px;
}

.block-input_edit .block-row_edit .inputNewDocuments {
  border: 1px solid #003e471a;
  border-radius: 4px;
  background-color: transparent;
  padding: 14px 12px 13px;
  width: 100%;
  font-size: 16px;
  color: #003e47cc;
  font-weight: 500;
}

.block-input .block-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
}

.block-input .block-row > *:not(span) {
  width: 468px;
}

/* .block-input .block-row input {
  border: 1px solid #003e471a;
  border-radius: 4px;
  background-color: transparent;
  padding: 14px 12px 13px;
  width: 57%;
  font-size: 16px;
  color: #003e47cc;
  font-weight: 500;
} */
.block-input .block-row .title-input {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #003e47;
  width: 92px;
  white-space: nowrap;
}

.category-line {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
}

.category-line .assign-row {
  position: relative;
  display: flex;
  flex-direction: row;
  grid-gap: 15px;
  /* align-items: center; */
}

.category-line .item-title {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: rgba(0, 62, 71, 0.4);
  width: 70px;
  display: flex;
  align-items: center;
}

.category-line .item-name {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  font-feature-settings: "pnum" on, "lnum" on;
  color: rgba(0, 62, 71, 0.8);
}

.block-input .block-row .inputNewDocuments {
  border: 1px solid #003e471a;
  border-radius: 4px;
  background-color: transparent;
  padding: 14px 12px 13px;
  font-size: 16px;
  color: #003e47cc;
  font-weight: 500;
}

.errorActive {
  border: 2px solid red;
  border-radius: 4px;
  background-color: transparent;
  padding: 14px 12px 13px;
  width: 57%;
  font-size: 16px;
  color: #003e47cc;
  font-weight: 500;
}

.file-drop-block_active {
  width: 100%;
  height: 80px;
  background: #fff;
  border-radius: 8px;
  justify-content: center;
  display: grid;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  grid-gap: 15px;
  padding: 25px 40px 26px;
  grid-template-columns: max-content max-content;
  align-items: center;
  border: 2px solid red;
  color: #49baca;
}

.file-drop-block {
  width: 100%;
  height: 80px;
  background: #fff;
  border-radius: 8px;
  justify-content: center;
  display: grid;
  cursor: pointer;
  grid-gap: 15px;
  padding: 25px 40px 26px;
  grid-template-columns: max-content max-content;
  align-items: center;
  border: 2px solid #49baca;
  color: #49baca;
}

.category-line.description .file-drop-block:hover {
  border: 2px solid #0f8594;
  transition: 0.4s;
  color: #0f8594;
  background: #f2f5f6;
}

.category-line.description .file-drop-block:hover path {
  fill: #0f8594;
}

.category-line.description .file-drop-block span,
.category-line.description .file-drop-block-open span {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
}

.file-drop-block-open {
  width: 100%;
  height: 80px;
  background: #6db3331a;
  border-radius: 8px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  cursor: pointer;
  grid-gap: 15px;
  padding: 25px 30px 26px;
  grid-template-columns: max-content max-content;
  border: none;
  color: #6db333;
}

.file-down-name-blockFile {
  border: none !important;
}

.file-drop-block-open.token-error {
  background: rgba(0, 62, 71, 0.1);
  color: rgba(0, 62, 71, 0.8);
}

.file-drop-block-open > button {
  margin-left: auto;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.04em;
  color: #6db333;
  border: 1px solid #6db333;
  border-radius: 4px;
  padding: 10px 16px;
  background-color: transparent;
  cursor: pointer;
}

.category-line.description .block-overflow {
  max-height: 130px;
  overflow-y: auto;
}

.category-line.description .block-overflow .file-down-block {
  display: flex;
  flex-direction: column;
  grid-gap: 13px;
}

.category-line.description .block-overflow .file-down-block .loader-file {
  height: 4px;
  width: 97%;
  background: #6db333;
  border-radius: 2px;
}

.category-line.description .block-overflow .file-down-block .file-down-row-block {
  display: flex;
  justify-content: space-between;
  /* align-items: flex-end; */
  padding: 14px 14px 14px 2px;
  grid-gap: 10px;
}

.category-line.description .block-overflow .file-down-block .file-down-row-block .file-delete {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: #ff5454;
  cursor: pointer;
  user-select: none;
}

.file-down-name-blocks {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  grid-gap: 18px;
  align-items: center;
}

.category-line.description
  .block-overflow
  .file-down-block
  .file-down-row-block
  .file-down-name-block {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  grid-gap: 18px;
}

.title-file-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: rgba(0, 62, 71, 0.8);
  cursor: pointer;
  max-width: 250px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.title-file-size {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 131.4%;
  font-feature-settings: "pnum" on, "lnum" on;
  color: rgba(0, 62, 71, 0.4);
}

.require-signature-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  grid-gap: 0 8px;
  position: relative;
  width: 100%;
}

.require-signature-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
}

.upload-document-signer .main-block {
  position: absolute;
  background-color: white;
  width: 380px;
  bottom: calc(100% + 12px);
  box-shadow: 0 6px 17px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
