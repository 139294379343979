.being-signed {
  color: var(--primary-blue, #49baca);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 4px;
  border: 1px solid var(--primary-blue, #49baca);
  background: rgba(73, 186, 202, 0.1);
  padding: 8.75px 16px;
}

.react-pdf__Document {
  position: relative;
}

.pdf-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.navigation-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
}

.navigation-button {
  color: black;
}

.content-block {
  display: grid;
  grid-template-columns: 360px 1fr;
  background: linear-gradient(0deg, rgba(0, 62, 71, 0.05), rgba(0, 62, 71, 0.05)), #ffffff;
}

.content-block .info-block {
  position: sticky;
  top: 83px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 83px);
  z-index: 2;
}

.content-block .info-block .info-block-row {
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content-block .info-block .pd-status {
  padding: 21px 24px 17px 24px;
  border-top: 1px solid #ebf0f0;
}

.content-block .info-block .pd-by {
  padding: 15px 24px;
}

.content-block .info-block .info-block-row .block-by {
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  align-items: center;
}

.content-block .info-block .info-block-row .block-by .block-by-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.content-block .info-block .info-block-row .block-by .block-by-content .block-first-letter {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  background: #49baca;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-block .info-block .info-block-row .block-by .block-by-content .block-first-letter {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.content-block .info-block .info-block-row .block-bordered {
  justify-content: space-between;
  border-bottom: 1px solid #f2f5f6;
  padding: 8px 0;
}

.content-block .info-block .info-block-row .block-by:last-child.block-bordered {
  border-bottom: none;
}

.content-block .info-block .info-block-row .block-by .block-first-letter {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  background: #49baca;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-block .info-block .info-block-row .block-by .block-first-letter {
  width: 24px;
  height: 24px;
}

.content-block .info-block .info-block-row .ready-button {
  border-radius: 25px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #6db333;
  background-color: rgba(109, 179, 51, 0.1);
  width: max-content;
  padding: 6px 9px;
  cursor: pointer;
}

.content-block .info-block .info-block-row .title-table {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 62, 71, 0.4);
}

.content-block .info-block .info-block-row .content-table,
.content-block .info-block .info-block-row .block-by .content-table {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: rgba(0, 62, 71, 0.8);
  display: flex;
  align-items: center;
}

.content-block .info-block .info-block-row .content-table svg {
  margin-right: 4px;
}

.content-block .info-block .button-arrows {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  grid-gap: 15px;
  margin: 24px;
  padding: 8px 0;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #99b2b5;
  border: 1px solid #99b2b5;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
}

.pdf-block {
  position: relative;
  min-width: 0;
  padding: 40px 15px;
  background: rgba(0, 62, 71, 0.05);
}

.pdf-view {
  position: relative;
  overflow: auto;
  margin: auto;
}

.pdf-block .pdf-view > span {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 62, 71, 0.4);
  text-align: center;
  display: block;
  margin-bottom: 12px;
}

.pdf-block .listA4 {
  align-items: center;
  width: max-content;
  height: max-content;
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  margin-top: 16px;
}

.field-value {
  color: var(--neutrals-dark-80, rgba(0, 62, 71, 0.8));
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 136%;
  margin-bottom: 8px;
}

@media (max-width: 768px) {
  .content-block {
    display: flex;
    flex-direction: column;

    .info-block {
      position: static;
      height: auto;

      .comments-block {
        padding-bottom: 0;

        input {
          position: static;
        }
      }
    }
  }

  .pdf-block {
    padding: 1rem;

    .pdf-view {
      > div {
        width: 100% !important;
      }
    }
  }
}
