@import "../../../../../../../../global-ui-scss/mixins";
@import "../../../../../../../../global-ui-scss/variables";

.wrapper {
  .drawerHeader {
    display: none;
  }

  .drawerBody {
    padding: 0;
  }

  .backButton {
    border: 0;
  }
}

.header {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  font-weight: 700;
  padding: 0.75rem 1.5rem;
  color: RgbaColor($primary-dark, 0.6);
  border-bottom: 2px solid $color-neutrals-solid-4;
}

.body {
  padding-block: 1.5rem;

  .inner {
    display: flex;
    flex-direction: column;
    grid-gap: 1.5rem;
    padding-inline: 1.5rem;

    + .inner {
      margin-top: 1.5rem;
    }
  }

  .titleWrapper {
    padding-inline: 1.5rem;
  }

  .title {
    font-size: 1.25rem;
    color: $primary-dark;
  }
}

.typeButton {
  width: 100%;
  background-color: transparent;
  border: 1px solid RgbaColor($primary-dark, 0.1);

  &.active {
    background-color: RgbaColor($color-primary-blue-main, 0.1);
    border-color: $color-primary-blue-main;
  }
}

.entriesWrapper {
  padding: 1.5rem;
  background-color: RgbaColor($primary-dark, 0.02);

  .entriesList {
    .dotsButton {
      background-color: transparent;
    }
  }
}

.divider {
  margin: 0;
}

.entriesTitle {
  font-size: 1rem;
  color: RgbaColor($primary-dark, 0.8);
  margin-bottom: 1.5rem;
}

.entriesList {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  margin-bottom: 1rem;
}

.alert {
  display: flex;
  align-items: center;
  grid-gap: 0.25rem;
  width: 100%;
  font-size: 0.75rem;
  font-weight: 500;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  color: $primary-dark;
  background-color: RgbaColor($primary-dark, 0.07);
}

.arrowsIcon {
  display: block;
  margin: auto;
  fill: RgbaColor($primary-dark, 0.8);
}
