.memberInfo {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;

  .memberName {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .memberTopRow {
      display: flex;
      align-items: center;
      gap: 4px;
      min-height: 23px;
    }

    .flag {
      padding: 2px 6px;
      border-radius: 3px;
      background: var(--neutrals-dark-7, rgba(0, 62, 71, 0.07));
      color: var(--neutrals-dark-40, rgba(0, 62, 71, 0.4));
      font-size: 12px;
      font-weight: 700;
      text-transform: capitalize;
    }

    .name {
      color: var(--neutrals-dark-80, rgba(0, 62, 71, 0.8));
      font-size: 14px;
      font-weight: 600;
      word-break: break-all;
    }

    .date {
      color: var(--neutrals-dark-40, rgba(0, 62, 71, 0.4));
      font-variant-numeric: lining-nums proportional-nums;
      font-size: 12px;
      font-weight: 700;
      line-height: 1;
      word-break: break-all;
    }
  }
}

.actions {
  display: flex;
  gap: 8px;
}
