@import "../../global-ui-scss/variables";

.wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  min-width: 0;
}

.name {
  color: $primary-dark;
  font-weight: 700;
  font-size: 0.875rem;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
