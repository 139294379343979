@import "../../../global-ui-scss/mixins";
@import "../../../global-ui-scss/variables";

.editor {
  background: transparent;
  width: 100%;
  white-space: pre-wrap;
  font-weight: 500;
  font-size: 0.875rem;
  padding: 1rem 1.25rem;
  color: $primary-dark;
  cursor: text;
  resize: vertical;

  :global(.public-DraftEditor-content span:not(.rdw-suggestion-dropdown)) {
    background-color: transparent !important;
  }

  :global(.rdw-image-imagewrapper) {
    img {
      max-width: 100%;
    }
  }
}

.wrapper {
  width: 100%;
  transition: 0.3s opacity;

  :global(.rdw-editor-toolbar) {
    padding: 8px;
    grid-gap: 0.125rem;
    margin-bottom: 0;
    border: none;
    background: RgbaColor($primary-dark, 0.02);
  }

  :global(.public-DraftStyleDefault-block) {
    margin: 0;
  }

  :global(.rdw-editor-main) {
    min-height: 10vh;
    box-sizing: border-box;
    border: none;
    transition: 0.3s all, 0s height;
  }
  ol {
    :global(.public-DraftStyleDefault-depth1) {
      counter-increment: depth1;

      &::before {
        content: counter(depth1, lower-alpha) ". ";
      }
    }

    :global(.public-DraftStyleDefault-depth2) {
      counter-increment: depth2;

      &::before {
        content: counter(depth2, lower-roman) ". ";
      }
    }
  }

  :global(.rdw-mention-link),
  :global(.rdw-link-decorator-wrapper) a {
    display: inline-block;
    padding: 0.125rem 0.25rem;
    margin: 1px;
    font-weight: 700;
    color: $primary-dark;
    border-radius: 4px;
    background-color: RgbaColor($color-primary-blue-main, 0.2);
  }

  :global(.rdw-link-decorator-wrapper) a {
    display: inline-flex;
    align-items: center;
    background: #003e471a;

    + :global(.rdw-link-decorator-icon) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }
}

.readOnly :global(.rdw-editor-toolbar) {
  display: none;
}

.root:not(.readOnly):not(.bordered) :global(.rdw-editor-wrapper) {
  border-color: transparent !important;
}

.root.readOnly.hiddenInput :global(.rdw-editor-wrapper),
.root.readOnly.hiddenInput :global(.rdw-editor-main) {
  padding: 0;
  border-color: transparent !important;
}

.hiddenInput :global(.rdw-editor-main) {
  min-height: max-content;
}

.wrapper.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.root.readOnly:hover :global(.rdw-editor-wrapper) {
  border-color: $primary-neutrals-dark20;
  background-color: rgba(0, 62, 71, 0.02);
}
