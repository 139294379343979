.header-aivo {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: grid;
  justify-items: center;
  z-index: 1000;
  margin: 0 auto;
  /* box-shadow: 0 2px 8px rgba(40, 41, 61, 0.04), */
  background-color: #003e47;
}

.header_info {
  color: #fff;
  font-family: "Raleway" sans-serif;
  font-weight: 500;
  font-size: 14px;
  width: 100%;
  height: 34px;
  padding-top: 0.5px;
  background: rgba(0, 0, 0, 0.1);
}

.header_i {
  display: flex;
  justify-content: space-between;
  max-width: 1148px;
  margin: 0 auto;
  padding-top: 0px;
}

.header_info-left {
  color: rgba(255, 255, 255, 0.4);
  display: flex;
  grid-gap: 24px;
  cursor: pointer;
  margin-left: 10px;
  line-height: 34px;
}

.header_info-right {
  color: rgba(255, 255, 255, 0.4);
  display: flex;
  align-items: center;
  grid-gap: 24px;
  font-weight: 600;
  margin-right: 10px;
}

.lang-active {
  text-decoration: unset;
  color: rgba(255, 255, 255, 1);
  opacity: 1;
  font-weight: 600;
  border-top: 2px solid #49baca;
  line-height: 30px;
}

.lang {
  line-height: 33px;
  cursor: pointer;
}

/*
todo: fix the responsiviness on mobile */
.header-aivo a {
  text-decoration: unset;
}

.header-aivo .wrapper {
  display: flex;
  padding: 15px 0;
  border-bottom: solid 1px rgba(255, 255, 255, 0.2);
  justify-content: space-between;
  max-width: 1128px;
  width: 100%;
  margin: 0 48px;
}

.revisison-and-login .button-login {
  background-color: #003e47;
}

.revisison-and-login .button-login:hover {
  background-color: transparent;
  border: 1px solid #ffffff;
}

.header-aivo-gray .wrapper {
  border-bottom: solid 1px rgba(0, 62, 71, 0.2);
}

.header-aivo-gray {
  background-color: #f2f5f6;
}

.revision-and-login {
  display: grid;
  grid-template-columns: 1fr max-content max-content;
  grid-gap: 12px;
}

.revision-and-login .lang span {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}

.header-aivo-gray .revision-and-login .lang span {
  color: #003e47;
}

.revision-and-login .lang,
.revision-and-login .lang span {
  display: flex;
  align-items: center;
}

/* overide the default styling  */
.revision-and-login button:hover {
  background-color: #ffffff !important;
  color: #003e47 !important;
  border: 1px solid #ffffff !important;
}

.menu {
  display: grid;
  align-items: center;
  grid-template-columns: 90px repeat(5, max-content);
  grid-gap: 28px;
  position: relative;
}

.nav-bar {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  position: relative;
  text-decoration: unset;
}

.menu .nav-bar:after {
  opacity: 0;
}

/* previous styling */
/* .menu .nav-bar:after, */
.menu .nav-bar-active:after {
  content: "";
  width: 100%;
  height: 2px;
  display: block;
  position: absolute;
  bottom: -24px;
  transition: 0.3s;
  background-color: var(--color);
}

.menu .nav-bar:hover:after {
  opacity: 1;
}

.menu .nav-bar:hover {
  opacity: 0.6;
  transition: 0.3s;
}

.menu .nav-bar-active:after {
  opacity: 1;
}

.revision-anfragen {
  background: #f17f16;
  border-radius: 4px;
  border: none;
  padding: 5px;
}

.word-revision {
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  text-transform: uppercase;
  padding: 10px;
  font-weight: bold;
}

.header-aivo-gray .nav-bar {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #003e47;
}

.login {
  background: #003e47;
  border: 1px solid #ffffff;
  border-radius: 4px;
}

.wrapper_open_menu {
  background-color: var(--background);
  position: fixed;
  bottom: 0;
  top: 53px;
  left: 100vw;
  right: -100vw;
  transition: 0.4s;
  display: grid;
  grid-template-rows: 1fr max-content max-content;
  padding: 20px 48px 80px;
}

.wrapper_open_menu_active {
  right: 0;
  left: 0;
}

.wrapper_open_menu .navigation {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 35px;
  margin-bottom: 50px;
}

.wrapper_open_menu .navigation .nav-bar {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 20px;
  align-items: center;
}

.wrapper_open_menu .menu_wrapper {
  margin: 0 0 0 auto;
}

.wrapper_open_menu .menu_wrapper span {
  transform: rotate(-90deg);
  display: block;
  margin-right: -12px;
  font-weight: bold;
  font-size: 12px;
  line-height: 110%;
  text-align: right;
  letter-spacing: 0.125em;
  text-transform: uppercase;
  color: var(--color);
  opacity: 0.2;
}

.wrapper_open_menu .revision-and-login {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.wrapper_open_menu .revision-and-login button {
  width: 100%;
  padding: 14px 0;
}

/* buttons take full width in the container */
@media screen and (max-width: 1000px) {
  .revision-and-login .wrapper_header_burger {
    margin-left: 36px;
    display: grid;
    align-self: center;
    cursor: pointer;
  }

  .revision-and-login .wrapper_header_burger .header_burger:after,
  .revision-and-login .wrapper_header_burger .header_burger {
    content: "";
    background: #c4c4c4;
    border-radius: 0.5px;
    width: 20px;
    height: 2px;
    display: block;
    margin-top: 4px;
    transition: 0.4s;
  }

  .wrapper_app_gray .revision-and-login .wrapper_header_burger .header_burger:after,
  .wrapper_app_gray .revision-and-login .wrapper_header_burger .header_burger {
    background-color: rgba(0, 62, 71, 1);
  }

  .revision-and-login .wrapper_header_burger .header_burger:after {
    margin-top: -6px;
  }

  .revision-and-login .wrapper_header_burger_active .header_burger {
    transform: rotate(45deg);
  }

  .revision-and-login .wrapper_header_burger_active .header_burger:after {
    transform: rotate(-90deg);
    margin-top: 0.2px;
    margin-left: -0.2px;
  }
}

@media screen and (max-width: 820px) {
  .revision-and-login {
    display: grid;
    grid-template-columns: 1fr repeat(2, max-content);
    grid-gap: 15px;
    /* grid-gap: 5px;   margin-right: -10px;*/
  }

  .header-aivo .wrapper {
    border-bottom: unset;
  }

  .header-aivo .wrapper::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 0;
    bottom: -3px;
    border-bottom: solid 1px rgba(255, 255, 255, 0.2);
  }

  .header-aivo-gray .wrapper::after {
    border-bottom: solid 1px rgba(0, 62, 71, 0.2) !important;
  }

  .wrapper_open_menu .menu_wrapper {
    margin: 12px -2px 0 auto;
  }
}

@media screen and (max-width: 768px) {
  .header-aivo .wrapper {
    border-bottom: unset;
    padding: 15px 20px;
  }

  .revision-and-login {
    grid-gap: 16px;
  }

  .header-aivo .wrapper::after {
    content: "";
    position: absolute;
    width: calc(100% - 40px);
    height: 0;
    bottom: -3px;
    border-bottom: solid 1px rgba(255, 255, 255, 0.2);
  }

  .header-aivo-gray .wrapper::after {
    border-bottom: solid 1px rgba(0, 62, 71, 0.2) !important;
  }

  .wrapper_open_menu {
    top: 44px;
  }

  .revision-and-login .wrapper_header_burger .header_burger:after,
  .revision-and-login .wrapper_header_burger .header_burger {
    margin-right: 1px !important;
  }

  .wrapper_open_menu .menu_wrapper {
    margin: 0 1px 0 auto !important;
  }
}

@media screen and (max-width: 500px) {
  .header-aivo .wrapper {
    margin: 0 20px;
  }
}
@media screen and (max-width: 450px) {
  .wrapper_open_menu .revision-and-login {
    grid-template-columns: 1fr;
    margin-bottom: -20px;
  }

  .wrapper_open_menu {
    padding: 20px 20px 62px;
  }

  .header-aivo .wrapper::after {
    content: "";
    position: absolute;
    width: calc(100% - 40px);
    height: 0;
    bottom: 0;
    border-bottom: solid 1px rgba(255, 255, 255, 0.2);
  }

  .header-aivo-gray .wrapper::after {
    border-bottom: solid 1px rgba(0, 62, 71, 0.2) !important;
  }

  .header-aivo .menu {
    grid-template-columns: 1fr;
  }

  .wrapper_open_menu .menu_wrapper {
    /* margin: 0 -34px 0 auto; */
    margin: 0 -1px 0 auto;
  }
}
