@import "../../../../../../global-ui-scss/mixins";
@import "../../../../../../global-ui-scss/variables";

.grid {
  width: 100% !important;
  height: max-content;
  box-shadow: none;

  &.highlighted {
    background-color: #fffce4;
  }

  &.noShadow {
    box-shadow: none;
  }

  &:not(.memberGrid) {
    padding-block: 20px;
  }

  input {
    padding: 11px 15px;
  }

  :global(.Dropdown-control) {
    height: 56px;
    width: 100%;
    padding: 0 0 0 16px !important;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 700;

    :global(.Dropdown-arrow) {
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .roleDisclaimer {
    display: block;
    color: var(--neutrals-solid-dark-40, #99b2b5);
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 12px;
    font-weight: 700;
    margin-top: 8px;
  }

  .relativeSearch {
    position: relative;

    .searchWrapper {
      position: absolute;
      z-index: 3;
      top: 12px;
      width: 450px;
      max-height: 300px;
      overflow: scroll;
      background: white;
      box-shadow: 0 6px 17px rgba(0, 0, 0, 0.1);
      border-radius: 8px;

      .titleName {
        font-weight: 600;
        font-size: 14px;
        line-height: 136%;
        margin-left: 10px;
        color: var(--neutrals-dark-80, rgba(0, 62, 71, 0.8));
      }

      .blockName {
        display: flex;
        align-items: center;
        padding: 12px;
        cursor: pointer;
      }
    }
  }

  .memberRow {
    display: flex;
    justify-content: space-between;
    align-items: center;

    :global(.Dropdown-control) {
      min-width: 140px;
    }

    .actions {
      margin-left: auto;
    }

    .memberInfo {
      display: flex;
      align-items: center;
      gap: 16px;
      width: 100%;

      .memberName {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .memberTopRow {
          display: flex;
          align-items: center;
          gap: 4px;
        }

        .flag {
          padding: 2px 6px;
          border-radius: 3px;
          background: var(--neutrals-dark-7, rgba(0, 62, 71, 0.07));
          color: var(--neutrals-dark-40, rgba(0, 62, 71, 0.4));
          font-variant-numeric: lining-nums proportional-nums;
          font-size: 12px;
          font-weight: 700;
          text-transform: capitalize;
        }

        .name {
          color: var(--neutrals-dark-80, rgba(0, 62, 71, 0.8));
          font-variant-numeric: lining-nums proportional-nums;
          font-size: 14px;
          font-weight: 600;
          line-height: 136%;
          word-break: break-all;
        }

        .email {
          color: var(--neutrals-dark-40, rgba(0, 62, 71, 0.4));
          font-variant-numeric: lining-nums proportional-nums;
          font-size: 12px;
          font-weight: 700;
          word-break: break-all;
        }
      }
    }
  }
}

.grid.memberGrid {
  padding-block: 18px;

  :global(.Dropdown-control) {
    height: 34px;
  }
}
