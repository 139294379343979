@import "../../../global-ui-scss/mixins.scss";
@import "../../../global-ui-scss/variables.scss";

.root {
  position: sticky;
  top: 0;
  align-self: start;
  width: 100%;
  border-radius: 8px;
  background-color: RgbaColor($primary-dark, 0.07);
  z-index: 20;
}

.inner {
  display: grid;
  grid-template-columns: 1fr 60px 120px 120px 140px 100px;
  grid-gap: 2rem;
  padding: 0 16px;
}

.title {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 0.75rem;
  padding: 0.5rem 0;
  color: $primary-dark;

  &.desc .icon {
    transform: rotate(180deg);
  }

  &.asc .icon {
    transform: rotate(0);
  }
}

.pointer {
  cursor: pointer;
}

.icon {
  margin-left: 0.25rem;
  transition: transform 250ms;
}
