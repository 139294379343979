.block-experts {
  margin-top: 130px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 2px;
  align-items: center;
  justify-items: center;
}
.block-experts span {
  font-weight: 800;
  font-size: 40px;
  line-height: 110%;
  color: #003e47;
}

.content-experts {
  max-width: 380px;
  display: grid;
  grid-gap: 26px 0;
  height: max-content;
  color: #003e47;
}
.content-experts a {
  text-decoration: unset;
}

.block-experts p {
  line-height: 148%;
  color: rgba(0, 62, 71, 0.8);
  margin: 0;
  font-weight: 500;
  font-size: 16px;
}

.team-what-believe {
  margin: 180px 0;
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
}

.team-white-block {
  background: #ffffff;
  border-radius: 16px;
  position: relative;
  width: 550px;
  height: 465px;
}

.team-white-block img {
  width: 400px;
  max-height: 450px;
  position: absolute;
  top: 120px;
  left: -30px;
}

.wrapper_team .team-info {
  margin-left: -70px;
  z-index: 1;
  position: relative;
}

.team-info {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
}

.experts-support .title,
.team-info .title {
  font-weight: 800;
  font-size: 32px;
  line-height: 110%;
  color: #003e47;
}

.experts-support-info .description,
.team-info .description {
  font-weight: 500;
  font-size: 16px;
  line-height: 148%;
  color: rgba(0, 62, 71, 0.8);
}

.experts-support {
  margin: 180px 0;
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
}

.experts-white-block {
  background: #ffffff;
  border-radius: 16px;
  position: relative;
  width: 550px;
  height: 465px;
}

.experts-support-info {
  display: flex;
  flex-direction: column;
  margin: 20px 104px 20px 0;
}

.business-decisions {
  margin-top: 63px;
  margin-left: 57px;
}

.our-experts-title {
  text-align: center;
}

.our-experts-title .title {
  font-weight: 800;
  font-size: 40px;
  line-height: 110%;
  color: #003e47;
}

.our-experts-wrapper {
  display: grid;
  margin-top: 80px;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 45px;
  justify-items: center;
}

.our-experts-wrapper .every-expert .name {
  font-weight: 800;
  font-size: 20px;
  line-height: 110%;
  color: #003e47;
  margin-top: 10px;
  margin-bottom: 10px;
}

.our-experts-wrapper .every-expert .title {
  font-weight: 500;
  font-size: 14px;
  line-height: 110%;
  color: rgba(0, 62, 71, 0.6);
}

.undrawSegment {
  margin-left: 120px;
}

.img-experts {
  display: block;
  position: relative;
}

.bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: -1px;
}

.img-experts:hover .bg {
  opacity: 0.9;
  background: linear-gradient(0deg, rgba(1, 63, 71, 0.9), rgba(1, 63, 71, 0.9)), #ffffff;
  border-radius: 16px;
  width: 100%;
  height: 99.4%;
}
.text {
  opacity: 0;
}

.img-experts:hover .text {
  opacity: 1;
  font-weight: 500;
  font-size: 14px;
  line-height: 148%;
  padding: 15px;
}

.orange-block {
  width: 248px;
  height: 330px;
  border-radius: 16px;
  background: #f17f16;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 18px;
}

.orange-block p {
  text-transform: uppercase;
  font-size: 14px;
  line-height: 110%;
  font-weight: bold;
}

.orange-block .new-talent {
  color: #ffffff;
  font-weight: 800;
  font-size: 20px;
  line-height: 110%;
  text-align: center;
}

.orange-block .white {
  background-color: #f5f5f5;
  margin-top: 24px;
  color: #f17f16;
}

@media screen and (max-width: 1200px) {
  .our-experts-wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
  .bg {
    width: 248px;
    height: 330px;
  }
}

@media screen and (max-width: 1015px) {
  .experts-support .title,
  .team-info .title {
    font-size: 27px;
  }
}

@media screen and (max-width: 918px) {
  .experts-support {
    grid-template-columns: none;
  }
}

@media screen and (max-width: 888px) {
  .experts-support {
    grid-template-columns: none;
  }
  .our-experts-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
  .undrawSegment {
    width: 355px;
    height: 353px;
  }
}

@media screen and (max-width: 805px) {
  .right-side-blue .undrawSegment {
    width: 355px;
    height: 271px;
    margin-left: 0;
  }
}

@media screen and (max-width: 862px) {
  .team-what-believe {
    grid-template-columns: none;
    margin-top: 120px;
  }
  .wrapper_team .team-info {
    grid-row-start: 1;
    margin: 0;
  }
  .block-experts {
    grid-template-columns: none;
    grid-gap: 53px 80px;
    align-items: flex-start;
    justify-items: flex-start;
  }

  .content-experts {
    max-width: 560px;
  }
  .experts-support-info {
    margin: 0;
  }
}

@media screen and (max-width: 670px) {
  .team-white-block {
    width: 458px;
    height: 403px;
  }
  .experts-white-block {
    width: 458px;
    height: 403px;
  }
  .business-decisions {
    width: 458px;
    height: 403px;
  }
}

@media screen and (max-width: 610px) {
  .img-experts img {
    width: 220px;
    height: 310px;
  }
  .bg {
    width: 220px;
    height: 310px;
  }
  .orange-block {
    width: 220px;
    height: 310px;
  }
}

@media screen and (max-width: 574px) {
  /* width of image on  removed */
  /* .right-side-blue .undrawSegment {
    width: 255px;
    height: 253px;
    margin-left: 0;
  } */
}

@media screen and (max-width: 554px) {
  .team-white-block img {
    left: 10px;
    top: 49px;
  }
  .team-white-block {
    width: 395px;
    height: 328px;
  }
  .team-what-believe {
    margin: 60px 0;
  }
  .experts-support {
    margin: 90px 0;
  }

  .team-white-block {
    width: 358px;
    height: 303px;
  }
  .experts-white-block {
    width: 358px;
    height: 303px;
  }
  .business-decisions {
    width: 358px;
    height: 303px;
  }
}

@media screen and (max-width: 525px) {
  .img-experts img {
    width: 190px;
    height: 254px;
  }
  .bg {
    width: 190px;
    height: 255px;
  }
  .our-experts-wrapper {
    grid-gap: 20px;
  }
  .img-experts:hover .bg {
    border-radius: 13px;
  }
  .orange-block {
    width: 190px;
    height: 254px;
  }
}

@media screen and (max-width: 425px) {
  .img-experts img {
    width: 158px;
    height: 210px;
  }
  .bg {
    width: 158px;
    height: 210px;
  }
  .creative-team {
    width: 339px;
    height: 266px;
  }
  .team-white-block {
    width: 339px;
    height: 266px;
  }
  .team-white-block img {
    width: 339px;
    height: 266px;
    left: -3px;
    top: 6px;
  }
  .experts-white-block {
    width: 339px;
    height: 266px;
  }
  .business-decisions {
    margin: 0;
  }

  .block-experts span,
  .our-experts-title .title {
    font-size: 32px;
  }
  .block-experts {
    margin-top: 70px;
  }
}

@media screen and (max-width: 350px) {
  .creative-team {
    width: 299px;
    height: 220px;
  }
  .team-white-block {
    width: 288px;
    height: 245px;
  }
  .team-white-block img {
    width: 295px;
    height: 248px;
  }
  .business-decisions {
    width: 299px;
    height: 250px;
  }
  .experts-white-block {
    width: 288px;
    height: 245px;
  }

  .img-experts img {
    width: 138px;
    height: 210px;
  }
  .bg {
    width: 138px;
    height: 210px;
  }
}
