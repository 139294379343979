@import "../../../global-ui-scss/variables.scss";
@import "../../../global-ui-scss/mixins.scss";

.menu {
  position: relative;
  @include DisplayFlex(column, center, flex-end);

  .menu_button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    width: 34px;
    height: 34px;
    cursor: pointer;
    &:hover {
      background: #003e4712;
    }
    .dot {
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background-color: #000;
      margin: 2px 0;
    }
  }
  .dropdown {
    position: absolute;
    top: 40px;
    min-width: 206px;
    right: 0;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 4px;
    box-shadow: 0px 6px 17px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
}
