html {
  scroll-behavior: smooth;
}

/* .faq-wrapper {
  margin: 140px 0;
} */

.frequently-questions {
  margin-top: 145px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 1px;
  align-items: center;
  justify-items: center;
}

.faq-info span {
  font-weight: 800;
  font-size: 40px;
  line-height: 110%;
  color: #003e47;
}

.faq-info p {
  font-weight: 500;
  font-size: 16px;
  line-height: 148%;
  color: rgba(0, 62, 71, 0.8);
}

.faq-info {
  display: grid;
  grid-gap: 11px 0;
  height: max-content;
  max-width: 400px;
  color: #003e47;
}

.my_answer {
  margin-bottom: -120px;
}

.offer-blocks {
  display: grid;
  grid-template-columns: repeat(5, 200px);
  margin-top: 230px;
  grid-gap: 32px;

  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
}

.faq-offer-block:hover {
  border: 2px solid #49baca;
}

.faq-offer-block {
  margin-bottom: 20px;
  scroll-snap-align: center;
  display: grid;
  align-items: center;
  justify-content: center;
  justify-items: center;
  padding: 10px;
  background: #ffffff;
  border-radius: 8px;
  height: 110px;
  width: 200px;
  cursor: pointer;
  border: 2px solid white;
}

.faq-offer-block-active {
  border: 2px solid #49baca;
}

.faq-offer-block-active svg path {
  fill: #49baca;
}

.faq-offer-block span {
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #003e47;
}

.faq-wrapper .blue-window {
  grid-gap: 135px;
}

.get-an-offer {
  padding-top: 126px;
  max-width: 950px;
  margin: 0 auto;
}

.get-an-offer h1 {
  text-align: center;
  font-weight: 800;
  font-size: 40px;
  line-height: 110%;
  color: #003e47;
}

.offer-questions {
  display: grid;
  grid-template-rows: repeat(4, max-content);
  grid-gap: 8px;
  margin-top: 60px;
}

.first-question {
  border: 1px solid rgba(0, 62, 71, 0.2);
  border-radius: 8px;
  padding: 29px;
  cursor: pointer;
  transition: 0.3s;
}

.first-question span {
  font-weight: 800;
  font-size: 18px;
  line-height: 110%;
  color: #003e47;
}

.first-question-active {
  background: rgba(0, 62, 71, 0.05);
  border: 1px solid rgba(0, 62, 71, 0.2);
}

.question {
  display: grid;
  grid-template-columns: 1fr max-content;
}

.first-question .question .plus {
  transform: rotate(0);
  transition: 0.2s;
}

.first-question-active .question .plus {
  transform: rotate(45deg);
}

.first-question-active .question .plus path {
  fill: #000;
}

.open-answer span {
  font-weight: 500;
  font-size: 16px;
  line-height: 148%;
  color: rgba(0, 62, 71, 0.8);
}

.open-answer {
  margin-top: 20px;
}

@media screen and (max-width: 1200px) {
  .offer-blocks {
    overflow: scroll;
  }
}

@media screen and (max-width: 768px) {
  .frequently-questions {
    grid-template-columns: 1fr;
    grid-gap: 32px;
  }

  .frequently-questions img {
    margin-bottom: 0;
  }

  .offer-blocks {
    margin-top: 100px;
  }

  .get-an-offer {
    padding-top: 80px;
  }

  .get-an-offer h1 {
    font-size: 32px;
    margin-top: 0;
  }

  .offer-questions {
    margin-top: 30px;
  }

  .faq-info {
    max-width: unset;
  }
  .faq-wrapper .blue-window {
    grid-gap: 35px;
  }
}

@media screen and (max-width: 450px) {
  .frequently-questions img {
    width: 100%;
  }
  .frequently-questions {
    margin-top: 70px;
  }
}
