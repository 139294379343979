@import "../../../global-ui-scss/variables.scss";

.popupContainer {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
  align-items: center;
  justify-content: center;

  :global(.ant-card-grid) {
    box-shadow: none;
  }

  .title {
    padding: 20px 24px;
  }
}

.popup {
  margin: 15% auto;
  border: 1px solid #888;
  width: 80%;
  max-width: 720px;
  z-index: 1001;
  position: relative;
  border-radius: 8px;
  background: var(--primary-white, #fff);
  box-shadow: 0 6px 17px 0 rgba(0, 0, 0, 0.1);
}

.title {
  color: $primary-dark;
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  padding: 20px 24px;
  border-bottom: 1px solid var(--neutrals-solid-dark-8, #ebf0f0);
}

.popup :global(.popup-body),
.body {
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  color: #003e47;
  border-top: 1px solid var(--neutrals-solid-dark-8, #ebf0f0);
  border-bottom: 1px solid var(--neutrals-solid-dark-8, #ebf0f0);
  gap: 20px;
}
