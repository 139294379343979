.contact_wrapper .revision_section {
  margin-top: 100px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  align-items: center;
  justify-items: center;
}

.contact_wrapper .revision_section .info {
  display: grid;
  grid-gap: 13px 0;
  height: max-content;
  max-width: 400px;
  color: #003e47;
}

.contact_wrapper .revision_section .preview {
  margin: 0 auto;
}

.contact_wrapper .contact_us_wrapper {
  margin-top: 140px;
}

.contact_wrapper .contact_us_wrapper .wrapper_tabs {
  border-bottom: 1px solid rgba(0, 62, 71, 0.2);
  display: grid;
  justify-content: center;
  grid-gap: 55px;
  grid-template-columns: repeat(2, max-content);
}

.contact_wrapper .contact_us_wrapper .wrapper_tabs .tab {
  font-weight: 800;
  font-size: 40px;
  cursor: pointer;
  line-height: 110%;
  color: #3b7680;
  display: block;
  padding-bottom: 20px;
}

.contact_wrapper .contact_us_wrapper .wrapper_tabs .active_tab {
  border-bottom: 4px solid #49baca;
  color: #003e47;
}

.contact_wrapper .contact_us_wrapper .book_a_meeting {
  /*background: #FBFCFD;*/
  min-height: 75vh;
  display: grid;
  grid-template-columns: 1fr 2fr;
  border-radius: 0 0 12px 12px;
  margin-top: 24px;
  grid-gap: 100px;
}

.contact_wrapper .contact_us_wrapper .book_a_meeting .wrapper_calendly {
  margin: 0 auto;
}

.contact_wrapper .contact_us_wrapper .contact_us {
  display: grid;
  grid-gap: 100px;
  grid-template-columns: 1fr 2fr;
  margin-top: 24px;
}

.contact_wrapper .contact_us_wrapper .info {
  background: #003e47;
  height: max-content;
  border-radius: 16px;
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 1fr max-content 1fr;
  padding: 55px 40px;
  margin: 100px 0 60px;
}

.contact_wrapper .contact_us_wrapper .info__every {
  display: grid;
  text-align: center;
  color: #fff;
  grid-template-columns: max-content max-content;
  align-items: center;
  justify-content: center;
  grid-gap: 26px;
}

.contact_wrapper .contact_us_wrapper .info__every a {
  color: unset;
  text-decoration: unset;
}

.contact_wrapper .contact_us_wrapper .line {
  content: "";
  display: block;
  width: 1px;
  background-color: #f17f16;
}

.contact_wrapper .contact_us_wrapper .info__every svg {
  margin: 0 auto;
}

.contact_wrapper .contact_us_wrapper .contact_us form {
  margin-top: 66px;
}

.contact_wrapper .contact_us_wrapper .contact_us form .email-adress {
  border: 1px solid #dae3e5;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #ffffff !important ;
}
.contact_wrapper .contact_us_wrapper .contact_us form .email-adress:focus-within {
  border: 2px solid #1b9bb8 !important;
}
.contact_wrapper .contact_us_wrapper .preview_wrapper {
  margin-top: 66px;
}

.contact_wrapper .contact_us_wrapper .preview_wrapper .title {
  font-weight: 800;
  font-size: 32px;
  line-height: 110%;
  color: #003e47;
}

.contact_wrapper .contact_us_wrapper .preview_wrapper .description {
  font-weight: 500;
  font-size: 16px;
  margin: 38px 0 48px;
  line-height: 148%;
  color: rgba(0, 62, 71, 0.8);
}

.contact_wrapper .contact_us_wrapper .contact_us form .email-adress textarea,
.contact_wrapper .contact_us_wrapper .contact_us form .email-adress input {
  background-color: transparent;
  border: none;
  outline: none;
}
.contact_wrapper .contact_us_wrapper .contact_us form .inputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.contact_wrapper .contact_us_wrapper .contact_us form .btn_bluelight,
.contact_wrapper .contact_us_wrapper .contact_us form .inputs .textarea_wrapper {
  grid-column: 1/-1;
}

.contact_wrapper .contact_us_wrapper .contact_us form .btn_bluelight {
  padding: 16px 60px;
  justify-self: end;
}

.revision_section .info .title {
  color: #003e47;
  font-weight: 800;
  font-size: 40px;
  line-height: 110%;
}

.revision_section .info .description {
  color: rgba(0, 62, 71, 0.8);
  font-size: 16px;
  line-height: 148%;
}

@media screen and (max-width: 1000px) {
  .contact_wrapper .contact_us_wrapper .preview_wrapper .title {
    font-size: 28px;
  }

  .contact_wrapper .contact_us_wrapper .preview_wrapper .description {
    font-size: 14px;
  }
}

@media screen and (max-width: 900px) {
  .contact_wrapper .revision_section {
    grid-template-columns: 1fr;
  }

  .contact_wrapper .contact_us_wrapper {
    margin-top: 100px;
  }

  .contact_wrapper .contact_us_wrapper .book_a_meeting {
    grid-gap: 40px;
  }
}

@media screen and (max-width: 768px) {
  .contact_wrapper .contact_us_wrapper .book_a_meeting,
  .contact_wrapper .contact_us_wrapper .contact_us {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }

  .contact_wrapper .contact_us_wrapper .preview_wrapper,
  .contact_wrapper .contact_us_wrapper .contact_us form {
    margin-top: 0;
  }

  .contact_wrapper .contact_us_wrapper .wrapper_tabs .tab {
    font-size: 32px;
  }

  /* .contact_wrapper .contact_us_wrapper .wrapper_tabs .tab {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 12px;
    align-items: center;
    justify-content: center;
    margin: auto;
    text-align: right;
  } */

  .contact_wrapper .contact_us_wrapper .wrapper_tabs .tab {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 12px;
    position: relative;
    left: 60px;
    bottom: 10px;
    margin: 4px 0;
  }

  .contact_wrapper .contact_us_wrapper .wrapper_tabs .active_tab {
    border-bottom: unset;
  }

  /* todo soon */
  /* .contact_wrapper .contact_us_wrapper .wrapper_tabs .active_tab:before {
    content: '';
    display: block;
    width: 32px;
    height: 4px;
    background: #49baca;
    margin-top: 16px;
  } */

  .contact_wrapper .contact_us_wrapper .wrapper_tabs .active_tab:before {
    content: "";
    position: absolute;
    width: 32px;
    height: 4px;
    background: #49baca;
    margin-top: 16px;
    left: -50px;
  }
}

@media screen and (max-width: 550px) {
  .contact_wrapper .contact_us_wrapper .contact_us form .inputs {
    grid-template-columns: 1fr;
  }

  .contact_wrapper .contact_us_wrapper .wrapper_tabs {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }

  .contact_wrapper .contact_us_wrapper .info {
    grid-template-columns: 1fr;
  }

  .contact_wrapper .contact_us_wrapper .line {
    height: 2px;
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .contact_wrapper .revision_section svg {
    width: 100%;
  }
  .contact_wrapper .revision_section {
    margin-top: 70px;
  }
  .contact_wrapper .contact_us_wrapper .contact_us form .email-adress:focus-within {
    border: none !important ;
  }
}
