@import "../../../global-ui-scss/mixins";
@import "../../../global-ui-scss/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  grid-gap: 2rem;
  padding: 1.5rem;
  background: linear-gradient(0deg, rgba(0, 62, 71, 0.05), rgba(0, 62, 71, 0.05)), #ffffff;
}

.title {
  font-size: 24px;
  font-weight: 800;
  color: $primary-dark;
}
