@import "../../../../global-ui-scss/mixins.scss";
@import "../../../../global-ui-scss/variables.scss";

.row {
  @include DisplayFlex(row, flex-start, center);
}

.column {
  width: 100%;
  @include DisplayFlex(column, center, flex-start);
  gap: 8px;
}

.datePicker {
  width: 150px;
}

.timePicker {
  width: 150px;
}
