@import "../../../../../../global-ui-scss/mixins";
@import "../../../../../../global-ui-scss/variables";

.root {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: 0.5rem;
  line-height: 1.2;
  min-height: 2.25rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 300ms;

  &:hover {
    .endIcon {
      color: $color-primary-blue-main;
    }
  }

  .tag {
    font-size: 0.625rem;
    padding: 0.125rem 0.375rem;
  }
}

.endIcon {
  color: #b2c5c8;
}

.title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 500;
  font-size: 0.875rem;
  color: $primary-dark;
  margin-bottom: 0.125rem;
}

.content {
  min-width: 8rem;
  max-width: 12rem;
  padding-right: 1rem;
}

.date {
  font-weight: 700;
  font-size: 0.75rem;
  color: RgbaColor($primary-dark, 0.4);
}
