@import "../global-ui-scss/variables.scss";
@import "../global-ui-scss/mixins.scss";

.input_container label {
  font-size: 12px;
  line-height: "normal";
  color: rgba(0, 62, 71, 0.8);
  font-weight: bold;
  cursor: text;
}

.asteriskone {
  color: #ffa500;
  position: absolute;
  top: 8px;
  left: 45px;
}

.asterisktwo {
  color: #ffa500;
  position: absolute;
  top: 8px;
  left: 95px;
}

.input_container input {
  background-color: transparent;
  height: 21px;
  outline: none;
  padding-bottom: 0;
}

.input_container .wrapper_input {
  position: relative;
  outline: 1px solid var(--neutrals-dark-10, rgba(0, 62, 71, 0.1));
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff !important;
  transition: 0.3s background-color, 0.3s border;

  &::placeholder {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: $primary-disable;
  }

  &:hover {
    background-color: rgba(0, 62, 71, 0.02) !important;
    outline: 1px solid rgba(0, 62, 71, 0.2);
    color: #003e47;
  }

  &:disabled {
    outline: none !important;
    background: rgba(0, 62, 71, 0.07);
  }
}

.input_container .wrapper_input:focus-within {
  outline: 2px solid #49baca !important;
  background: transparent !important;
}

.input_container.input_error .wrapper_input:focus-within {
  outline: 2px solid #ff5454 !important;
}

.input_container .wrapper_inputActive {
  position: relative;
  outline: 2px solid red;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #ffffff !important;
}

.input_hint {
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
}

.input_hint p,
.input_hint .wrapper_input {
  grid-column: 1/-1;
}

.input_container .hint {
  position: relative;
  justify-self: end;
}

.input_container .hint:hover .tool_tip {
  display: block;
}

.input_container .tool_tip {
  border-radius: 2px;
  display: none;
  right: 0;
  bottom: 30px;
  position: absolute;
  background: rgba(0, 0, 0, 0.75);
  padding: 8px 6px;
  max-width: 200px;
  z-index: 10;
  width: 200px;
}

/*.input_container .tool_tip:before {*/
/*    position: absolute;*/
/*    background: rgba(0, 0, 0, 0.75);*/
/*    content: '';*/
/*    width: 8px;*/
/*    height: 8px;*/
/*    right: 2px;*/
/*    bottom: -3px;*/
/*}*/

.input_container .hint .tool_tip span {
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
}

.input_show_icon .wrapper_input .show_pass_icon {
  position: absolute;
  top: 50%;
  right: 12px;
  cursor: pointer;
  transform: translateY(-50%);
}

.input_title {
  position: absolute;
  margin-top: 13px;
  margin-left: 14px;
}

.custom_input {
  width: 100%;
  height: 64px !important;
  padding-left: 14px;
  background: #ffffff;
  border: 1px solid var(--light_gray);
  transition: 0.4s;
  border-radius: 4px;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  padding-top: 20px;
}

.input_error .wrapper_input {
  outline: 1px solid var(--error) !important;
  background-color: rgba(255, 84, 84, 0.1) !important;

  &:hover {
    background-color: transparent !important;
    outline: 1px solid #d94747 !important;
  }

  &:focus {
    background-color: yellow !important;
  }
}

.input_text_error {
  color: #ff5454 !important;
}

.errorMessage {
  font-size: 12px;
  line-height: 14px;
  color: var(--error);
  margin: 4px 0 0;
  grid-column: 1/-1;
}

::placeholder {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 62, 71, 0.2);
}

.input_container .input_descpription {
  font-size: 14px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.54);
  margin: 4px 0 0;
}

.custom_text_area .ant-input,
.input_container .ant-input {
  padding: 9px 16px;
  border-radius: 4px;
}

@media screen and (max-width: 450px) {
  .asteriskone {
    display: none;
  }

  .asterisktwo {
    display: none;
  }

  .input_container .wrapper_input:focus-within {
    outline: none !important;
  }
}
