@import "../../../global-ui-scss/variables.scss";
@import "../../../global-ui-scss/mixins.scss";

.tag_root {
  @include DisplayFlex(row, center, center);
  padding: 4px 8px;
  border-radius: 25px;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  width: max-content;

  &.clickable {
    cursor: pointer;
  }

  &.closable {
    svg {
      transition: 0.3s;
      cursor: pointer;
      opacity: 0;
      width: 0;
    }

    &:hover svg {
      margin-left: 4px;
      width: 16px;
      opacity: 1;
    }
  }

  &.small {
    padding: 2px 6px;
    font-size: 10px;
    font-weight: 700;
    line-height: 12px;
  }

  &.dark {
    background: $primary-neutrals-dark10;
    color: $primary-neutrals-dark80;
  }

  &.gray {
    color: #003e47;
    background: $secondary-gray-dark;
  }

  &.blue {
    color: $color-primary-blue-deep;
    background: $secondary-blue;
  }

  &.green {
    color: $primary-green-dark;
    background: $secondary-green20;
  }

  &.orange {
    color: $secondary-orange;
    background: $secondary-orange-dark10;
  }
}
