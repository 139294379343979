@import "../../../global-ui-scss/mixins";
@import "../../../global-ui-scss/variables";

.fileType {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  color: #ffffff;
  text-transform: uppercase;
  border-radius: 4px;
  font-weight: 700;
  font-size: 10px;
  min-width: 36px;
  background: RgbaColor($primary-dark, 0.8);
}
