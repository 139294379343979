@import "../../../../../global-ui-scss/mixins";
@import "../../../../../global-ui-scss/variables";

.popover {
  :global(.ant-popover-inner) {
    padding: 0;
  }

  .h1 {
    font-size: 1.5rem;
    font-weight: 800;
  }

  .h2 {
    font-size: 1.25rem;
    font-weight: 800;
  }

  .h3 {
    font-size: 1rem;
    font-weight: 700;
  }

  .normal {
    font-size: 0.875rem;
    font-weight: 400;
  }

  .menu {
    padding: 0.25rem;
  }

  .menuItem {
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 4px;

    &:hover {
      background-color: $color-neutrals-solid-4;
    }
  }
}
