.root {
  .body {
    &:global(.ant-modal-body) {
      padding: 0;
    }

    :global(.ant-form-item) {
      margin-bottom: 0;
    }
  }
}

.body {
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  padding: 1rem 1.5rem;
}

.footer {
  padding: 1rem 1.5rem;
  border-top: 1px solid #ebf0f0;
}
