@import "../../../../../../global-ui-scss/variables.scss";

.titleBlock {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #003e47;
  max-width: 320px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.requestType {
  color: var(--neutrals-dark-60, rgba(0, 62, 71, 0.6));
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 11px */
  letter-spacing: 1.25px;
  text-transform: uppercase;
  margin-bottom: 4px;
}

.titleBlock.readOnly {
  color: rgba(0, 62, 71, 0.6);
}

.requestInfo {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  align-items: center;

  .statusTag {
    max-width: max-content;
  }
}

.notificationSide {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 24px;
  /* grid-template-columns: max-content max-content; */
  .block {
    display: grid;
    align-items: center;
    grid-gap: 12px;
    grid-template-columns: max-content max-content;

    .countLength {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.02em;
      color: rgba(0, 62, 71, 0.4);
    }
  }
}

.assignedUsers {
  margin-top: 16px;
  display: flex;
  gap: 4px;
  flex-wrap: wrap;

  .userIcon {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: $white;
    background: $color-primary-blue-deep;
    border-radius: 50%;
    min-width: 24px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
