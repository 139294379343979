@import "../../../global-ui-scss/variables.scss";
@import "../../../global-ui-scss/mixins.scss";

.root {
  width: 100%;
  border-radius: 4px;
  outline: 1px solid $primary-neutrals-dark10;
  border: none !important;
  padding: 0.563rem 1rem;
  transition: 0.3s;

  &::placeholder {
    font-weight: 500;
    font-size: 14px;
    color: RgbaColor($primary-dark, 0.4);
  }

  &:hover {
    outline: 1px solid RgbaColor($primary-dark, 0.2);
    color: $primary-dark;
    border: none !important;
    background: RgbaColor($primary-dark, 0.02);
  }

  &:focus {
    outline: 2px solid $color-primary-blue-main;
    border: none !important;
  }

  &:disabled {
    outline: none !important;
    border: none !important;
    color: #003e4799;
    background: #003e4712;
    cursor: not-allowed;
  }

  &.error {
    outline: 1px solid $color-red-700;
    border: none !important;
    background-color: $secondary-red-10;
  }

  &.highlighted {
    background-color: #fffce4;
  }
}

.errorText {
  color: $color-red-700;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
}
