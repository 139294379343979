.root {
  //background: #49baca;
  border-radius: 50%;
  height: 34px;
  width: 34px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  justify-content: center;
  flex-shrink: 0;

  svg,
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: contain;
  }

  img {
    background-color: white;
  }
}
