@import "../../../global-ui-scss/variables.scss";

.root {
  border-radius: 4px;
  display: flex;
  padding: 12px 16px;
  align-items: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  &.orange {
    border-color: var(--Secondary-Orange-700, #f17f16);
    background: var(--Secondary-Orange-100, rgba(241, 127, 22, 0.1));
    color: var(--Secondary-Orange-700, #f17f16);
  }

  &.gray {
    background: #003e4712;
    border: 1px solid #b2c5c8;
    color: var(--neutrals-solid-dark-40);
  }
}
