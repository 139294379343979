@import "../../../../../../global-ui-scss/mixins";
@import "../../../../../../global-ui-scss/variables";

.wrapper {
  display: grid;
  grid-gap: 16px;
  padding: 1rem 1.5rem;
}

.type {
  font-size: 0.75rem;
  font-weight: 700;
  color: RgbaColor($primary-dark, 0.8);
  text-transform: capitalize;
}

.title {
  font-size: 1.25rem;
  color: $primary-dark;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.descriptionWrapper {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  font-size: 14px;
  font-weight: 500;
  color: $primary-dark;
}
