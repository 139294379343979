@import "../../../../../global-ui-scss/mixins";
@import "../../../../../global-ui-scss/variables";

.form {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  margin-top: 1rem;
}

.categoryGroup,
.categoryButton {
  width: 100%;
}

.categoryButton {
  position: relative;
  justify-content: center;
  padding: 0.625rem;
  line-height: 1.5;
  border-radius: 4px;
  border: 1px solid RgbaColor($primary-dark, 0.1);

  &:global(.ant-checkbox-wrapper) {
    color: $primary-dark;
    transition: background-color 300ms, border-color 300ms;
  }

  &:global(.ant-checkbox-wrapper-checked) {
    border-color: $color-primary-blue-main;
    background-color: RgbaColor($color-primary-blue-main, 0.1);
  }

  :global(.ant-checkbox) {
    position: absolute;
    opacity: 0;
  }
}
