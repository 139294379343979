.login_form__subtitle{
   color: #003E47;
   font-size: 14px;
   text-align: center;
   font-weight: 500;
   line-height: 17px;
   margin-top: 12px;
}

.btn_blueligh{
   width: 340px;
   height: 56px;
   font-size: 14px;
   border: 1px solid transparent;
   color: #fff;
   text-transform:none ;
   font-weight: 700;
   background: #003E4733;
   cursor: not-allowed;

}
.btn_blueligh:hover {
   border: 1px solid transparent;
   color: #fff;
   background: #003E4733;
}

.login_form__inputs .error-text{
   color: red;
   text-align: center;
   font-weight: 700;
   letter-spacing: 0.5px;
}
.login_form__inputs .sent-text{
   color: #003E4733;
   text-align: center;
   font-weight: 700;
   letter-spacing: 0.5px;
}
.btn_blueligh_active{
   background-color:#49BACA;
   cursor: pointer;
}
.btn_blueligh_active:hover{
  background-color: transparent;
  color: #49BACA;
  border: 1px solid #49BACA;
}
.btn_bluelighSent{
   background-color: #49BACA;
   color: #fff;
}
.login_form-sendEmail_images{
   margin: 0px auto 26px;
}
.login_form__subtitle-sendEmail{
   margin-bottom: 6px;
}