@import "../../../../global-ui-scss/mixins.scss";
@import "../../../../global-ui-scss/variables.scss";

.root {
  @include DisplayFlex(row, flex-start, center);
  gap: 8px;

  .assignButton {
    @include DisplayFlex(row, center, center);
    color: $secondary-gray;
    border: 1px dashed $tertiary-neutrals-dark20;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    transition: border-color 0.3s;
  }

  .assignText {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: $secondary-gray;
    transition: color 0.3s;
  }

  &:hover .assignButton {
    border: 1px dashed rgba(0, 62, 71, 0.6);
    cursor: pointer;
  }

  &:hover .assignText {
    color: rgba(0, 62, 71, 0.6);
  }
}
