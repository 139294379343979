@import "../../../global-ui-scss/mixins";
@import "../../../global-ui-scss/variables";

.root {
  :global(.ant-modal-content) {
    padding: 0;
  }

  :global(.ant-modal-header),
  :global(.ant-modal-footer) {
    padding: 1rem 1.5rem;
    margin: 0;
  }

  :global(.ant-modal-header) {
    border-bottom: 1px solid #ebf0f0;
  }

  :global(.ant-modal-title) {
    font-weight: 700;
    color: $primary-dark;
  }

  :global(.ant-modal-body) {
    padding: 2rem 1.5rem;
  }

  :global(.ant-modal-footer) {
    border-top: 1px solid #ebf0f0;
  }
}
