.settingsAudit {
  display: flex;
  width: 100%;
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  line-height: 19px;
}

.header_settings {
  height: auto;
  position: sticky;
  z-index: 100;
  top: 70px;
  background-color: #fff;
  padding: 35px 32px 24px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header_settings-left {
  display: flex;
  align-items: center;
  grid-gap: 25px;
  color: #003e47;
  font-size: 24px;
  font-weight: 800;
  cursor: pointer;
}

.header_settings-right {
  display: flex;
  align-items: center;
  grid-gap: 20px;
}

.btn_header-right_unSave {
  width: 128px;
  height: 34px;
  border: 1px solid #ff5454;
  border-radius: 4px;
  color: #ff5454;
  font-size: 12px;
  font-weight: 700;
  background-color: transparent;
  line-height: 14px;
  cursor: pointer;
}

.btn_header-right_NoSave {
  width: 128px;
  height: 34px;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  background-color: #003e4733;
  line-height: 14px;
}

.btn_header-right_active {
  background-color: #6db333;
}

.btn_header-right_save {
  width: 128px;
  height: 34px;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  background-color: #6db333;
  line-height: 14px;
  letter-spacing: 0.04em;
  cursor: pointer;
  transition: 0.6s;
}

.progressBar_navigation {
  width: 16px !important;
  height: 16px !important;
}

.progressBar_navigation .CircularProgressbar-trail {
  stroke: #dae3e5 !important;
}
