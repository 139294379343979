@import "../../../../../global-ui-scss/mixins";
@import "../../../../../global-ui-scss/variables";

.popover {
  :global(.ant-popover-inner) {
    padding: 0;
  }

  .manu {
    padding: 0.25rem;
  }

  .menuItem {
    display: flex;
    align-items: center;
    grid-gap: 0.25rem;
    cursor: pointer;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;

    &:hover {
      background-color: $color-neutrals-solid-4;
    }
  }
}
