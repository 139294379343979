.account-settings-wrapper {
  max-width: 1088px;
  margin: 40px auto 0;
}

.account-settings-wrapper #react-select-5-listbox {
  max-width: 140px;
}

.account-settings-wrapper .ant-col > span {
  color: var(--primary-dark, #003e47);
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 136%;
}

.audits_logo {
  z-index: 30;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: auto;
  padding: 18px 24px;
  background: #ffffff;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ebf0f0;
}

.audits_logo .logo {
  cursor: pointer;
}

.audits_info {
  display: grid;
  align-items: center;
  grid-gap: 16px;
  grid-template-columns: 1fr max-content;
  position: relative;
  cursor: pointer;
}

.accountSetting__inner {
  min-height: calc(100vh - 71px);
  padding: 3.625rem 1.5rem;
  background: linear-gradient(0deg, rgba(0, 62, 71, 0.05), rgba(0, 62, 71, 0.05)), #ffffff;
}

.accountSetting_title {
  background: linear-gradient(0deg, rgba(0, 62, 71, 0.05), rgba(0, 62, 71, 0.05)), #ffffff;
  text-align: center;
  font-size: 24px;
  font-weight: 800;
  line-height: 27px;
  margin-bottom: 15px;
  color: #003e47;
}

.settings_right-info_last-next_inner {
  display: grid;
  grid-template-columns: 180px 1fr;
  grid-gap: 9px 24px;
}

.sub_menu_Audit .outside_click_wrapper,
.sub_menu_Audit .subMenuActive_addAudit {
  width: max-content;
}

.subMenuActive_addAudit .settings_right-info_lastCreate .settings_right-info_last-next_error input {
  border: 1px solid red;
  padding-left: 12px;
  font-feature-settings: "pnum" on, "lnum" on;
}

/* .settings_right-info_last-nextImages{
  margin-left: -27px;
} */
.settings {
  max-width: 900px;
  display: flex;
  justify-content: flex-start;
  grid-gap: 20%;
  /* margin: 0 auto; */
  margin-top: 65px;
  margin-left: 11%;
  /* margin-bottom: 10%; */
}

.settings_right_addTeamMember {
  margin-top: 50px;
  padding-bottom: 58px;
}

.settings_right_addTeamMember .Dropdown-menu {
  max-height: 96px !important;
}

.settings_accountSet {
  margin-left: 27%;
  margin-top: 100px;
}

.settings .setting_menu {
  position: fixed;
  margin-top: 30px;
  list-style-type: none;
  margin-left: -12%;
}

.setting_menuListAccount {
  margin-top: 70px !important;
}

.settings_right-info {
  height: 306px;
  background-color: #fff;
  border-radius: 12px;
}

.setting_menu-list:hover {
  color: #003e47;
}

.setting_menu-list {
  display: grid;
  align-items: center;
  grid-gap: 20px;
  margin-bottom: 12px;
  width: 280px;
  height: 40px;
  color: #003e47;
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  margin-right: 24px;
  padding-left: 24px;
  cursor: pointer;
  user-select: none;
}

.setting_menu_details {
  margin-top: 47px;
}

.setting_menu_detailsAddTeamMem {
  position: relative !important;
}

.setting_menu-list-active {
  display: grid;
  align-items: center;
  grid-gap: 20px;
  transition: 0.2s;
  border-radius: 8px;
  background-color: #fff;
  font-weight: 700;
  cursor: pointer;
  user-select: none;
}

.settings_right_permissions {
  margin-top: 40px;
  margin-bottom: 0;
}

.settings_right-info_inner {
  padding: 20px 24px 16px;
  margin-bottom: 24px;
  color: #003e47;
  font-weight: 700;
  font-size: 16px;
}

.settings_right-info_inner hr {
  margin-top: 20px;
  margin-bottom: 0;
  margin-inline: -24px;
  border: none;
  height: 1px;
  background-color: #003e4712;
}

.settings_right-info_last {
  display: flex;
  justify-content: space-between;
  color: #003e47;
  font-size: 14px;
  font-weight: 500;
  grid-gap: 24px;
  margin-top: 32px;
}

.account-details-grid {
  display: grid;
  grid-template-columns: 180px 1fr;
  grid-gap: 24px;
  margin-top: 12px;
}

.MuiSwitch-track {
  margin-inline: -3px;
  width: calc(100% + 6px) !important;
}

.settings_right-info_lasts {
  display: flex;
  justify-content: space-between;
  color: #003e47;
  font-size: 14px;
  font-weight: 500;
  grid-gap: 60px;
  margin-top: 25px;
  margin-right: 50px;
}

.settings_right-info-set {
  margin-bottom: 0;
}

.settings_right-info_last-one {
  display: grid;
  grid-template-columns: 1fr;
  color: #003e47;
  grid-gap: 0;
  width: 100%;
  align-items: center;
  font-size: 14px;
  line-height: 17px;
}

.create-company-popup .settings_right-info_last-one {
  width: 180px;
  grid-gap: 20px;
  grid-template-rows: 125px 44px 44px;
  align-items: flex-start;
}

.create-company-popup .settings_right-info_last input {
  margin-left: 0;
}

.create-company-popup .settings_right-info_last-next {
  width: calc(100% - 180px);
}

.settings_right-info_last-two {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 21px;
  margin-top: 10px;
}

.settings_right-info_last-two .account-settings-split-input {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
}

.settings_right-info_last input {
  /*max-width: 500px;*/
  width: 100%;
  height: 46px;
  margin-left: 3px;
}

.settings_right-info_last input:disabled {
  border-color: transparent;
}

.settings_right-info_last-next {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  grid-gap: 20px;
}

.settings_right-info_last-next input {
  border: none;
  border-radius: 4px;
  background-color: transparent;
  padding-left: 12px;
  font-size: 16px;
  color: #003e47cc;
  font-weight: 500;
  font-feature-settings: "pnum" on, "lnum" on;
}

.settings_right-info_last-next .error-input {
  border: 1px solid #f30000;
}

.settings_right-info_last-next .error_input {
  border: 1px solid var(--error) !important;
}

.css-j204z7-MuiFormControlLabel-root {
  flex-direction: row;
}

.settings_right-info_inner-note {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.settings_right-info_last-one-note {
  width: auto;
  align-items: start;
  margin-top: 10px;
}

.settings_right-info_last-one-lang {
  display: flex;
  grid-gap: 173px;
}

.settings_right-info_last-note {
  display: grid;
  grid-template-columns: 180px 1fr;
  justify-content: flex-start;
  grid-gap: 24px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.settings_right-info-btn {
  width: 100%;
  margin-left: 107.5px;
}

.MuiSwitch-switchBase.Mui-checked {
  color: #49baca !important;
}

.MuiSwitch-switchBase {
  color: #003e47 !important;
}

.MuiSwitch-track {
  background: #e5dada !important;
}

.accountSettings_inner {
  position: absolute;
  cursor: pointer;
  width: 206px;
  top: calc(100% + 8px);
  right: 0;
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: grid;
  font-size: 12px;
  font-weight: bold;
  color: #003e47;
  padding: 4px;
}

.settings_btn hr {
  margin-top: 20px;
  margin-bottom: 0;
  border: none;
  height: 1px;
  background-color: #003e4712;
}

.settings_btn .settings_btn_checked {
  display: flex;
  justify-content: flex-end;
  margin: 20px;
}

.account-settings:not(.audit-settings) .settings_btn .settings_btn_checked {
  margin: 16px 0 0;
}

.condition-columns {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 15px;
}

.condition-block {
  display: flex;
}

.condition-item {
  display: flex;
  align-items: center;
}

.condition-item > svg {
  margin-right: 8px;
}

.condition_green {
  color: #569720;
}

.condition_grey {
  color: #99b2b5;
}

.condition_red {
  color: #d94747;
}

.settings_right-info_input {
  display: flex;
  width: 420px;
  padding-right: 25px;
  padding-left: -20px;
  border: none;
  outline: 1px solid #003e471a;
  border-radius: 4px;
}

.settings_right-info_input::placeholder {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #6c757d;
}

.settings_right-info_input:hover {
  background: rgba(0, 62, 71, 0.02);
  outline: 1px solid rgba(0, 62, 71, 0.2);
  border: none !important;
  color: #003e47;
}

.settings_right-info_input:focus-within {
  outline: 2px solid #49baca;
  border: none !important;
}

.settings_right-info_input:disabled {
  outline: none !important;
  border: none !important;
  background: rgba(0, 62, 71, 0.07);
}

.settings_right-info_input.error {
  outline: 1px solid #dc3545;
  border: none !important;
  background-color: #f8d7da;
}

.settings_right-info_input_inner_eye {
  cursor: pointer;
}

.settings_right-info_input_closeBtn {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 10px;
  margin-right: -15px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.create-company-popup {
  display: flex;
  align-items: center;
}

.create-company-popup .ant-col > span {
  color: var(--primary-dark, #003e47);
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 14px;
  font-weight: 500;
  line-height: 136%;
}

.popup-body.sign-popup {
  padding: 44px;
  padding-bottom: 32px;
  min-height: 378px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 0;
}

.popup-body .countText {
  font-size: 0.75rem;
  font-weight: 700;
  color: #49baca;
  margin-top: 3.5rem;
}

.sign-progress {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  border-radius: 100px;
  width: 152px;
  height: 152px;
  flex-shrink: 0;
  background-color: transparent;
  border: 1px dashed #0f8594;
  transition: 0.3s;
}

.sign-progress.signed {
  border-color: transparent;
  background-color: var(--Secondary-Green-100, rgba(109, 179, 51, 0.1));
}

.sign-progress.error {
  border-color: transparent;
  background-color: var(--Secondary-Red-100, rgba(255, 84, 84, 0.1));
}

.sign-progress .status {
  margin-block: 18px 24px;
  color: var(--Primary-Dark, #003e47);
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.95px;
  text-transform: uppercase;
}

.sign-progress.signed .status {
  color: var(--Secondary-Green-700, #6db333);
  margin-top: 32px;
}

.sign-progress.error .status {
  color: var(--Secondary-Red-700, #ff5454);
  margin-top: 32px;
}

.sign-popup.error > .sign-bold-text,
.sign-popup.signed > .sign-bold-text,
.sign-popup.start > .sign-bold-text {
  margin-top: 32px;
}

.sign-popup.signing-app .sign-opacue-text,
.sign-popup.personal-data .sign-opacue-text,
.sign-popup.error .sign-opacue-text,
.sign-popup.signed .sign-opacue-text,
.sign-popup.start .sign-opacue-text {
  margin-top: 10px;
}

.sign-popup.error .sign-red-text,
.sign-popup.signed .sign-red-text,
.sign-popup.start .sign-red-text {
  margin-top: 59px;
  cursor: pointer;
}

.sign-popup.error .sign-bold-text + .sign-bold-text,
.sign-popup.signed .sign-bold-text + .sign-bold-text,
.sign-popup.start .sign-bold-text + .sign-bold-text {
  margin-top: 8px;
}

.sign-popup.personal-data > button,
.sign-popup.signing-app > button {
  margin-top: 72px;
}

.popup-body .sign-bold-text {
  color: var(--Primary-Dark, #003e47);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 17.6px */
}

.popup-body .sign-opacue-text {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 13.2px */
  opacity: 0.2;
}

.popup-body.personal-data .sign-opacue-text,
.popup-body.signing-app .sign-opacue-text {
  color: var(--neutrals-dark-60, rgba(0, 62, 71, 0.6));
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 136%; /* 19.04px */
  opacity: 1;
}

.popup-body .sign-red-text {
  color: var(--secondary-red-800, #d94747);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 17.6px */
  cursor: pointer;
  padding: 20px 16px;
  width: 200px;
  text-align: center;
  margin-top: 20px;
}

.popup-body > button {
  width: 200px;
}

.sign-action-row {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 56px;
}

.sign-action-row > button {
  width: 200px;
}

.popup-body .ant-col .caption {
  display: block;
  color: var(--neutrals-dark-60, rgba(0, 62, 71, 0.6));
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 12px;
  font-weight: 500;
  line-height: 136%;
  margin-top: 4px;
}

.create-company-popup .ant-space-compact {
  padding-block: 12px;
}

.create-company-popup .settingsAuditAdd {
  margin-top: 0;
}

.account-settings {
  margin: 45px auto;
  width: max-content;
  display: grid;
  grid-template-columns: 304px 1fr;
  grid-gap: 32px;
  padding-bottom: 400px;
}

.account-settings .settings-right {
  max-width: 700px;
}

.account-settings .settings-left {
  height: max-content;
  position: sticky;
  top: 110px;
  margin-left: auto;
  padding-inline-start: 0;
}

.account-settings.audit-settings .setting_menu-list {
  margin-right: 0;
  padding-left: 0;
}

.account-settings.audit-settings .setting_menu-list a {
  padding-left: 24px;
}

.account-settings.audit-settings {
  padding-right: 0;
  grid-template-columns: 280px 1fr;
  padding-bottom: 200px;
  width: 100%;
}

.account-settings.audit-settings .settings-right {
  max-width: unset;
}
