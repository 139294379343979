.root {
  padding: 4px;
  display: flex;
  align-items: center;
  gap: 12px;
  background: transparent;
  transition: 0.3s;
  border-radius: 4px;

  .name {
    font-size: 12px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0em;
    color: #003e47;
  }

  &.bordered {
    border: 1px solid var(--neutrals-dark-10, rgba(0, 62, 71, 0.1));
  }

  &:hover {
    background: #003e4712;
  }
}
