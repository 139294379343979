.audits .audits_logo .logo {
  cursor: pointer;
}

.header-notifications {
  margin-inline: auto 18px;
  position: relative;
  display: flex;
  align-items: center;
  padding-block: 1px;
  transition: 0.3s;
  cursor: pointer;
}

.header-notifications.unread-notifications::after {
  content: " ";
  position: absolute;
  top: 4px;
  right: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #f17f16;
  border: 1px solid #ffffff;
}

.header-notifications.active-page {
  background: rgba(0, 62, 71, 0.07);
  border-radius: 4px;
}

.audits_info {
  display: grid;
  align-items: center;
  grid-gap: 12px;
  padding: 6px 12px;
  background: rgba(0, 62, 71, 0.07);
  border-radius: 4px;
  grid-template-columns: 1fr max-content;
  cursor: pointer;
  transition: 0.3s background-color;
}

.audits_info:hover {
  background-color: rgba(0, 62, 71, 0.1);
}

.audits_info .audits-block {
  display: grid;
  grid-gap: 5px;
}

.audits_info .icon-name-person-blue {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  background: #49baca;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  justify-content: center;
}

.audits_info .audits-block .manage-info {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 62, 71, 0.8);
}

.audits_content {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  min-height: calc(100vh - 70px);
  padding: 40px;
  background: linear-gradient(0deg, rgba(0, 62, 71, 0.05), rgba(0, 62, 71, 0.05)), #ffffff;
}

.audits_content-inner {
  display: grid;
  grid-gap: 24px;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
}

.audits_content-inner .audits_title-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.audits_search {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
  margin-bottom: 1.5rem;
  border-bottom: 2px solid rgba(0, 62, 71, 0.1);
}

.audits_search-input {
  border: none;
  background-color: transparent;
  margin-left: -18px;
  padding-left: 34px;
  height: 100%;
  width: 100%;
}

.audits_search .audits_search-left {
  display: flex;
  align-items: center;
  width: 100%;
}

.audits_search-input {
  color: #003e4799;
  font-size: 16px;
}

.audits_search-input::placeholder {
  color: #003e4799;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
}

.css-1yjjitx-MuiSwitch-track {
  background-color: #dae3e5 !important;
}

.css-j204z7-MuiFormControlLabel-root {
  flex-direction: row-reverse;
}

.audits_info_block {
  max-width: 700px;
  width: 100%;
  height: max-content;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
}

.audits_info_block.expanded {
  padding: 0 8px 8px;
}

.audits_info_block > .audits_info_data + .audits_info_data {
  margin-top: 8px;
}

.audits_info_block > button {
  margin: 16px auto 8px;
  width: max-content;
  letter-spacing: 0.04em;
}

.audits_info_block .audits_info_block_last {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 16px;
}

.audits_info_block .audits_info_block-inner {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 12px;
}

.audits_info_block .audits_info_block-inner .audits_info_block-logo {
  object-fit: cover;
  margin: 16px 16px 16px 8px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: white;
}

.audits_info_block-percent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.audits_info_block-percent_inner {
  color: #6db333;
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
}

.audits_info_block-percent_inner_last {
  font-size: 10px;
  color: #6db333;
  line-height: 12px;
  font-weight: 700;
}

.audits_info_block-info {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
  margin-right: 35%;
  margin-left: -50px;
  grid-gap: 5px;
}

.audits_info_block-info_title {
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  color: #003e47;
  cursor: pointer;
}

.audits_info_block-info_subtitle {
  font-size: 14px;
  color: #003e47;
  font-weight: 400;
  line-height: 17px;
  cursor: pointer;
}

.audits_info_block-info_text {
  width: 120px;
  height: 20px;
  background-color: #6db333;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #fff;
  border-radius: 4px;
  text-transform: uppercase;
  margin-top: 10px;
}

.audits_info_data {
  grid-gap: 40px;
  align-items: center;
  color: #003e47;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  border: 1px solid rgba(0, 62, 71, 0.07);
  border-radius: 4px;
  transition: 0.3s background-color, 0.3s border-color;
}

.audits_info_data:hover {
  background: #f2f5f6;
  border-color: transparent;
}

.audits_info_data-plus {
  font-size: 14px;
  width: 24px;
  margin-top: 2px;
  height: 16px;
  background-color: #f17f16;
  text-align: center;
  border-radius: 51px;
  color: #fff !important;
}

.sub_menu_Audit {
  position: fixed;
  z-index: 105;
  top: 0;
  bottom: 0;
  transition: 0s;
  padding-block: 24px;
  overflow: auto;
}

.settingsAuditAdd {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  max-width: 720px;
  width: 720px;
  margin-inline: auto;
}

.createAudit {
  margin-top: 0;
}

.addAuditBtn {
  cursor: pointer;
  display: flex;
  grid-gap: 15px;
  justify-content: flex-end;
  margin-top: 20px;
}

.btn_header-right_unSave:hover {
  transition: 0.4s;
  background-color: #ff5454;
  color: #fff;
}

.btn_header-right_save:hover {
  transition: 0.4s;
  background-color: #569720;
  color: #fff;
}

.audits_info_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  border: 1px solid #ccd8da;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s;
}

.audits_info_btn .audits_info_btnAdd {
  padding: 8px;
}

.audits_info_btn:hover {
  background-color: rgba(0, 62, 71, 0.07);
  border-color: transparent;
}

.audits_info_data_inner {
  display: flex;
  grid-gap: 24px;
  align-items: center;
  padding: 24px;
}

.audits_info_block-info_status {
  color: #003e4766;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  grid-gap: 40px;
  align-items: center;
  margin-left: 96px;
  padding-bottom: 24px;
}

.audits_info_block-info_status_inner {
  display: flex;
  align-items: center;
  grid-gap: 5px;
}

.progresBar_audits {
  display: flex;
  position: relative;
  width: 60px;
  height: 60px;
}

.audits_info_data_inner-complete {
  height: 96px;
  align-items: center;
}
