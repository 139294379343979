.popover {
  :global(.ant-popover-inner) {
    padding: 0;
  }
}

.dropdownList {
  width: 90vw;
  max-width: 350px;
  min-height: max-content;
  list-style-type: none;
  background-color: #fff;
}

.dropdownList input {
  border: none !important;
  outline: none !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.pointer {
  position: absolute;
  top: -8px;
  right: 68px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #fff;
}

.footerActions {
  border-top: 1px solid #003e471a;
  display: flex;
  padding: 8px;
  gap: 10px;
}

.options {
  padding: 8px;
  margin: 0;
  max-height: 171px;
  overflow: auto;
}

.options > li + li {
  margin-top: 4px;
}

.noResults {
  padding: 8px;
  text-align: center;
  color: #003e47;
}

.dropdownHeader {
  position: relative;
  display: flex;
  width: 100%;
  height: 46px;
  border-radius: 0;
  border-bottom: 1px solid #003e471a;
}

.dropdownHeader > svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.dropdownHeader .searchIcon {
  left: 15px;
}

.dropdownHeader input {
  padding-inline: 49px;
  border-radius: 0;
}
