@import "../../../global-ui-scss/mixins";
@import "../../../global-ui-scss/variables";

.button {
  grid-gap: 1rem;
  height: auto;
  padding: 6px 12px;
  color: $primary-dark;
  font-weight: 700;
  background-color: rgba(0, 62, 71, 0.07);
  border-radius: 4px;
  border: 0;

  &:global(.ant-btn-text:not(:disabled):not(.ant-btn-disabled)) {
    color: $primary-dark;

    &:hover {
      color: $primary-dark;
      background-color: rgba(0, 62, 71, 0.1);
    }
  }
}

.link {
  display: flex;
  align-items: center;
  grid-gap: 0.75rem;
}

.linkIcon {
  fill: RgbaColor($primary-dark, 0.6);
  margin-top: -1px;
}

.linkDanger {
  .linkIcon {
    fill: $color-red-700;
  }
  color: $color-red-700;
}
