@import "../global-ui-scss/mixins";
@import "../global-ui-scss/variables";

.vi__container {
  width: 100%;
  height: 64px;
  gap: 0.25rem;
  justify-content: center;

  .vi__character {
    flex: 0 0 auto;
    border-radius: 8px;
    width: 48px;
    color: RgbaColor($primary-dark, 0.8);
    height: 64px;
    font-size: 26px;
    line-height: 60px;
    cursor: pointer;
    border: 2px solid RgbaColor($primary-dark, 0.1);
    background-color: transparent;

    &:nth-child(5) {
      position: relative;
      margin-left: 2rem;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        right: 100%;
        width: 0.75rem;
        height: 2px;
        background-color: RgbaColor($primary-dark, 0.2);
        transform: translate(-0.875rem, -50%);
      }
    }
  }

  .vi__character--selected {
    outline: none;
    border: 2px solid $color-primary-blue-main;
  }
}
