.file-upload-wrapper {
  display: flex;
  align-items: center;
  border: 1px dashed rgba(0, 62, 71, 0.2);
  border-radius: 4px;
  padding: 14px;
  transition: 0.3s border, 0.3s box-shadow, 0.3s background-color;
}

.file-upload-wrapper.file-upload-disabled {
  pointer-events: none;
}

.file-upload-wrapper > img {
  width: 72px;
  height: 72px;
  object-fit: cover;
  border-radius: 4px;
  cursor: default;
}

.file-upload-wrapper .file-upload-description {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}

.file-upload-wrapper .file-upload-description > * + * {
  margin-top: 2px;
}

.file-upload-wrapper .file-upload-description .title {
  font-weight: 500;
  font-size: 14px;
  line-height: 136%;
  color: rgba(0, 62, 71, 0.8);
}

.file-upload-wrapper .file-upload-description .subtitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 136%;
  color: rgba(0, 62, 71, 0.4);
}

.file-upload-wrapper > button {
  margin-left: auto;
  padding: 10px 16px;
  border: 1px solid #49baca;
  border-radius: 4px;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.04em;
  color: #49baca;
  background-color: transparent;
  width: 92px;
  cursor: pointer;
  transition: 0.3s color, 0.3s border-color, 0.3s background-color, 0.3s opacity !important;
}

.file-upload-wrapper.file-upload-active > button {
  border-color: #ff5454;
  color: #ff5454;
}

.file-upload-wrapper.file-upload-drag-active {
  border: 1px solid #49baca;
  background: rgba(0, 62, 71, 0.02);
  box-shadow: 0 0 4px 0 #54b4b4;
}

.file-upload-wrapper.file-upload-drag-active button {
  opacity: 0;
}
