@import "../../../../global-ui-scss/variables.scss";
@import "../../../../global-ui-scss/mixins.scss";

.headerSection {
  padding: 24px;
  gap: 24px;
  width: 100%;
  @include DisplayFlex(column, flex-start, center);
  border-bottom: 1px solid $primary-neutrals-dark10;
  .headingWrapper {
    width: 100%;
    @include DisplayFlex(row, space-between, center);
    .title {
      font-weight: 800;
      font-size: 20px;
      color: $primary-dark;
    }

    .buttonsWrapper {
      @include DisplayFlex(row, center, center);
      gap: 12px;
      button {
        width: 113px;
      }
    }
  }
  .inputWrapper {
    width: 100%;
  }
}
