.root {
  display: flex;
  gap: 4px;
  padding: 2px 6px;
  border-radius: 4px;
  background: #003e471a;
  width: max-content;
  height: max-content;
}

.root span {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  color: #003e47cc;
}
