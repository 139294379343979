@import "../../../../../../global-ui-scss/mixins";
@import "../../../../../../global-ui-scss/variables";

.body {
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  padding: 1.25rem 1.5rem;
}

.button {
  grid-gap: 0.5rem;
  padding-inline: 0.5rem;
}

.footnote {
  font-weight: 500;
  color: RgbaColor($primary-dark, 0.6);
  white-space: pre-wrap;
}

.formItem {
  margin-bottom: 0;
}

.link {
  font-weight: 700;
  color: $color-primary-blue-main;
}

.footer {
  padding: 1rem 1.5rem;
  border-top: 1px solid #ebf0f0;
}
