@import "../../../../global-ui-scss/variables.scss";
@import "../../../../global-ui-scss/mixins.scss";

.root {
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 62, 71, 0.05), rgba(0, 62, 71, 0.05)), #ffffff;

  .contentWrapper {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: max-content 3fr;

    .leftBlock {
      display: flex;
      padding: 24px 0 24px 24px;
      flex-direction: column;
      overflow: auto;
      height: calc(100vh - 70px);
    }

    .rightBlock {
      height: calc(100vh - 70px);
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-left: 1px solid #ebf0f0;

      .childrenWrapper {
        @include DisplayFlex(column, space-between, flex-start);
        height: 100%;
        width: 100%;
        overflow-y: auto;
      }
    }
  }
}

.offScroll {
  overflow-y: hidden;
}
