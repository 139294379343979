@import "../../global-ui-scss/mixins";
@import "../../global-ui-scss/variables";

.wrapper {
  :global(.ant-drawer-content) {
    padding: 0;
  }

  :global(.ant-drawer-close) {
    width: 2rem;
    height: 2rem;
    padding: 0.25rem;
    margin: 0;
    color: RgbaColor($primary-dark, 0.6);
  }

  :global(.ant-drawer-header) {
    border-bottom-color: $color-neutrals-solid-5;
  }

  .header {
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    padding: 0.5rem 0 0.5rem 0.5rem;
    height: 4rem;
  }

  .title {
    font-weight: 700;
    font-size: 1rem;
    color: $primary-dark;
  }

  .headerContent {
    flex-grow: 1;
    min-width: 0;
  }
}
