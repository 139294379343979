@import "../../../../../../global-ui-scss/variables.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
}

.container {
  position: relative;
  min-width: 18rem;

  .selectButton {
    justify-content: space-between;
    color: $primary-dark;
    font-size: 0.875rem;
    line-height: inherit;
    padding: 0.6875rem 0.75rem 0.6875rem 0.9375rem;
    font-weight: 500;
    text-transform: none;
    border-color: $primary-neutrals-dark10;

    &:hover,
    &.selectButtonActive {
      background: rgba(0, 62, 71, 0.02);
      border-color: $primary-neutrals-dark10;
      color: #003e47;
    }

    :global(.MuiButton-icon) {
      transition: transform 200ms;
    }

    &.selectButtonActive {
      color: $primary-disable;

      :global(.MuiButton-icon) {
        transform: rotate(180deg);
      }
    }

    &.selectButtonError {
      color: $color-red-800;
      border-color: currentColor;
    }

    &.highlighted {
      background-color: #fffce4;
    }

    .countryLabel {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }

  .popper {
    width: 100%;
    margin-top: 0.5rem !important;
    background-color: #ffffff;
    z-index: 2000;
    box-shadow: 0 7px 25px 0 rgba(0, 0, 0, 0.25);

    .searchInput {
      padding: 0.125rem 0.75rem;
      border-bottom: 1px solid rgba(235, 240, 240, 1);
      border-radius: 0;
    }

    .autocomplete {
      border: 2px solid transparent;

      &:global(.Mui-focused) {
        border: 2px solid rgba(73, 186, 202, 1);
        border-radius: 8px;

        .searchInput {
          border-bottom-color: transparent;
        }
      }
    }
  }

  .autocompletePaper {
    box-shadow: none;
  }

  fieldset {
    border: 0;
  }

  .item {
    position: relative;
    grid-gap: 0.875rem;
    font-weight: 500;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    border-radius: 4px;
  }

  .itemLabel {
    padding-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    grid-gap: 1rem;
    color: $primary-dark;
  }

  .itemLabelInner {
    display: flex;
    align-items: center;
    width: 55px;

    img {
      width: 100%;
      height: auto;
    }
  }

  .checkmark {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .option {
    &:global(.Mui-focused) {
      background-color: $primary-neutrals-dark5;
    }
  }

  .autocompletePopper {
    position: static !important;
    transform: none !important;
  }
}
