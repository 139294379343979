@import "../../../global-ui-scss/mixins";
@import "../../../global-ui-scss/variables";

.dropdown-container {
  position: relative;
  width: 200px;
}

.action-overlay {
}

.dropdown-header {
  padding: 7.75px 16px;
  border-radius: 4px;
  border: 1px solid var(--neutrals-dark-10, rgba(0, 62, 71, 0.1));
  cursor: pointer;
  color: var(--primary-dark, #003e47);
  font-variant-numeric: lining-nums proportional-nums;
  font-family: "Raleway", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
  transition: 0.3s;
}

.readOnly .dropdown-header {
  cursor: default;
  border-color: RgbaColor($primary-dark, 0.02);
  background-color: RgbaColor($primary-dark, 0.02);
}

.dropdown-container.disabled .dropdown-header {
  opacity: 0.5;
  pointer-events: none;
}

.dropdown-container.open .dropdown-arrow {
  transform: translateY(-50%) rotate(180deg);
}

.dropdown-container.grey .dropdown-header {
  border-radius: 4px;
  background: var(--neutrals-dark-20, rgba(0, 62, 71, 0.2));
  border: 1px solid var(--neutrals-dark-10, rgba(0, 62, 71, 0.02));
}

.dropdown-container.big .dropdown-list,
.dropdown-container.big {
  width: 100%;
}

.dropdown-container.big .dropdown-header {
  padding: 13px 15px;
  line-height: 24px;
}

.dropdown-container.error .dropdown-header {
  outline: 1px solid #ff5454;
  border: none !important;
  background-color: rgba(255, 84, 84, 0.1);
}

.dropdown-list-wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  margin: 4px 0 0 0;
  padding: 4px;
  z-index: 10;
  border-radius: 8px;
  background: var(--primary-white, #fff);
  box-shadow: 0 6px 17px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.dropdown-list-inner {
  display: flex;
  flex-direction: column;
  max-height: 165px;
  overflow-y: auto;
  list-style-type: none;
  gap: 4px;
  padding: 8px 4px;
  margin: 0;
}

.dropdown-search-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
}

.dropdown-search-wrapper > svg {
  position: absolute;
  top: calc(50% - 2px);
  transform: translateY(-50%);
  left: 15px;
}

.dropdown-search-wrapper input {
  padding-left: 49px;
  outline: none !important;
  border-bottom: 1px solid #003e471a !important;
  border-radius: 0;
  margin: -4px -4px 0;
  width: calc(100% + 8px);
  padding-block: 11px;
}

.dropdown-search-wrapper input:focus,
.dropdown-search-wrapper input:hover {
  border-bottom: 1px solid #003e471a !important;
}

.dropdown-list-wrapper .dropdown-list {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: max-content;
  max-height: 165px;
  overflow-y: auto;
  list-style-type: none;
  margin: 4px 0 0 0;
  padding: 4px;
  z-index: 10;
  gap: 4px;
  border-radius: 8px;
  background: var(--primary-white, #fff);
  box-shadow: 0 6px 17px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.dropdown-list-wrapper .dropdown-footer {
  margin: 0 -4px -4px;
  padding: 8px;
  width: calc(100% + 8px);
  border-top: 1px solid var(--neutrals-dark-10, rgba(0, 62, 71, 0.1));
}

.dropdown-arrow {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.3s transform;
}

.readOnly .dropdown-arrow {
  opacity: 0.2;
}

.dropdown-list-title {
  font-size: 12px;
  font-weight: 700;
  line-height: 14.09px;
  padding: 16px 16px 12px;
  color: #003e4766;
}

.dropdown-list-item {
  min-height: 32px;
  max-height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 4px 4px 8px;
  border-radius: 4px;
  color: var(--primary-dark, #003e47);
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.48px;
  transition: 0.3s opacity, 0.3s background-color;

  &.dropdown-danger-item {
    color: $color-red-700;
  }
}

.dropdown-list-wrapper .dropdown-list-item.dropdown-hidden-item {
  opacity: 0.4;
  cursor: not-allowed;
}

.dropdown-list-item:hover {
  background: #003e4712;
}

.dropdown-list-item.dropdown-active-item .dropdown-item-actions > button {
  transition: 0.3s opacity;
  opacity: 0;
}

.dropdown-list-item.dropdown-active-item:hover .dropdown-item-actions > button {
  opacity: 1;
}

.dropdown-list-item.dropdown-active-item .dropdown-item-actions {
  opacity: 1;
}

.dropdown-list-item .dropdown-item-actions > svg {
  margin-inline: 2px;
}

.dropdown-list-wrapper .dropdown-item-actions button {
  width: 24px;
  height: 24px;
  padding: 0;
}

.dropdown-item-actions {
  opacity: 0;
  transition: 0.3s opacity;
  display: flex;
  align-items: center;
  gap: 4px;
}

.dropdown-list-item:hover .dropdown-item-actions {
  opacity: 1;
}

.dropdown-list-item.dropdown-active-item {
  background: var(--neutrals-solid-dark-5, #f2f5f6);
}

.ant-dropdown .ant-dropdown-menu .dropdown-list-item.dropdown-caution-item {
  &:hover {
    background: rgba(255, 84, 84, 0.1);
  }
  span {
    color: $color-red-700;
  }
}

.ant-dropdown .ant-dropdown-menu .dropdown-list-item.dropdown-disabled-item {
  background: $secondary-gray-dark;
  opacity: 0.3;
  cursor: not-allowed;
}
