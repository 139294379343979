@import "../../../global-ui-scss/variables.scss";
@import "../../../global-ui-scss/mixins.scss";

.tag_root {
  @include DisplayFlex(row, center, center);
  padding: 3px 10px;
  border-radius: 25px;
  font-size: 0.75rem;
  line-height: 1.5;
  font-weight: 700;
  width: max-content;

  &.closable {
    svg {
      transition: 0.3s;
      cursor: pointer;
      opacity: 0;
      width: 0;
    }

    &:hover svg {
      margin-left: 4px;
      width: 16px;
      opacity: 1;
    }
  }

  &.clickable {
    cursor: pointer;
  }

  &.small {
    padding: 2px 6px;
    font-size: 10px;
    line-height: 12px;
  }

  &.nowrap {
    white-space: nowrap;
  }

  &.dark {
    background: $primary-neutrals-dark10;
    color: $primary-neutrals-dark80;
  }

  &.gray {
    color: #003e47;
    background: $secondary-gray-dark;
  }

  &.blue {
    color: $color-primary-blue-deep;
    background: $secondary-blue;
  }

  &.green {
    color: $primary-green-dark;
    background: $secondary-green20;
  }

  &.orange {
    color: $secondary-orange;
    background: $secondary-orange-dark10;
  }

  &.red {
    color: $color-red-700;
    background: RgbaColor($color-red-700, 0.2);
  }

  &.yellow {
    color: $color-yellow-dark;
    background: RgbaColor($color-yellow-main, 0.2);
  }

  &.purple {
    color: $color-purple;
    background: RgbaColor($color-purple, 0.2);
  }
}
