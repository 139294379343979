.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0);
  z-index: 1000;
}

.modal-content {
  transition: 0.5s;
  padding: 40px;
  border-radius: 12px;
  background-color: white;
  width: 574px;
  position: relative;
  display: grid;
  grid-gap: 15px;
}

.modal .close_icon {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.modal.active,
.modal.active .modal-content {
  transform: scale(1);
  transition: 0.5s;
}

.modal-content .title {
  font-weight: 800;
  font-size: 24px;
  line-height: 110%;
  color: #003e47;
}

.modal-content p {
  font-weight: 500;
  font-size: 16px;
  line-height: 148%;
  color: rgba(0, 62, 71, 0.8);
}

.email-adress {
  border: 2px solid #dae3e5;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.email-adress span {
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 62, 71, 0.6);
  font-weight: bold;
  margin-bottom: 6px;
}

.email-adress span::after {
  content: '*';
  display: inline-block;
  color: #ffa600;
}

.email-adress input {
  border: none;
  outline: none;
}

input::placeholder {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 62, 71, 0.2);
}

.button-popup {
  display: flex;
  justify-content: flex-end;
}

.button-sended {
  background: #49baca;
  padding: 15px 60px;
}
.button-sended:hover {
  background: #0f8594;
  border: 1px solid #0f8594;
}

.block_close_tag {
  display: flex;
  justify-content: flex-end;
}
.closeTag {
  cursor: pointer;
}

@media screen and (max-width: 430px) {
  .modal-content .title {
    font-size: 16px;
  }

  .modal-content p {
    font-size: 12px;
  }
  .email-adress span::after {
    content: '*';
    display: none;
    color: #ffa600;
  }
  .button-sended {
    padding: 15px 30px;
  }
}
