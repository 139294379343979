@import "../../../../../global-ui-scss/mixins";
@import "../../../../../global-ui-scss/variables";

.wrapper {
  position: relative;
}

.root {
  display: grid;
  align-items: center;
  grid-template-columns: minmax(10rem, 1fr) 60px 120px 130px 140px 100px;
  padding: 16px;
  grid-gap: 2rem;
  transition: 0.3s;
  text-decoration: none;
  position: relative;
  border-bottom: 1px solid $color-neutrals-solid-5;
  z-index: 1;

  &::before {
    content: "" "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: calc(100% + 1px);
    width: 100%;
    border: none;
    background: transparent;
    border-radius: 8px;
    transition: 0.3s;
    z-index: -1;
  }

  &:hover {
    border-color: transparent;

    &::before {
      background: white;
    }
  }

  .tooltipButton {
    padding: 0;
    border: 0;
    justify-content: flex-start;
    height: auto;
    background: transparent;
    box-shadow: none;

    &:hover {
      background: transparent;
    }
  }
}

.divider {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border: none;
  background-color: #dae3e5;
  height: 1px;
  z-index: 0;
  margin: 0;
}

.root .actions {
  z-index: 5;
}

.row {
  display: flex;
  gap: 12px;
  align-items: center;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex-grow: 1;
  min-width: 0;
}

.documentName {
  display: flex;
  min-width: 0;
}

.tooltipTitle {
  font-size: 0.75rem;
  margin-bottom: 0.625rem;
}

.fileSize {
  font-size: 12px;
  font-weight: 500;
  color: $color-neutrals-solid-7;
  text-transform: lowercase;
}

.date {
  font-size: 12px;
  font-weight: 500;
  color: #003e47;
}

.actionItemText {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 1rem;
}

.actions {
  position: absolute;
  right: 1rem;
  top: 50%;
  display: flex;
  align-items: center;
  grid-gap: 0.625rem;
  margin-left: auto;
  transform: translateY(-50%);
  z-index: 99;
}

.signaturesTooltip {
  min-width: 290px;
}
