.root {
  position: relative;

  .icon {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    display: flex;
    padding: 8px;
    cursor: pointer;
  }
}
