@import "../../../../../../../../global-ui-scss/mixins";
@import "../../../../../../../../global-ui-scss/variables";

.root {
  display: grid;
  grid-template-columns: 80px 1fr 120px;
  grid-gap: 2rem;
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 4px;
  font-size: 0.875rem;
  padding: 1rem;

  + .root {
    border-top: 1px solid $color-neutrals-solid-4;
  }
}

.status {
  display: flex;
  align-items: center;
  grid-gap: 0.25rem;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1;

  &.gap2 {
    grid-gap: 0.5rem;
  }

  &.general {
    color: RgbaColor($primary-dark, 0.4);
  }

  &.success {
    color: $color-green-700;
  }

  &.danger {
    color: $color-red-700;
  }

  &.warning {
    color: $secondary-orange;
  }

  &.info {
    color: $color-primary-blue-main;
  }
}

.content {
  min-width: 0;
}

.title {
  font-weight: 500;
  color: $primary-dark;
  line-height: 1.2;
}

.description {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  font-weight: 500;
  font-size: 0.75rem;
  color: $color-neutrals-solid-7;
  margin-top: 0.25rem;
}

.typeWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
