.login_sms-list {
  position: relative;
  display: flex;
  justify-content: space-around;
  margin-bottom: 24px;
  margin-top: 20px;
}

.error-codes-sms {
  font-weight: 700;
  font-size: 16px;
  color: #de5b49;
  margin-bottom: 15px;
  text-align: center;
}
