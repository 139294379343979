.document_settings {
  position: absolute;
  cursor: pointer;
  width: max-content;
  height: max-content;
  right: 0;
  margin-top: 18px;
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: grid;
  padding: 4px;
  font-size: 12px;
  font-weight: bold;
  color: #003e47;
  z-index: 120;
}

.document_settings_inner {
  height: 44px;
  width: 198px;
  padding: 15px 16px;
  line-height: 14px;
  transition: 0.5s;
}

.document_settings_inner.inner-caution {
  color: #ff5454;
}

.document_settings_inner.inner-caution.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.document_settings_inner-active,
.document_settings_inner:hover {
  transition: 0.5s;
  border-radius: 4px;
  background-color: #f2f5f6;
}

.document_settings_inner.inner-caution:hover {
  background-color: rgba(255, 84, 84, 0.1);
}
