@import "../../global-ui-scss/mixins";
@import "../../global-ui-scss/variables";

.ant-alert {
  font-weight: 700;
  border-radius: 4px;
}

.ant-alert-info {
  color: $color-primary-blue-main;
  border-color: $color-primary-blue-main;
  background-color: RgbaColor($color-primary-blue-main, 0.1);
}

.ant-alert-error {
  color: $color-red-700;
}
