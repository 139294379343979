.actions {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  padding: 12px;
  border-radius: 16px;
  background: #ffffff;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding-block: 24px;
}

.loader {
  margin: 3rem;
}

.modal {
  &.danger :global(.ant-modal-title) {
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    color: #d94747;
  }

  :global(.ant-modal-body) {
    margin-inline: -24px;
    padding-inline: 24px;
    border-block: 1px solid #ebf0f0;

    p {
      font-size: 16px;
      font-weight: 500;
      color: #003e4799;
    }
  }

  :global(.ant-modal-footer) {
    display: flex;
    justify-content: flex-end;
    gap: 12px;

    button {
      width: 142px;
    }
  }
}
