@import "../../../../../global-ui-scss/mixins";
@import "../../../../../global-ui-scss/variables";
.wrapper {
  width: 100%;

  .bodySection {
    padding: 24px;
    gap: 40px;
    width: 100%;
    @include DisplayFlex(column, center, flex-start);

    .dateFileWrapper {
      gap: 24px;

      .inputDate {
        border: 1px solid #003e471a;
        border-radius: 4px;
        background-color: transparent;
        font-feature-settings: "pnum" on, "lnum" on;
        padding: 4px 8px 4px 12px;
        font-size: 14px;
        color: #003e47cc;
        font-weight: 500;
        &::placeholder {
          font-size: 14px;
          padding-top: 2px;
        }
      }
    }

    :global(.rdw-editor-wrapper) {
      border-color: #ebf0f0 !important;
    }
  }
}
.row {
  @include DisplayFlex(row, flex-start, center);
}
.column {
  width: 100%;
  @include DisplayFlex(column, center, flex-start);
  gap: 8px;
}
