@import "../../../global-ui-scss/variables";

.root {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  padding: 18px 24px;
  height: $header-height;
  background: #ffffff;
  z-index: 30;
  border-bottom: 1px solid #ebf0f0;

  .link {
    .backButton {
      padding: 6px 16px 6px 8px;
    }

    .backButtonRounded {
      border: none;
      border-radius: 100px;
      color: #003e47;
      font-size: 10px;
      font-style: normal;
      padding: 8px 19px;
      text-transform: uppercase;
      background: #003e471a;
    }
  }
}

.link {
  text-decoration: none;
}
