.btn_default {
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.04em;
  color: #fff;
  cursor: pointer;
  padding: 7px 28px;
  border: 1px solid #fff;
  background: transparent;
  border-radius: 8px;
  transition: background-color 0.4s;
  width: max-content;
  height: max-content;
}
.br-8px{
  border-radius:8px;
}
.btn_mobile {
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  padding: 14px 0;
  border: 1px solid #fff;
  background: transparent;
  border-radius: 4px;
  transition: background-color 0.4s;
  display: block;
  text-align: center;
}
.btn_orange {
  border: 1px solid var(--bg-main-orange);
  background: var(--bg-main-orange);
}

/* override the default border color */
/*.btn_orange:hover {*/
/*  border: 1px solid #ffa500 !important;*/
/*  background: var(--bg-main-orange);*/
/*}*/

.btn_gray {
  border: 1px solid var(--bg-main-gray);
  background: var(--bg-main-gray);
  color: #000;
}

.btn_default:hover {
  border: 1px solid var(--bg-main-white);
}

.btn_blue {
  background: #003e47;
  border: 1px solid #ffffff;
  color: #fff;
}

.btn_blue:hover {
  background: transparent;
  border: 1px solid #003e47;
  color: #003e47;
}

.btn_bluelight {
  background: #49baca;
  border: 1px solid transparent;
  color: #fff;
}

.btn_bluelight:hover {
  background: transparent;
  border: 1px solid #49baca;
  color: #49baca;
}

.btn_orange:hover {
  background: #ce6809;
  border-color: #ce6809;
}

.btn_border_delete {
  width: max-content;
  font-family: 'Raleway';
  font-weight: bold;
  padding: 0;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.04em;
  text-transform:none ;
  color: #49baca;
  background-color: transparent;
  border: unset;
}

.btn_border_delete:hover {
  border: unset;
  padding: 0;
  background: transparent;
  color: #49baca;
}
