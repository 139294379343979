.root {
  display: grid;
  align-items: center;
  grid-template-columns: minmax(130px, 1fr) 100px 100px 150px 100px;
  padding: 16px;
  grid-gap: 2rem;
  border-radius: 8px;
  flex-shrink: 0;
  transition: 0.3s;
  text-decoration: none;
  position: relative;
}

.root > hr {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border: none;
  background-color: #dae3e5;
  height: 1px;
  z-index: 0;
  margin: 0;
}

.root > * {
  z-index: 1;
}

.root .actions {
  z-index: 5;
}

.root :global(.outside_click_wrapper) {
  position: absolute;
  right: 10px;
  z-index: 10;
}

.root::after {
  content: "" "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: calc(100% + 1px);
  width: 100%;
  border: none;
  background: transparent;
  border-radius: 8px;
  transition: 0.3s;
}

.root:hover::after {
  background: white;
}

.row {
  display: flex;
  gap: 12px;
  align-items: center;
}

.fileName {
  font-size: 12px;
  font-weight: 700;
  color: #003e47;
}

.date {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #003e47;
}

.actions {
  display: flex;
  position: relative;
  gap: 10px;
  align-items: center;
  margin-left: auto;
}

.actions :global(.outside_click_wrapper) {
  margin-left: -10px;
}

.actions a {
  display: inline-flex;
}
