@import "../../../../global-ui-scss/mixins.scss";
@import "../../../../global-ui-scss/variables.scss";

.headerWrapper {
  @include DisplayFlex(column, flex-start, flex-start);
  gap: 16px;
  height: 100%;
  width: 100%;
  position: relative;
  max-width: 70%;
  margin-right: auto;

  .editButtonsWrapper {
    position: absolute;
    right: 0;
    top: calc(100% + 8px);
    @include EditButtonsAppear(calc(100% + 8px));

    + .editHeader {
      filter: drop-shadow(0px 6px 17px rgba(0, 0, 0, 0.1));
      border: none !important;
      padding: 16px;
    }
  }

  .headerTitleWrapper {
    position: relative;
    width: 100%;
  }

  .editHeader {
    border: none;
    border-radius: 4px;
    padding: 0;
    margin: 0;
    font-family: "Raleway";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 110%;
    width: 100%;
    position: relative;
    z-index: 1;
    transition: 0.3s filter, 0.3s border, 0.3s padding;

    &:hover {
      border-color: $primary-neutrals-dark20;
    }
  }
}
