@import "../../global-ui-scss/variables";

.wrapper {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0.5rem;
  align-items: center;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid $color-neutrals-solid-5;

  + .wrapper {
    margin-top: 0.5rem;
  }
}

.content {
  min-width: 0;
}

.nameWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 0.5rem;
}

.nameStack {
  grid-gap: 0.25rem;
  display: flex;
  align-items: center;
  min-width: 0;
}

.name {
  min-width: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
