@import "../../global-ui-scss/mixins.scss";
@import "../../global-ui-scss/variables.scss";

.ant-tooltip {
  font-weight: 500;
  max-width: 300px;

  .ant-tooltip-inner {
    background-color: $primary-dark;
    border-radius: 8px;
    padding: 12px 14px;
  }

  .ant-tooltip-arrow {
    &::before {
      background: $primary-dark;
    }
  }
}
