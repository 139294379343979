@import "../../global-ui-scss/mixins.scss";
@import "../../global-ui-scss/variables.scss";

.wrapper {
  width: 100%;
  border-radius: 4px;
  background-color: $white;
  transition: 0.3s;
  outline: 1px solid transparent;
  border: 1px solid #ebf0f0;
}

.wrapper.focused {
  outline: 2px solid #49baca;
  border-color: transparent !important;
}
