@import "../../../../../global-ui-scss/variables";

.root {
  @media (max-width: 1439px) {
    width: calc(100% - $dashboard-aside-width);
    left: $dashboard-aside-width;
  }

  :global(.ant-drawer-body) {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  :global(.ant-drawer-content-wrapper) {
    transform: none !important;
  }
}
