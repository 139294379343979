@import "../../../global-ui-scss/variables.scss";
@import "../../../global-ui-scss/mixins.scss";

.root {
  background-color: #dcdcdc;
  border-radius: 20px;
  display: inline-block;
  height: 14px;
  position: relative;
  cursor: pointer;
  width: 32px;

  .toggleSwitch {
    background-color: $primary-dark;
    border-radius: 50%;
    box-shadow: 0 1px 1px $tertiary-neutrals-dark20;
    height: 18px;
    left: 0;
    position: absolute;
    top: -2px;
    transition: left 0.2s ease-in-out;
    width: 18px;
  }

  &.toggled {
    .toggleSwitch {
      left: 14px;
      background-color: $color-primary-blue-main;
    }
  }
}
