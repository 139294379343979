@import "../../../../global-ui-scss/mixins";
@import "../../../../global-ui-scss/variables";

.root {
  min-height: 100vh;
  background: linear-gradient(0deg, rgba(0, 62, 71, 0.05) 0%, rgba(0, 62, 71, 0.05) 100%);
  padding: 40px 176px;
  display: flex;
  flex-direction: column;

  :global(.ant-col) > span {
    color: var(--primary-dark, #003e47);
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 14px;
    font-weight: 500;
    line-height: 136%;
  }

  .companyInfo {
    display: flex;
    align-items: center;

    .logo {
      background-color: white;
      object-fit: cover;
      width: 106px;
      height: 106px;
      border-radius: 50%;
    }

    .infoBlock {
      display: flex;
      flex-direction: column;
      margin-inline: 32px 12px;

      .companyTitle {
        color: var(--primary-dark, #003e47);
        font-variant-numeric: lining-nums proportional-nums;
        font-size: 24px;
        font-weight: 800;
        line-height: 110%;
      }

      .companyUID {
        display: flex;
        align-items: center;
        grid-gap: 0.5rem;
        margin-top: 6px;
        color: var(--neutrals-dark-40, rgba(0, 62, 71, 0.4));
        font-variant-numeric: lining-nums proportional-nums;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  :global(.ant-tabs) {
    margin-top: 49px;
  }
}

.backButton {
  width: max-content;
  padding: 6px 16px 6px 8px;
}

.row {
  display: flex;
  gap: 12px;
}

.link {
  color: RgbaColor($primary-dark, 0.7);
}

@media screen and (max-width: 1150px) {
  .root {
    padding-inline: 5%;
  }
}
