@import "../../global-ui-scss/variables";
@import "../../global-ui-scss/mixins";

.nameWrapper {
  position: relative;
  padding-right: 1.375rem;
  min-width: 0;
  min-height: 1rem;

  .actionButtons {
    position: absolute;
    right: 0;
  }

  .input {
    border: 0;
    box-shadow: 0 6px 17px 0 #0000001a;
    outline: none;

    &:hover,
    &:focus {
      background-color: #ffffff;
      box-shadow: 0 6px 17px 0 #0000001a;
    }
  }
}

.nameWrapperEditing {
  padding-right: 0;
  flex-grow: 1;
  z-index: 100;
}

.innerAbsolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.small {
  .input {
    padding: 0.25rem 0.5rem;
  }

  .actionButtons {
    top: calc(100% + 0.25rem);
    grid-gap: 0.25rem;
    padding: 0.25rem;
    width: 4.25rem;

    button {
      padding: 1px;
    }
  }
}

.medium {
  .input {
    padding: 0.4125rem 1rem;
  }

  .actionButtons {
    top: calc(100% + 0.5rem);
    width: 5.625rem;

    button {
      padding: 4px;
    }
  }
}

.name {
  font-size: 12px;
  font-weight: 700;
  color: $primary-dark;
  cursor: pointer;
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: color 150ms;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 2px;
    color: RgbaColor($primary-dark, 0.4);

    .editButton {
      opacity: 1;
    }
  }
}

.editButton {
  position: absolute;
  top: 50%;
  right: 0;
  opacity: 0;
  color: RgbaColor($primary-dark, 0.8);
  transition: opacity 150ms;
  transform: translate(0, -50%);

  &:global(.ant-btn.ant-btn-icon-only.ant-btn-text:not(:disabled):not(.ant-btn-disabled)):hover {
    color: RgbaColor($primary-dark, 0.8);
    background-color: transparent;
  }

  &:global(.ant-btn.ant-btn-icon-only) {
    width: 1.5rem;
    height: 1.5rem;
  }
}
