@import "../../../../../../global-ui-scss/mixins";
@import "../../../../../../global-ui-scss/variables";

.header {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  font-size: 0.75rem;
  font-weight: 700;
  cursor: pointer;
  padding: 0.375rem 0.75rem;
  color: RgbaColor($primary-dark, 0.6);
  border-bottom: 2px solid $color-neutrals-solid-3;
}

.body {
  padding: 0.75rem;
  font-size: 0.75rem;

  .button {
    width: 100%;
    font-weight: 700;
    font-size: 0.75rem;
    padding: 0.5rem 1.25rem;
    height: auto;
    color: $primary-dark;
    border-radius: 4px;
  }

  .buttonActive {
    border-color: $color-primary-blue-main;
    background-color: RgbaColor($color-primary-blue-main, 0.1);
  }
}

.alert {
  font-weight: 600;
  margin-top: 0.5rem;
  padding: 0.25rem 0.5rem;
  white-space: pre-wrap;
  background-color: $color-neutrals-solid-3;
}

.divider {
  margin-block: 1rem;
}

.footer {
  text-align: right;
  padding: 0.5rem 1rem;
  border-top: 1px solid RgbaColor($primary-dark, 0.1);

  .footerButton {
    color: #ffffff;
    border-radius: 4px;
    padding-inline: 2.5rem;
    border-color: $color-primary-blue-main;
    background-color: $color-primary-blue-main;
  }
}
