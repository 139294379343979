.root {
  display: grid;
  align-items: center;
  grid-template-columns: minmax(180px, 1fr) 200px 200px 120px 125px 32px;
  padding: 16px;
  grid-gap: 2rem;
  border-radius: 8px;
  transition: 0.3s;
  text-decoration: none;
  position: relative;

  &.inactive > *:not(:global(.outside_click_wrapper)) {
    opacity: 0.4;
  }

  .phone,
  .email {
    font-size: 12px;
    font-weight: 500;
    line-height: 16.32px;
    color: #003e47;
  }
}

.root > hr {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border: none;
  background-color: #dae3e5;
  height: 1px;
  z-index: 0;
  margin: 0;
}

.root > * {
  z-index: 1;
}

.root .actions {
  z-index: 5;
}

.root :global(.outside_click_wrapper) {
  position: absolute;
  right: 10px;
  z-index: 10;
}

.root::after {
  content: "" "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: calc(100% + 1px);
  width: 100%;
  border: none;
  background: transparent;
  border-radius: 8px;
  transition: 0.3s;
}

.root:hover::after {
  background: white;
}

.row,
.rowWrap {
  display: flex;
  gap: 12px;
  align-items: center;
}

.rowWrap {
  flex-wrap: wrap;
  min-width: 0;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.name {
  font-size: 12px;
  font-weight: 700;
  line-height: 13.2px;
  color: #003e47;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.flag {
  padding: 2px 6px;
  border-radius: 3px;
  background: var(--neutrals-dark-7, rgba(0, 62, 71, 0.07));
  color: var(--neutrals-dark-40, rgba(0, 62, 71, 0.4));
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 12px;
  font-weight: 700;
  text-transform: capitalize;
}

.date {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #003e47;
}

.actions {
  display: flex;
  position: relative;
  gap: 10px;
  align-items: center;
  margin-left: auto;
}

.actions :global(.outside_click_wrapper) {
  margin-left: -10px;
}

.actions a {
  display: inline-flex;
}
