@import "../../../global-ui-scss/variables";

.root {
  font-size: 0.75rem;
  margin-top: 0.25rem;
  font-weight: 500;
}

.error {
  color: $color-red-700;
}
