.data-protection-wrapper {
  max-width: 744px;
  margin: 200px auto;
}

.data-protection-block {
  margin-bottom: 100px;
}
.data-protection-title {
  font-weight: 800;
  font-size: 40px;
  line-height: 110%;
  color: #003e47;
  margin-bottom: 32px;
}
.data-protection-description {
  font-weight: 500;
  font-size: 16px;
  line-height: 148%;
  color: rgba(0, 62, 71, 0.8);
}

.data-protection-headline {
  font-weight: 800;
  font-size: 24px;
  line-height: 110%;
  color: #003e47;
}

@media screen and (max-width: 450px) {
  .data-protection-title {
    font-size: 33px;
  }
}

@media screen and (max-width: 374px) {
  .data-protection-title {
    font-size: 27px;
  }
}

@media screen and (max-width: 280px) {
  .data-protection-title {
    font-size: 23px;
  }
}
