.imprint-wrapper {
  max-width: 744px;
  margin: 200px auto;
}
.imprint-title {
  font-weight: 800;
  font-size: 40px;
  line-height: 110%;
  color: #003e47;
}
.description-imprint {
  font-weight: 500;
  font-size: 16px;
  line-height: 148%;
  color: rgba(0, 62, 71, 0.8);
  margin-top: 32px;
}

.imprint-headlines {
  font-weight: 800;
  font-size: 20px;
  line-height: 110%;
  color: #003e47;
}

.imprint-blocks {
  margin-top: 90px;
}
.responsible-content {
  margin-top: 90px;
  /*display: grid;*/
  /*grid-template-rows: repeat(3,1fr);*/
}

.content-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 148%;
  color: rgba(0, 62, 71, 0.8);
}
.content-blocks {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}
.imprint-wrapper .info,
.data-protection-wrapper .info {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media screen and (max-width: 450px) {
  .imprint-headlines {
    font-size: 18px;
  }
  .imprint-title {
    font-size: 33px;
  }
}
