@import "../../global-ui-scss/mixins";
@import "../../global-ui-scss/variables";

.ant-popover {
  .ant-popover-inner {
    border: 1px solid #ebf0f0;
    box-shadow: 0 4px 8px 0 #0000001f;
    border-radius: 4px;
  }
}

.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
  > .ant-popover-arrow {
    top: 1px !important;
  }
}
