@import "../../global-ui-scss/mixins.scss";
@import "../../global-ui-scss/variables.scss";

.ant-tabs {
  > div > .ant-tabs-nav,
  > .ant-tabs-nav {
    .ant-tabs-nav-more {
      cursor: pointer;
      font-weight: 600;
      color: RgbaColor($primary-dark, 0.6);
      padding: 0.25rem 0.625rem 0.75rem;
    }
  }

  .ant-tabs-tab {
    font-size: 1rem;
    font-weight: 600;
    color: RgbaColor($primary-dark, 0.6);

    &.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: $primary-dark;
    }
  }
}

.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  &::before {
    border-bottom-color: $color-neutrals-solid-6;
  }

  .ant-tabs-ink-bar {
    height: 3px;
    border-radius: 2px;
    background-color: $color-primary-blue-main;
  }
}
