@import "../../global-ui-scss/mixins";
@import "../../global-ui-scss/variables";

.wrapper {
  display: flex;
  min-height: 100vh;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100vh;
  background-color: #f2f5f6;

  @media (max-width: 1439px) {
    padding-left: $dashboard-aside-width;
  }
}
