.root {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border: 1px solid rgba(0, 62, 71, 0.1);
  border-radius: 8px;
  width: 100%;
  text-decoration: none;
  -webkit-user-drag: none;
}

.info {
  display: flex;
  flex-direction: column;
  min-width: 0;
  margin-inline: 16px 16px;
  width: 100%;
}

.info .name {
  font-weight: 500;
  font-size: 14px;
  line-height: 136%;
  color: rgba(0, 62, 71, 0.8);
  word-break: break-all;
}

.info .infoTop,
.info .infoBot {
  display: flex;
}

.info .date {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 62, 71, 0.4);
}

.size {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 62, 71, 0.4);
  margin-left: auto;
  display: block;
}

.actions {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  margin-left: auto;
}

.actions > * {
  height: 33px;
}

.actions svg {
  cursor: pointer;
}

.actions svg path {
  fill: rgba(0, 62, 71, 0.6);
  fill-opacity: 1;
  transition: 0.3s;
}

.actions svg:hover path {
  fill: rgba(0, 62, 71, 1);
}

.progressWrapper,
.progress {
  border-radius: 2px;
  height: 4px;
}

.progressWrapper {
  background: rgba(0, 62, 71, 0.1);
  width: 100%;
  margin-top: 6px;
}

.progress {
  background: #6db333;
  width: 0;
  transition: 0.3s width;
}
