@import "../../../../../global-ui-scss/variables";

.root .splitInput {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
}

.root {
  width: 100%;
  gap: 20px;
}

.textWarning {
  font-weight: 700;
  font-size: 0.75rem;
  margin-top: 0.5rem;
  color: $secondary-orange;
}
