.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  padding: 24px;
  border-radius: 8px;
  background-color: #49baca1a;
  border: 1px solid #49baca;
  margin-bottom: 16px;

  > button {
    width: 155px;
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .title {
      font-size: 16px;
      font-weight: 700;
      line-height: 17.6px;
      color: #49baca;
    }

    .description {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.375;
      color: #003e47cc;
    }
  }
}
