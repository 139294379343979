@import "../../../global-ui-scss/mixins";
@import "../../../global-ui-scss/variables";

.root {
  &.light {
    :global(.ant-tooltip-inner) {
      color: $primary-dark;
      background-color: #ffffff;
    }

    :global(.ant-tooltip-arrow) {
      &::before {
        background: #ffffff;
      }
    }
  }
}
