@import "../../global-ui-scss/mixins";
@import "../../global-ui-scss/variables";

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
  padding: 2rem 0;
  height: 100%;
}

.title {
  color: $primary-dark;
  text-align: center;
  font-size: 24px;
  font-weight: 800;
  max-width: 330px;
}

.subtitle {
  color: RgbaColor($primary-dark, 0.6);
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  max-width: 400px;
}

.button {
  width: 220px;
  margin-top: 1.5rem;
}
