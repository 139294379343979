@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  font-variant-numeric: lining-nums proportional-nums;
}

.landing__wrapper {
  max-width: 1128px;
  margin: 0 auto;
  padding: 30px 0;
}

input,
textarea {
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  line-height: 24px;
}

.outside_click_wrapper .settings_right-info_inner {
  margin-bottom: 0;
}

button:disabled {
  cursor: default !important;
}

.ant-picker-dropdown .ant-picker-ranges {
  margin-block: 0;
}

.ant-picker-dropdown .ant-btn-primary:disabled {
  color: rgba(255, 255, 255, 0.75);
}

.ant-picker-dropdown .ant-picker-cell-inner {
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Raleway, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.ant-picker-dropdown
  .ant-picker-cell.ant-picker-cell-selected:not(.ant-picker-cell-in-view)
  .ant-picker-cell-inner {
  background: var(--neutrals-dark-60, rgba(0, 62, 71, 0.6));
  color: var(--primary-white, #fff);
}

.ant-picker-dropdown .ant-picker-cell:not(.ant-picker-cell-in-view) .ant-picker-cell-inner {
  color: var(--neutrals-dark-40, rgba(0, 62, 71, 0.4));
}

.ant-picker-dropdown .ant-picker-cel:hover .ant-picker-cell-inner {
  background: var(--neutrals-solid-dark-8, #ebf0f0);
}

.ant-picker-dropdown .ant-picker-cel.ant-picker-cell-in-view .ant-picker-cell-inner {
  color: var(--primary-dark, #003e47);
}

.ant-picker-dropdown .ant-picker-header-view button {
  color: var(--primary-dark, #003e47);
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Raleway !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}

.ant-picker-dropdown {
  font-family: Raleway, sans-serif;
}

.ant-picker-dropdown .ant-picker-content thead th {
  color: var(--primary-dark, #003e47);
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Raleway, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.ant-picker-footer {
  display: none;
}

#active_audits .ant-card-body > .audits_info_data + .audits_info_data {
  margin-top: 8px;
}

:has(option[value=""]:not(:checked)) {
  color: #003e47;
}

.empty-section {
  min-height: 135px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
}

.empty-section > span {
  font-family: Raleway, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  color: #003e47;
}

.empty-section .title {
  font-size: 18px;
  font-weight: 800;
  line-height: 19.8px;
}

.tsqd-main-panel {
  z-index: 9999999 !important;
}

.vi__container input.vi {
  opacity: 0;
}

@media screen and (max-width: 768px) {
  .landing__wrapper {
    max-width: 768px;
    margin: 0 auto;
    padding: 30px 0;
  }
}
